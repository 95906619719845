import { useContext, createContext, useState } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import ApiConstants from "constant";
import axios from "axios";
import CryptoJS from "crypto-js";
import { v4 as uuidv4 } from "uuid";

export const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(
    localStorage.getItem("user") !== null
      ? JSON.parse(localStorage.getItem("user"))
      : null,
  );
  const [marchand, setMarchand] = useState(
    localStorage.getItem("marchand") !== null
      ? JSON.parse(localStorage.getItem("marchand"))
      : null,
  );
  const [token, setToken] = useState(localStorage.getItem("site") || "");
  const [localCountry, setLocalCountry] = useState({});
  const [otpError, setOtpError] = useState("");
  const history = useHistory();

  const SECRET_KEY = "tgdrhbsle";

  const encryptData = (name, prefixe) => {
    const encrypted = CryptoJS.AES.encrypt(
      JSON.stringify(prefixe),
      SECRET_KEY,
    ).toString();
    localStorage.setItem(name, encrypted);
    localStorage.setItem("sk", SECRET_KEY);
  };

  //pour executer l'encryptage
  // ApiConstants.encryptData('prefixe', ApiConstants.prefixe);

  //static prefixe = localStorage.getItem('prefixe');
  //static secretKey = localStorage.getItem('secretKey');

  const decryptData = (prefixe, secretKey) => {
    const encrypted = localStorage.getItem(prefixe);
    const SECRET_KEY = localStorage.getItem(secretKey);
    const decrypted = CryptoJS.AES.decrypt(encrypted, SECRET_KEY).toString(
      CryptoJS.enc.Utf8,
    );
    //localStorage.setItem('decrypt',decrypted);
    return JSON.parse(decrypted);
  };

  const handleErrorAPI = (
    error,
    handleIsLoading,
    handleIsFound,
    handleIsError,
    handleError,
  ) => {
    if (error.isAxiosError) {
      if ("response" in error) {
        if (error.response.status === 401) {
          handleIsLoading(false);
          setOtpError("Code OTP expiré.");
        } else if (error.response.status === 403) {
          handleIsLoading(false);
          setOtpError("OTP incorrect. Veuillez réessayer.");
        } else if (error.response.status === 404) {
          handleIsLoading(false);
          //handleIsFound(false);
          setOtpError("L'utilisateur n'a pas été trouvé.");
        } else {
          handleIsLoading(false);
          handleIsError(true);
          handleError(error);
          setOtpError("Une erreur est survenue. Veuillez réessayer plus tard.");
        }
      } else {
        handleIsLoading(false);
        handleIsError(true);
        handleError(error);
      }
    } else {
    }
  };

  const loginAction = async (
    data,
    handleIsLoading,
    handleIsFound,
    handleIsError,
    handleError,
  ) => {
    const handleResponseAPI = (response) => {
      // if (response.data.status === 200) {
      //   handleIsLoading(false);
      //   setOtpError("");
      //   localStorage.setItem('username', data)
      //   if(response.data.client.typeConnexion === "ga" && response.data.client.ifFristConnexion === true ){
      //     window.location.href='/home/connection/2fa'
      //   }
      //   if (response.data.client.typeConnexion === "otp")
      //     window.location.href='/home/connection/verify'
      // }
      if (
        response.data.status === "SUCCESS" ||
        response.data.status === 200 ||
        response.data.status === "OK"
      ) {
        handleIsLoading(false);
        setOtpError("");
        localStorage.setItem("username", data.username);
        localStorage.setItem("connection_method", data.connection_method);
        localStorage.setItem("username_type", data.username_type);

        //case of connection which otp and phone number
        if (Object.keys(response.data).includes("uniqueid")) {
          localStorage.setItem("uniqueid", response.data.uniqueid);
        }

        //case of connection which ga
        if (Object.keys(response.data).includes("url")) {
          localStorage.setItem("url", response.data.url);
        }

        //localStorage.setItem('username', 'typeconnexion','typeusername',data)
        window.location.href = "/home/connection/verify";
      } else if (response.data.status === "FAILED ") {
        setOtpError("");
      } else if (response.data.status === "ERROR ") {
        setOtpError("");
      }
    };

    handleIsLoading(true);
    let url = ApiConstants.baseUrlRequest + "" + ApiConstants.login;
    axios
      .post(url, {
        username: data.username,
        connection_method: data.connection_method,
        username_type: data.username_type,
      })
      .then(handleResponseAPI)
      .catch((error) =>
        handleErrorAPI(
          error,
          handleIsLoading,
          handleIsFound,
          handleIsError,
          handleError,
        ),
      );
  };

  const otpAction = (
    data,
    handleIsLoading,
    handleIsFound,
    handleIsError,
    handleError,
    handleOtpErrorMessage,
  ) => {
    const handleResponse = (response) => {
      let whereWeGo = "";
      if (
        response.data.status === "SUCCESS" ||
        response.data.status === "OK" ||
        response.data.status === 200
      ) {
        handleIsLoading(false);
        setUser(response.data.client);
        setMarchand(response.data.marchand);
        setToken(response.data.client.username); //token not return I use the email instead of token
        localStorage.setItem("site", response.data.client.username);
        localStorage.setItem("user", JSON.stringify(response.data.client));
        localStorage.setItem(
          "localStorageUserData",
          JSON.stringify(response.data.client),
        );
        localStorage.setItem(
          "marchand",
          JSON.stringify(response.data.marchand),
        );

        localStorage.removeItem("connection_method");
        localStorage.removeItem("username_type");
        localStorage.removeItem("uniqueid");
        localStorage.removeItem("url");

        const type = response.data.client.type;

        history.push({
          pathname:
            type === "professionnel"
              ? "/welcome/find_devis"
              : type === "partenaire"
              ? "/welcome/devis"
              : type === "marchand"
              ? "/welcome/dashboard"
              : type === "particulier"
              ? "/welcome/devis"
              : "",
        });
      } else {
        handleIsLoading(false);
        // handleIsFound(false);
        handleOtpErrorMessage("Le code OTP est incorrect");
      }
    };

    handleIsLoading(true);
    let url = ApiConstants.baseUrlRequest + "" + ApiConstants.verify;

    axios
      .post(url, data)
      .then(handleResponse)
      .catch((error) =>
        handleErrorAPI(
          error,
          handleIsLoading,
          handleIsFound,
          handleIsError,
          handleError,
        ),
      );
  };

  const logOut = () => {
    setUser(null);
    setToken("");
    localStorage.removeItem("site");
    localStorage.removeItem("marchand");
    localStorage.removeItem("user");
    localStorage.removeItem("localStorageUserData");

    window.location.href = "/home";
  };

  const isVisible = (visibility_name) => {
    if (user && user.profil_visibilities) {
      let element = user.profil_visibilities.filter(
        (elt) => elt.name === visibility_name,
      )[0];
      if (element && element.visibility === 1) {
        return true;
      } else {
        return false;
      }
    }
    return false;
  };
  const refreshUser = () => {
    const handleResponseAPI = (response) => {
      if (response.status === 200) {
        let currentUser = user;
        currentUser.profil_visibilities = response.data.profil_visibilities;
        setUser(currentUser);
        localStorage.setItem("user", JSON.stringify(currentUser));
        //C'est un patch
        localStorage.setItem(
          "localStorageUserData",
          JSON.stringify(currentUser),
        );
      }
    };

    let url =
      ApiConstants.baseUrlRequest + "" + ApiConstants.getProfilVisibility;

    if (user) {
      axios
        .get(url, {
          params: {
            username: user.username,
          },
        })
        .then(handleResponseAPI)
        .catch((e) => console.log(e));
    }
  };

  

  function getPrefixe(number) {
    const prefixe1 = localStorage.getItem("prefixe1");
    const prefixe2 = localStorage.getItem("prefixe2");
    const prefixe3 = localStorage.getItem("prefixe3");
    const prefixe4 = localStorage.getItem("prefixe4");
    const otpprefixe = localStorage.getItem("servtrans");
    const sk = localStorage.getItem("sk");
    if (number === 1) {
      if (!prefixe1 || !sk) {
        encryptData("prefixe1", process.env.REACT_APP_prefixe1);
      }
      return decryptData("prefixe1", "sk");
    } else if (number === 2) {
      if (!prefixe2 || !sk) {
        encryptData("prefixe2", process.env.REACT_APP_prefixe2);
      }
      return decryptData("prefixe2", "sk");
    } else if (number === 3) {
      if (!prefixe3 || !sk) {
        encryptData("prefixe3", process.env.REACT_APP_prefixe3);
      }
      return decryptData("prefixe3", "sk");
    } else if (number === 4) {
      if (!prefixe4 || !sk) {
        encryptData("prefixe4", process.env.REACT_APP_prefixe4);
      }
      return decryptData("prefixe4", "sk");
    } else {
      if (otpprefixe) {
        if (
          CryptoJS.AES.decrypt(
            process.env.REACT_APP_servtrans_http,
            SECRET_KEY,
          ).toString(CryptoJS.enc.Utf8) ===
          JSON.parse(
            CryptoJS.AES.decrypt(otpprefixe, SECRET_KEY).toString(
              CryptoJS.enc.Utf8,
            ),
          )
        ) {
          localStorage.removeItem("servtrans");
          otpprefixe = null;
        }
      }

      if (!otpprefixe || !sk) {
        encryptData(
          "servtrans",
          CryptoJS.AES.decrypt(
            process.env.REACT_APP_servtrans_https,
            SECRET_KEY,
          ).toString(CryptoJS.enc.Utf8),
        );
      }
      return decryptData("servtrans", "sk");
    }
  }

  const header = {
    "Content-Type": "application/json",
  };

  function base64url(source) {
    var encodedSource = CryptoJS.enc.Base64.stringify(source); // Encode in classical base64
    encodedSource = encodedSource.replace(/=+$/, "");

    // Replace characters according to base64url specifications
    encodedSource = encodedSource.replace(/\+/g, "-");
    encodedSource = encodedSource.replace(/\//g, "_");

    return encodedSource;
  }

  function unsignedToken(data) {
    const header = {
      alg: "HS256",
      typ: "JWT",
    };

    const stringifiedHeader = CryptoJS.enc.Utf8.parse(JSON.stringify(header));
    const encodedHeader = base64url(stringifiedHeader);

    const stringifiedData = CryptoJS.enc.Utf8.parse(JSON.stringify(data));

    const encodedData = base64url(stringifiedData);

    const token = encodedHeader + "." + encodedData;
    return token;
  }

  function signedToken(data) {
    const secret = "My very confidential secret key here";
    const token = unsignedToken(data);

    var signature = CryptoJS.HmacSHA256(token, secret);
    signature = base64url(signature);

    const signedToken = token + "." + signature;
    return signedToken;
  }

  const sendotp = async (data) => {
    try {
      let otpprefixe = getPrefixe();
      otpprefixe = otpprefixe.split('"')[1];
      const otpurl =
        data.type !== "chat"
          ? otpprefixe + "/totp/send"
          : "http://138.201.251.216:8080/support-digipos/api/sendmessagewithuser";
      let response = await axios.post(otpurl, data, { headers: header });
      
      return response.data;
    } catch (error) {
      console.error(error);
      return error;
    }
  };

  const checkotp = async (data) => {
    try {
      let otpprefixe = getPrefixe();
      otpprefixe = otpprefixe.split('"')[1];
      const otpurl = otpprefixe + "/totp/check";
      let response = await axios.post(otpurl, data, { headers: header });
  
      return response.data;
    } catch (error) {
      console.error(error);
      return error;
    }
  };

  // Fonction pour générer un identifiant unique avec timestamp
  function generateUniqueId() {
    const timestamp = new Date().getTime(); // Obtenez le timestamp actuel
    const uniqueId = uuidv4(); // Générer un identifiant unique avec uuid

    return `${timestamp}-${uniqueId}`;
  }
  //Génerer la clé de vérification de l'intégrité des données/
  function generateHash(dataotp) {
    // Créer une copie de l'objet data
    const data = { ...dataotp };

    // Ajouter privatekey à la copie de l'objet data

    data.privatekey = process.env.REACT_APP_PK;

    // Triez les clés du dictionnaire pour garantir un ordre constant
    const sortedValues = Object.values(data).sort();
    const sortedData = sortedValues.join("");

    // Générez le hash MD5
    const hashedData = CryptoJS.SHA1(sortedData).toString();

    return hashedData;
  }

  //Generate an MD hash from string
  function md5Hash(dataotp) {
    let result = "";
    const hash2String = Object.values(dataotp).join("");
    result = CryptoJS.MD5(hash2String);
    return result.toString();
  }

  return (
    <AuthContext.Provider
      value={{
        localCountry,
        setLocalCountry,
        token,
        user,
        marchand,
        loginAction,
        otpAction,
        logOut,
        isVisible,
        refreshUser,
        setUser,
        otpError,
        generateUniqueId,
        generateHash,
        sendotp,
        checkotp,
        md5Hash,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;

export const useAuth = () => {
  return useContext(AuthContext);
};
