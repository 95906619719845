import React, { useState } from "react";
import PhoneInput from 'react-phone-input-2'

export default class PhoneInputGfg extends React.Component {
	constructor(props) {
		super(props);
	}
	render() {
		return (
			<div>
				<PhoneInput
					countryCallingCodeEditable={false}
					isValid={(value, country) => {
						if (value.match(/12345/)) {
							
						return 'Invalid value: '+value+', '+country.name;
						} else if (value.match(/1234/)) {
							
						return false;
						} else {
							
						return true;
						}
					}} 
					className="mb-3"
					specialLabel=""
					
					countryCodeEditable={false}
					disableDropdown={true} 
					country={this.props.localCountry.value.toLowerCase()}
					onChange={(phone) => {
						this.props.setPhone(phone)
					}}
				/>
			</div>
		);
	}
};
