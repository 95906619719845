import React, { useEffect, useState, useContext } from "react";
import { Route, Switch, useLocation, useHistory } from "react-router-dom";
import Header from "components/Home/Header";
import Footer from "components/Home/Footer";
import routes from "router/home-routes.js";
import ApiConstants from "constant";
import axios from "axios";
import { useAuth, AuthContext } from "AuthProvider";

const Home = () => {
  const location = useLocation();
  const context = useAuth();
  const history = useHistory();
  const auth = useAuth();
  const [isConnected, setIsConnected] = useState(false);
  const [user, setUser] = useState(context.user);

  const [notificationsNumber, setNotificationsNumber] = useState([]);

  useEffect(() => {
    if (JSON.parse(localStorage.getItem("user"))) {
      setUser(JSON.parse(localStorage.getItem("user")));
      setIsConnected(true);
    } else {
      setIsConnected(false);
    }

    const interval = setInterval(() => {
      // Call the hook inside the setInterval function
      let temp_user = JSON.parse(localStorage.getItem("user"));
      if (temp_user) {
        setUser(temp_user);
        setIsConnected(true);
      } else {
        setIsConnected(false);
      }
    }, 1000);

    // Clean up the interval when the component unmounts
    return () => {
      if (user) {
        clearInterval(interval);
      }
    };
  }, []);

  useEffect(() => {
    if (user && user.type === "marchand" && isConnected) {
      axios
        .get(
          ApiConstants.baseUrlRequest + ApiConstants.getAdressesToBeValided,
          { params: { username: user.username } },
        )
        .then((response) => {
          setNotificationsNumber(response.data.adresses.length);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [user]);

  useEffect(() => {
    let url = ApiConstants.baseUrlRequest + ApiConstants.getMarchand;
    axios
      .get(url)
      .then(function (response) {
        if (response.status == 200) {
          // setIsLoading(false);
          // setIsFound(true);
          localStorage.setItem(
            "marchand",
            JSON.stringify(response.data.marchand),
          );
        } else if (response.status == 404) {
          // setIsLoading(false);
          // setIsFound(false);
        }
      })
      .catch(function (error) {
        // setIsError(true);
        // setError(error);
      });
  }, [localStorage.getItem("marchand") === null]);

  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.layout === "/home") {
        if (prop.path === "/update_adresse/:user/:adress") {
          return (
            <Route
              exact
              path={prop.layout + prop.path}
              render={(props) => (
                <prop.component
                  {...props}
                  user={props.match.params.user}
                  adress={props.match.params.adress}
                  context={context}
                />
              )}
            />
          );
        }
        if (prop.path === "/createpart") {
          return (
            <Route
              exact
              path={prop.layout + prop.path}
              render={(props) => <prop.component {...props} />}
            />
          );
        }

        return (
          <Route
            exact
            path={prop.layout + prop.path}
            render={(props) => <prop.component {...props} context={context} />}
          />
        );
      } else {
        <Route
          exact
          path={prop.layout + prop.path}
          render={(props) => <prop.component {...props} context={context} />}
        />;
        // return null
      }
    });
  };

  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [location]);

  return (
    <>
      <div
        style={{
          overflowX: "hidden",
        }}
        className="App"
      >
        <Header
          isConnected={isConnected}
          user={user}
          history={history}
          auth={auth}
          notificationsNumber={notificationsNumber}
        />
        <div style={{ marginTop: "106px" }}>
          <Switch>{getRoutes(routes)}</Switch>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default Home;
