export function remove_style_script_home() {
  //remove all Home style whom would make fight
  let childLinkForStyleHome = document.getElementById("uncode-style-css");
  let childLinkForStyleCustomHome = document.getElementById(
    "uncode-custom-style-css",
  );

  if (childLinkForStyleHome) {
    document.head.removeChild(childLinkForStyleHome);
  }

  if (childLinkForStyleCustomHome) {
    document.head.removeChild(childLinkForStyleCustomHome);
  }
}

export function masking_transition(idScript, idComponent, display) {
  let script_show = document.createElement("script");
  script_show.type = "text/javascript";
  script_show.id = idScript;
  script_show.textContent =
    "setTimeout(function(){document.getElementById('" +
    idComponent +
    "').style.visibility='" +
    display +
    "'}, 500);";
  script_show.defer = true;
  return document.body.appendChild(script_show);
}

export const  customStyleForReactSelect ={
  control: (baseStyles, state) => ({
    ...baseStyles,
    borderColor: '#F9F9EA',
  }),
  option: (baseStyles, state) => ({
    ...baseStyles,
    color: '#8EA841',
  }),
  placeholder: (baseStyles, state) => ({
    ...baseStyles,
    color: '#8EA841',
  }),
  dropdownIndicator: (baseStyles, state) => ({
    ...baseStyles,
    color: '#8EA841',
  }),
};
