class ApiConstants {
    // static baseUrlRequest = "http://10.0.229.151:8000/api"; //levinne
    // static baseUrlRequest = "http://10.0.1.27:8000/api";    //Darlin
    //  static baseUrlRequest = "http://65.21.15.22:80/api"; //serveur de test
    // static baseUrlRequest =  "https://monisolation.be/worldvoiceinvoice_laravel/public/api";    // serveur de production monisolation.be
     static baseUrlRequest =  "https://monisolation.eu/worldvoiceinvoice_laravel/public/api";    // serveur de production monisolation.eu
  
  

  //Endpoints
  static getInvoiceProfessionnel = "/facturations/professionnel";
  static getDevisParticulars = "/facturations/particulier/devis";
  static getArticlesEndpoint = "/articles";
  static getClientsEndpoint = "/clients";
  static getProfilVisibilityEndpoint = "/profil_visibility";
  static setDevisWithOrganigram = "/facturations_organigramme/create_isolation";
  static convertInvoice = "/facturations/convert";
  static deleteInvoice = "/facturations/delete";
  static getEmailsModels = "/emails";
  static setEmailsModels = "/email";
  static updateFacturation = "/facturations";
  static updateAdresses = "/newaddress";
  static setAllocationInvoice = "/facturations/allocation_devis";
  static setStatusInvoice = "/facturation/changement_status   ";
  static setStatusInvoiceByClient = "/facturation/changement_status   ";
  static setPdfToSend = "/send-invoice";
  static createClient = "/clients/new";
  static setMailAdresses = "/email/verification_address";
  static getAdresse = "/adresses";
  static getAddresstovalidate = "/addresstovalidate";
  static updateCreateClient = "/client/update";
  static login = "/clients/login";
  static verify = "/clients/verify";
  static searchclient = "/search-client";
  static searchinvoice = "/facturations-search";
  static activeClient = "/activate";
  static deactiveClient = "/clients/desactive";
  static facturationsBbyType = "/facturations-by-type";
  static facturationsByStatus = "/facturations-by-status";
  static facturationsByStatusType = "/facturations-by-status";
  static facturationsByMonth = "/facturations-by-month";
  static facturationsByMonthType = "/facturations-by-month-type";
  static facturationsByMonthStatus = "/facturations-by-month-status";
  static facturationsByYear = "/facturations-by-year";
  static facturationsByYearType = "/facturations-by-year-type";
  static facturationsByYearStatus = "/facturations-by-year-status";
  static facturationsByMonthTypes = "/facturations-by-month-types";
  static facturationsAmountByMonthAndStatus ="/facturations-amount-by-month-status";
  static getDataForExport = "/facturations-export";
  static addArticle = "/articles/new";
  static editArticle = "/articles/update";
  static getApplications = "/cibles";
  static deleteArticle = "/articles/delete";
  static searchArticles = "/articles/search";
  static getProfilVisibility = "/profil_visibility"
  static getResumeInvoice = "/facturations-details"
  static getMarchand = "/marchands";
  static getAdressesToBeValided = "/addresstovalidate";
  static getAdresseAndClient = "/adresse";
  static editAdresse = "/adresses";
  static editMarchand = "/marchand";
  static updateDevis = "/facturations/update_organigramme"
}


export default ApiConstants;
