import React from "react";
import Image from "react-bootstrap/Image";
import {
  Badge,
  Button,
  Card,
  Navbar,
  Nav,
  Container,
  Row,
  Col,
} from "react-bootstrap";

import { NavLink, useLocation } from "react-router-dom";

function ArticleShow() {
  const location = useLocation();
  const article = location.state.article;

  const image = article.image;

  return (
    <Container fluid>
      <Row>
        <Col
          sm="12"
          className="d-flex align-items-center justify-content-center mb-5"
        >
          {image && (
            <Col sm="12" className="px-1">
              <img
                src={`data:image/jpeg;base64, ${image}`}
                alt="Image de l'article"
                style={{ width: "65rem", height: "35rem", objectFit: "cover" }}
              />
            </Col>
          )}
        </Col>
      </Row>
      <Row>
        <Col sm="12" md="6">
          <div className="mb-2">
            <strong>Intitulé:</strong> {article.libelle}
          </div>
          {article.thickness !== null ? (
            <div className="mb-2">
              <strong>Épaisseur:</strong> {article.thickness} {article.unit}
            </div>
          ) : null}

          {article.surface !== null ? (
            article.unit === "m2" ? (
              <div className="mb-2">
                <strong>Surface:</strong> {article.surface}{" "}
                <var>
                  m<sup>2</sup>
                </var>
              </div>
            ) : null
          ) : null}
          <div className="mb-2">
            <strong>Prix:</strong> {article.price + " €"}
          </div>
          <div className="mb-2">
            <strong>Description:</strong> {article.description}
          </div>
          {!article.libelle.includes("Forfait") ? (
            <div className="mb-2">
              <strong>Marque:</strong> {article.brand}
            </div>
          ) : null}
        </Col>
        <Col sm="12" md="6">
          <Row>
            <Col sm="12">
              <div className="mb-2">
                <strong>Type:</strong> {article.type}
              </div>
              {!article.libelle.includes("Forfait") ? (
                <>
                  <div className="mb-2">
                    <strong>Conductivité:</strong> {article.conductivity}
                  </div>
                  <div className="mb-2">
                    <strong>Résistance thermique:</strong>{" "}
                    {article.thermal_resistance}
                  </div>{" "}
                </>
              ) : null}
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col className="d-flex justify-content-evenly align-items-center mt-5">
          <NavLink
            className="btn btn-fill btn-lg btn-primary text-decoration-none"
            to={{
              pathname: "/welcome/article",
              state: { article: "plus tard" },
            }}
          >
            Retour
          </NavLink>
        </Col>
      </Row>
    </Container>
  );
}

export default ArticleShow;
