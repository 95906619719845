import React from "react";
import { useState } from "react";
import {
  Badge,
  Button,
  Card,
  Form,
  Navbar,
  Table,
  Nav,
  Container,
  Row,
  Col,
} from "react-bootstrap";
import { Label } from "reactstrap";
import Pagination from "components/utils/Pagination";
import { Link } from "react-router-dom";
import ApiConstants from "constant";
import axios from "axios";
import { Dots } from "react-activity";
import "react-activity/dist/library.css";
import Error from "components/utils/Error";
import { AuthContext } from "AuthProvider";

class UserProfile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      validated: false,
      isLoading: false,
      isFound: true,
      isError: false,
      error: "",
    };
    this.handleResponse = this.handleResponse.bind(this);
    this.handleError = this.handleError.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
  }

  handleResponse(response) {
    if (response.status === 200) {
      this.setState({ isLoading: false });
      this.context.setUser(response.data.client);
      localStorage.setItem("user", JSON.stringify(response.data.client));
    }
  }
  handleError(error) {
    if (error.isAxiosError) {
      if ("response" in error) {
        if (error.response.status === 401) {
          this.setState({ isLoading: false });
        } else if (error.response.status === 404) {
          this.setState({ isLoading: false });
          this.setState({ isFound: false });
        } else {
          this.setState({ isLoading: false });
          this.setState({ isError: true });
          this.setState({ error: error });
        }
      } else {
        this.setState({ isLoading: false });
        this.setState({ isError: true });
        this.setState({ error: error });
      }
    } else {
      console.log("No Axios error");
    }

    console.log(error);
  }

  handleSubmit = (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.stopPropagation();
      this.setState({ validated: true });
    } else {
      this.setState({ validated: false });
      this.setState({ isLoading: true });
      const data = {
        name: this.name.value,
        region: this.region.value,
        country: this.country.value,
        phone_number: this.phone_number.value,
        email: this.email.value,
        num_tva: this.num_tva.value,
        username: this.context.user.username,
        code_client: this.context.user.code_client,
      };
      let url =
        ApiConstants.baseUrlRequest + "" + ApiConstants.updateCreateClient;
      axios.put(url, data).then(this.handleResponse).catch(this.handleError);
    }
  };

  static contextType = AuthContext

  render() {
    if (this.state.isLoading === true) {
      return (
        <Container fluid>
          <div className="d-flex justify-content-center align-items-center">
            <Dots
              color="#727981"
              size={32}
              speed={1}
              animating={this.state.isLoading}
            />
          </div>
        </Container>
      );
    }

    if (this.state.isLoading === false && this.state.isFound === false) {
      return <Error code={404} />;
    }

    if (this.state.isError === true) {
      if (this.state.error.message === "Network Error") {
        return <Error code={-1} />;
      }
      return <Error code={-2} />;
    }
    const user = this.context.user;
    return (
      <>
        <Container fluid>
          <Row>
            <Col sm="12">
              <Card>
                <Card.Header>
                  <Card.Title as="h4">Information personnelle</Card.Title>
                </Card.Header>
                <Card.Body>
                  <Form
                    noValidate
                    validated={this.state.validated}
                    onSubmit={(e) => this.handleSubmit(e)}
                  >
                    <Row>
                      <Col className="px-1" sm="6">
                        <Form.Group controlId="name">
                          <Form.Label>Nom(s) & Prénom(s)</Form.Label>
                          <Form.Control
                            required
                            ref={(input) => (this.name = input)}
                            defaultValue={user.name}
                            placeholder="Vos nom(s) & prénom(s)"
                            type="text"
                          ></Form.Control>
                        </Form.Group>
                      </Col>
                      <Col className="pl-1" sm="6">
                        <Form.Group controlId="email">
                          <Form.Label>E-mail</Form.Label>
                          <Form.Control
                            required
                            ref={(input) => (this.email = input)}
                            defaultValue={user.email}
                            placeholder="Votre e-mail"
                            type="email"
                          ></Form.Control>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm="6">
                        <Form.Group>
                          <Form.Label>Numéro du mobile</Form.Label>
                          <Form.Control
                            required
                            ref={(input) => (this.phone_number = input)}
                            defaultValue={user.phone_number}
                            placeholder="Votre numéro de téléphone"
                            type="text"
                          ></Form.Control>
                        </Form.Group>
                      </Col>
                      <Col className="pl-1" sm="6">
                        <Form.Group controlId="tva">
                          <Form.Label>Taux de TVA</Form.Label>
                          <Form.Control
                            required
                            ref={(input) => (this.num_tva = input)}
                            defaultValue={parseInt(user.num_tva)}
                            placeholder="Votre taux de TVA"
                            type="number"
                          ></Form.Control>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col className="pr-1" sm="6">
                        <Form.Group>
                          <Form.Label>Région</Form.Label>
                          <Form.Control
                            required
                            ref={(input) => (this.region = input)}
                            defaultValue={user.region}
                            placeholder="Votre région"
                            type="text"
                          ></Form.Control>
                        </Form.Group>
                      </Col>
                      <Col className="px-1" sm="6">
                        <Form.Group controlId="pays">
                          <Form.Label>Pays</Form.Label>
                          <Form.Control
                            required
                            ref={(input) => (this.country = input)}
                            defaultValue={user.country}
                            placeholder="Country"
                            type="text"
                          ></Form.Control>
                        </Form.Group>
                      </Col>
                    </Row>

                    <div className="d-flex align-items-center justify-content-center">
                      <Button
                        className="btn-fill pull-right my-5"
                        type="submit"
                        variant="info"
                      >
                        Éditer
                      </Button>
                    </div>
                    <div className="clearfix"></div>
                  </Form>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default UserProfile;
