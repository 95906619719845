import React from "react";
import { VscError } from "react-icons/vsc";

class Error extends React.Component{
    constructor(props){
        super(props);
    }

    render(){
        let msg = "";
        switch(this.props.code){
            case 404 :
                msg = "Page non trouvé !"
                break;
            case -1 :
                msg = "Problème de connexion !"
                break;
            default:
                msg = "Oups erreur inattendu !"
                break;
        }
        
        return (
            <section className="d-flex justify-content-center align-items-center" style={{ width: '100%',}}>
               <div className="d-flex justify-content-center align-items-center" style={{ width: '50rem', height:'30rem' }} >
                    <div className="me-3">
                        <VscError color="#f00" size={90}/>
                    </div>
                    <div>
                        <span className="display-6 fw-none">{msg}</span>
                    </div>
                </div>
            </section>
        );
    }
}

export default Error;