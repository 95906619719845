import React, { useState } from "react";
import { AiOutlineDownload } from "react-icons/ai";
// react-bootstrap components
import {
  Badge,
  Button,
  Card,
  Modal,
  Navbar,
  Nav,
  Container,
  Row,
  Col,
  Form,
  Table,
} from "react-bootstrap";
import { NavLink } from "react-router-dom";

import { useLocation } from "react-router-dom";

function InfosClient() {
  const [show, setShow] = useState(false);
  const location = useLocation();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <>
      <Container fluid>
        <Row>
          <Row>
            <Col
              sm="6"
              className="d-flex justify-content-left align-items-left"
            >
              <figure>
                <img
                  style={{ height: "150px" }}
                  className="cardLogoBig"
                  src={require("assets/img/logo.png")}
                  alt="..."
                />
              </figure>
            </Col>

            <Col
              sm="6"
              className="d-flex justify-content-end align-items-center"
            >
              <AiOutlineDownload size={40} />
            </Col>
          </Row>
          <Row>
            <Col
              md="12"
              className="d-flex justify-content-center align-items-center"
            >
              <div className="bg-success py-1 px-2 rounded">
                {location.pathname === "/welcome/user/infos" ? (
                  <h3 className=" text-white m-0 p-0">
                    Informations du Partenaires
                  </h3>
                ) : location.pathname === "/welcome/client/infos" ? (
                  <h3 className=" text-white m-0 p-0">
                    Informations du client
                  </h3>
                ) : location.pathname === "/welcome/soustraitant/infos" ? (
                  <h3 className=" text-white m-0 p-0">
                    Informations du sous-traitant
                  </h3>
                ) : null}
              </div>
            </Col>
          </Row>
          <Row className="d-flex align-items-center justify-content-between my-5">
            <Card>
              <Card.Body>
                <Row>
                  <Col md="4">
                    <div class="row align-items-end custom-line">
                      <div class="col">Code client: </div>
                      <div class="col" style={{ fontWeight: "bold" }}>
                        {location.state.client.code_client}
                      </div>
                    </div>
                  </Col>
                  <Col md="4">
                    <div class="row align-items-end custom-line">
                      <div class="col">Nom de famille: </div>
                      <div class="col" style={{ fontWeight: "bold" }}>
                        {location.state.client.last_name}
                      </div>
                    </div>
                  </Col>
                  <Col md="4">
                    <div class="row align-items-end custom-line">
                      <div class="col">Rue,numero,boite : </div>
                      <div class="col" style={{ fontWeight: "bold" }}>
                        {location.state.client.adresse.street}{" "}
                        {location.state.client.adresse.number_street}{" "}
                        {location.state.client.adresse.box}
                      </div>
                    </div>
                  </Col>
                </Row>
                <br></br>
                <Row>
                  <Col md="4">
                    <div class="row align-items-end custom-line">
                      <div class="col">Email: </div>
                      <div class="col" style={{ fontWeight: "bold" }}>
                        {location.state.client.email}
                      </div>
                    </div>
                  </Col>
                  <Col md="4">
                    <div class="row align-items-end custom-line">
                      <div class="col">Prénom: </div>
                      <div class="col" style={{ fontWeight: "bold" }}>
                        {location.state.client.first_name}
                      </div>
                    </div>
                  </Col>
                  <Col md="4">
                    <div class="row align-items-end custom-line">
                      <div class="col">Code postal: </div>
                      <div class="col" style={{ fontWeight: "bold" }}>
                        {location.state.client.adresse.postal_code}
                      </div>
                    </div>
                  </Col>
                </Row>
                <br></br>
                <Row>
                  <Col md="4">
                    <div class="row align-items-end custom-line">
                      <div class="col">Numéro du mobile: </div>
                      <div class="col" style={{ fontWeight: "bold" }}>
                        {location.state.client.phone_number}
                      </div>
                    </div>
                  </Col>
                  <Col md="4">
                    <div class="row align-items-end custom-line">
                      <div class="col">Numero de TVA: </div>
                      <div class="col" style={{ fontWeight: "bold" }}>
                        {location.state.client.num_tva
                          ? "BE" + location.state.client.num_tva
                          : "Aucun"}
                      </div>
                    </div>
                  </Col>

                  <Col md="4">
                    <div class="row align-items-end custom-line">
                      <div class="col">Pays: </div>
                      <div class="col" style={{ fontWeight: "bold" }}>
                        {location.state.client.adresse.country}
                      </div>
                    </div>
                  </Col>
                </Row>
                <br></br>
                <Row></Row>
              </Card.Body>
            </Card>
          </Row>
          <Row>
            <Col className="d-flex justify-content-evenly align-items-center mt-5">
              {location.pathname === "/welcome/user/infos" ? (
                <NavLink
                  className="btn btn-fill btn-primary"
                  to={{ pathname: "/welcome/user" }}
                >
                  Retour
                </NavLink>
              ) : location.pathname === "/welcome/client/infos" ? (
                <NavLink
                  className="btn btn-fill btn-primary"
                  to={{ pathname: "/welcome/client" }}
                >
                  Retour
                </NavLink>
              ) : location.pathname === "/welcome/soustraitant/infos" ? (
                <NavLink
                  className="btn btn-fill btn-primary"
                  to={{ pathname: "/welcome/soustraitant" }}
                >
                  Retour
                </NavLink>
              ) : null}
            </Col>
          </Row>
        </Row>
      </Container>
    </>
  );
}

export default InfosClient;
