import React from "react";
import {
  Popover,
  OverlayTrigger,
  ButtonToolbar,
  Badge,
  Button,
  Card,
  Form,
  Navbar,
  Table,
  Nav,
  Container,
  Row,
  Col,
} from "react-bootstrap";
import { FaEdit, FaSearch } from "react-icons/fa";
import "react-activity/dist/library.css";
import { Link, withRouter, NavLink } from "react-router-dom";

import { Dots } from "react-activity";
import "react-activity/dist/library.css";

import { AuthContext } from "AuthProvider";
import Error from "components/utils/Error";
import ApiConstants from "constant";
import axios from "axios";

class ModifDevis extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      isFound: true,
      isError: false,
      error: "",
    };
    this.handleResponse = this.handleResponse.bind(this);
    this.handleError = this.handleError.bind(this);
  }

  static contextType = AuthContext;

  handleResponse(response) {
    if (response.status === 200) {
      this.setState({ isLoading: false });
      this.props.history.push({
        pathname: "/welcome/devis",
      });
    } else if (response.status == 404) {
      this.setState({ isLoading: false });
      this.setState({ isFound: false });
    }
  }
  handleError(error) {
    this.setState({ isLoading: false });
    this.setState({ isError: true });
    this.setState({ error: error });
  }

  render() {
    const invoice = this.props.location.state.invoice;
    if (this.state.isLoading === true) {
      return (
        <Container fluid>
          <div className="d-flex justify-content-center align-items-center">
            <Dots
              color="#727981"
              size={32}
              speed={1}
              animating={this.state.isLoading}
            />
          </div>
        </Container>
      );
    }

    if (this.state.isLoading === false && this.state.isFound === false) {
      return <Error code={404} />;
    }

    if (this.state.isError === true) {
      if (this.state.error.message === "Network Error") {
        return <Error code={-1} />;
      }
      return <Error code={-2} />;
    }
    return (
      <Container fluid>
        <Row>
          <Col>
            <h1 className="h1 mt-0">Modifier un Devis</h1>
          </Col>
        </Row>
        <Row>
          <Col sm="12" className="d-flex justify-content-center">
            <Card style={{ width: "100%" }}>
              <Card.Title className="bg-secondary text-white fw-bold fs-5">
                {" "}
                <div className="m-3">Recapitulatif </div>{" "}
              </Card.Title>
              <Card.Body className="p-5">
                <Form>
                  <div className="form-card text-start mb-3">
                    <div className="row">
                      <Col md="6" className="mb-3">
                        <p
                          style={{ fontSize: "15", fontWeight: "bold" }}
                          htmlFor="surface"
                          placeholder="Surface en m²"
                        >
                          N° Devis: <strong>{invoice.num_facturation}</strong>
                        </p>
                      </Col>
                      {invoice.client ? (
                        <Col md="6" className="mb-3">
                          <p
                            style={{ fontSize: "15", fontWeight: "bold" }}
                            htmlFor="epaisseur"
                            placeholder="epaisseur en cm"
                          >
                            Nom Client: <strong>{invoice.client.name}</strong>
                          </p>
                        </Col>
                      ) : null}
                      <Col md="6" className="mb-3">
                        <p
                          style={{ fontSize: "15", fontWeight: "bold" }}
                          htmlFor="surface"
                          placeholder="Surface en m²"
                        >
                          Montant TTC:{" "}
                          <strong>{invoice.montant_ttc} EUR</strong>
                        </p>
                      </Col>
                    </div>
                  </div>
                </Form>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col sm="12" className="d-flex justify-content-center">
            <Card style={{ width: "100%" }}>
              <Card.Title className="bg-secondary text-white fw-bold fs-5">
                {" "}
                <div className="m-3">
                  {" "}
                  <FaEdit className="me-3" /> Zone de modification{" "}
                </div>{" "}
              </Card.Title>
              <Card.Body className="p-5">
                <Form>
                  <div className="form-card text-start mb-3">
                    <div className="row">
                      <div className="col-7 text-center">
                        <h3 className="mb-4">Dimensions</h3>
                      </div>
                    </div>
                    <div className="row">
                      <Col md="12" className="mb-3">
                        {" "}
                      </Col>
                      <Col md="12" className="mb-3">
                        <Form.Label
                          style={{
                            color: "black",
                            fontSize: "15",
                            fontWeight: "bold",
                          }}
                          htmlFor="surface"
                          placeholder="Surface en m²"
                        >
                          Surface (m²):
                        </Form.Label>
                        <Form.Control
                          name="surface"
                          type="number"
                          id="surface"
                          required
                          ref={(input) => (this.surface = input)}
                          defaultValue={invoice.surface}
                        />
                      </Col>
                      <Col md="12" className="mb-3">
                        <Form.Label
                          style={{
                            color: "black",
                            fontSize: "15",
                            fontWeight: "bold",
                          }}
                          htmlFor="epaisseur"
                          placeholder="Épaisseur en cm"
                        >
                          Épaisseur (cm):
                        </Form.Label>
                        <Form.Control
                          name="epaisseur"
                          type="number"
                          id="epaisseur"
                          required
                          ref={(input) => (this.epaisseur = input)}
                          defaultValue={invoice.epaisseur}
                        />
                      </Col>
                      <Col md="12" className="mb-3">
                        <Form.Label
                          style={{
                            color: "black",
                            fontSize: "15",
                            fontWeight: "bold",
                          }}
                          htmlFor="surface"
                          placeholder="Surface en m²"
                        >
                          Description:
                        </Form.Label>
                        <Form.Control
                          name="surface"
                          as={"textarea"}
                          id="surface"
                          required
                          ref={(input) => (this.description = input)}
                          defaultValue={invoice.description}
                        />
                      </Col>
                      <Col md="12" className="mb-3">
                        <Form.Label
                          style={{
                            color: "black",
                            fontSize: "15",
                            fontWeight: "bold",
                          }}
                          htmlFor="surface"
                          placeholder="Surface en m²"
                        >
                          Condition de paiement:
                        </Form.Label>
                        <Form.Control
                          name="surface"
                          as={"textarea"}
                          id="surface"
                          required
                          ref={(input) => (this.cdPaiement = input)}
                          defaultValue={invoice.condition_paiement}
                        />
                      </Col>
                    </div>
                  </div>
                  <Row>
                    <Col className="d-flex justify-content-evenly align-items-center">
                      <Button
                        className="btn-fill pull-right"
                        type="submit"
                        variant="info"
                        onClick={(e) => {
                          e.preventDefault();
                          this.setState({ isLoading: true });
                          let url =
                            ApiConstants.baseUrlRequest +
                            ApiConstants.updateFacturation;
                          let data = {
                            num_facturation: invoice.num_facturation,
                            username: this.context.user.username,
                            description: this.description.value,
                            surface: this.surface.value,
                            epaisseur: this.epaisseur.value,
                            condition_paiement: this.cdPaiement.value,
                          };

                          axios
                            .put(url, data)
                            .then(this.handleResponse)
                            .catch(this.handleError);
                        }}
                      >
                        Modifier
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default ModifDevis;
