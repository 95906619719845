import React, { Component } from "react";
import { useLocation, Route, Switch, Redirect } from "react-router-dom";
import { useAuth } from "AuthProvider";
import WelcomeNavbar from "components/Navbars/WelcomeNavbar";
import Footer from "components/Footer/Footer";
import Sidebar from "components/Sidebar/Sidebar";
import ArticleShow from "views/ArticleShow";
import ArticleCreate from "views/ArticleCreate";
import routes from "router/welcome-routes.js";
import sidebarImage from "assets/img/sidebar-3.jpg";
import InfosClient from "views/InfosClient";
import ListePermissions from "views/ListePermissions";
import ModifDevis from "views/ModifDevis";
import AcceptDevisView from "views/AcceptDevisView";
import { useHistory } from "react-router-dom";
import CreateQuotationWithFlowchart from "views/create-quotation-with-flowchart";
import Adresse from "views/Adresse";
import InfosAdresse from "views/InfosAdresse";
import { remove_style_script_home } from "utils";
import { masking_transition } from "utils";
import InvoiceShow from "views/InvoiceShow";

function Welcome(props) {
  const user = useAuth();

  if (!user.token) {
    return <Redirect to="/home/connection" />;
  }
  const [image, setImage] = React.useState(sidebarImage);
  const [color, setColor] = React.useState("black");
  const [hasImage, setHasImage] = React.useState(true);
  const location = useLocation();
  const mainPanel = React.useRef(null);
  const history = useHistory();
  let childLinkForLightBoostrapReactv2_0_0 = null;
  let childLinkForBoostrap = null;
  let childLinkForStyleFreeDevis = null;
  let childScriptShow = null;

  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.layout === "/welcome") {
        return (
          <Route
            exact
            path={prop.layout + prop.path}
            render={(props) => <prop.component {...props} />}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };
  React.useEffect(() => {
    //remove all Home style whom would make fight
    remove_style_script_home();

    // Adding bootstrap and light bootstrap style react for welcome page
    let linkForBoostrap = document.createElement("link");
    linkForBoostrap.id = "bootstrap_for_welcome";
    linkForBoostrap.type = "text/css";
    linkForBoostrap.rel = "stylesheet";
    linkForBoostrap.href = "/css/bootstrap.min.css";
    childLinkForBoostrap = document.head.appendChild(linkForBoostrap);

    let linkLightBoostrapReactv2_0_0 = document.createElement("link");
    linkLightBoostrapReactv2_0_0.type = "text/css";
    linkLightBoostrapReactv2_0_0.id = "style_lightBoostrapReactv2_0_0";
    linkLightBoostrapReactv2_0_0.rel = "stylesheet";
    linkLightBoostrapReactv2_0_0.href =
      "/css/light-bootstrap-dashboard-react2.css";
    childLinkForLightBoostrapReactv2_0_0 = document.head.appendChild(
      linkLightBoostrapReactv2_0_0,
    );

    // Adding style for free devis
    let linkForStyleFreeDevis = document.createElement("link");
    linkForStyleFreeDevis.id = "style_free_devis";
    linkForStyleFreeDevis.type = "text/css";
    linkForStyleFreeDevis.rel = "stylesheet";
    linkForStyleFreeDevis.href = "/css/style_free_devis.css";
    childLinkForStyleFreeDevis = document.head.appendChild(
      linkForStyleFreeDevis,
    );

    //script for masking transition
    childScriptShow = masking_transition(
      "show_welcome_page",
      "welcome_page",
      "visible",
    );

    // returned function will be called on component unmount
    //Remove all welcome page when go to home
    return () => {
      if (childLinkForBoostrap) {
        document.head.removeChild(childLinkForBoostrap);
      }

      if (childLinkForLightBoostrapReactv2_0_0) {
        document.head.removeChild(childLinkForLightBoostrapReactv2_0_0);
      }
      if (childLinkForStyleFreeDevis) {
        document.head.removeChild(childLinkForStyleFreeDevis);
      }

      if (childScriptShow) {
        document.body.removeChild(childScriptShow);
      }
    };
  }, []);

  React.useEffect(() => {
    user.refreshUser();
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainPanel.current.scrollTop = 0;
    if (
      window.innerWidth < 993 &&
      document.documentElement.className.indexOf("nav-open") !== -1
    ) {
      document.documentElement.classList.toggle("nav-open");
      var element = document.getElementById("bodyClick");
      element.parentNode.removeChild(element);
    }
  }, [location]);
  return (
    <>
      <div
        className="wrapper"
        id="welcome_page"
        style={{ visibility: "hidden" }}
      >
        <Sidebar color={color} image={hasImage ? image : ""} routes={routes} />
        <div className="main-panel" ref={mainPanel}>
          <WelcomeNavbar />
          <div className="content d-flex align-items-center justify-content-center">
            <Switch>
              {getRoutes(routes)}
              <Route
                exact
                path="/welcome/article/show"
                render={(props) => <ArticleShow {...props} />}
              />
              <Route
                exact
                path="/welcome/devis/show"
                render={(props) => <InvoiceShow {...props} />}
              />
              <Route
                exact
                path="/welcome/find_devis/show"
                render={(props) => <InvoiceShow {...props} />}
              />
              <Route
                exact
                path="/welcome/devis/modif"
                render={(props) => <ModifDevis {...props} />}
              />
              <Route
                exact
                path="/welcome/article/create"
                render={(props) => <ArticleCreate {...props} />}
              />
              <Route
                exact
                path="/welcome/facture/create"
                render={(props) => <FactureCreate {...props} />}
              />
              <Route
                exact
                path="/welcome/facture/show"
                render={(props) => <InvoiceShow {...props} />}
              />
              <Route
                exact
                path="/welcome/facture"
                render={(props) => <Facture {...props} />}
              />
              <Route
                exact
                path="/welcome/devis/create"
                render={(props) => <CreateQuotationWithFlowchart {...props} />}
              />
              <Route
                exact
                path="/welcome/devis/create/show"
                render={(props) => <CreatedDevisShow {...props} />}
              />
              <Route
                exact
                path="/welcome/devis/create1"
                render={(props) => <CreateDevisSansOrga {...props} />}
              />
              <Route
                exact
                path="/welcome/bondecommande/create"
                render={(props) => <BonCommandeCreated {...props} />}
              />
              <Route
                exact
                path="/welcome/user/infos"
                render={(props) => <InfosClient {...props} />}
              />
              <Route
                exact
                path="/welcome/client/infos"
                render={(props) => <InfosClient {...props} />}
              />
              <Route
                exact
                path="/welcome/soustraitant/infos"
                render={(props) => <InfosClient {...props} />}
              />
              <Route
                exact
                path="/welcome/adresseavalider/infos"
                render={(props) => <InfosAdresse {...props} />}
              />
              <Route
                exact
                path="/welcome/user/permissions"
                render={(props) => <ListePermissions {...props} />}
              />
              <Route
                exact
                path="/welcome/client/permissions"
                render={(props) => <ListePermissions {...props} />}
              />
              <Route
                exact
                path="/welcome/soustraitant/permissions"
                render={(props) => <ListePermissions {...props} />}
              />
              <Route
                exact
                path="/welcome/bondecommande/show"
                render={(props) => <InvoiceShow {...props} />}
              />
              <Route
                exact
                path="/welcome/accept/devis"
                render={(props) => <AcceptDevisView {...props} />}
              />

              <Route
                exact
                path="/welcome/createPartner"
                render={(props) => <AccountCreate {...props} />}
              />

              <Route
                exact
                path="/welcome/adresseavalider"
                render={(props) => <Adresse {...props} />}
              />
            </Switch>
          </div>
          <Footer />
        </div>
      </div>
    </>
  );
}

export default Welcome;
