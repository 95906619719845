import { ChangeEvent, useState } from 'react';
import { Form } from 'react-bootstrap';

const SingleFile = ({ title, subTitle, onFileSelect }) => {
const handleFileChange = (e, currentFile) => {
  if (e.target.files) {
    const selectedFile = e.target.files[0];
    if (onFileSelect) {
      onFileSelect(selectedFile);
    }
  } else {
    onFileSelect(null); // Réinitialisez le fichier sélectionné
  }
};

  return (
    <Form.Group controlId="chooseFile" className='d-flex justify-content-between align-items-center py-3'>
      <Form.Label>{title.toLowerCase()} <br/> {subTitle}</Form.Label>
        <Form.Control
          type="file"
          onChange={handleFileChange}
        ></Form.Control>
    </Form.Group>
  );
}

export default SingleFile;