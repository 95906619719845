import React, { useRef } from "react";
import NotificationAlert from "react-notification-alert";
import { useState } from "react";
import {
  Alert,
  Badge,
  Button,
  Card,
  Modal,
  Navbar,
  Nav,
  Container,
  Row,
  Col,
  Accordion,
  Form,
} from "react-bootstrap";
import Select from "react-select";

import { Dots } from "react-activity";
import "react-activity/dist/library.css";

import ApiConstants from "constant";
import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import axios from "axios";
import { exportDoc } from "documents";
import Error from "components/utils/Error";
import { CSVLink } from "react-csv";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";

import moment from "moment";
import "moment/locale/fr";
moment.locale("fr");
import { useAuth } from "AuthProvider";

function ExportData() {
  const auth = useAuth();
  const [showModal, setShowModal] = React.useState(false);
  const notificationAlertRef = React.useRef(null);

  const rapportType = [
    { value: "journalier", label: "Rapport jounalier" },
    { value: "hebdomadaire", label: "Rapport hebdomadaire" },
    { value: "mensuel", label: "Rapport mensuel" },
    { value: "periodique", label: "Rapport périodique" },
  ];

  const [boutonActif, setBoutonActif] = useState("pdf");
  const [typeReport, setTypeReport] = useState("periodique");
  const [showPeriod, setShowPeriod] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [isFound, setIsFound] = useState(true);
  const [isError, setIsError] = useState(false);
  const [error, setError] = useState("");
  const [dateErrorMessage, setDateErrorMessage] = useState(
    "Veuillez renseigner les dates de début et de fin."
  );
  const dateDebut = useRef();
  const dateFin = useRef();
  const [dateDebutT, setDateDebutT] = useState("");
  const [dateFinT, setDateFinT] = useState("");
  const history = useHistory();
  const location = useLocation();
  const [csvFilename, setCsvFilename] = useState("Rapport périodique.csv");
  const [dataCsv, setDataCsv] = useState([]);

  const handleResponse = (response) => {
    if (response.status === 200) {

      if (boutonActif === "csv" || boutonActif === "xls") {
        let extension = boutonActif === "csv" ? "csv" : "xls";

        let fileName =
          typeReport === "periodique"
            ? "Rapport périodique du " +
              dateDebutT +
              " au " +
              dateFinT +
              extension
            : "Rapport " + typeReport + extension;
        // let fileName = "test" + extension

        let data = [
          [
            "N°",
            "CLIENT",
            "TYPE SERVICE",
            "DATE CRÉATION",
            "DATE ÉCHÉANCE",
            "MONTANT TTC",
            "DATE DÉBUT",
            "DATE FIN",
          ],
        ];
        Object.keys(response.data.results).map((element) => {
          response.data.results[element].map((article) =>
            data.push([
              article.num_facturation,
              article.client.name,
              article.type_service,
              moment(article.created_at).format("dddd Do MMMM YYYY à HH:mm:ss"),
              moment(article.date_echeance).format(
                "dddd Do MMMM YYYY à HH:mm:ss"
              ),
              article.montant_ttc,
              moment(article.date_debut).format("dddd Do MMMM YYYY à HH:mm:ss"),
              moment(article.date_fin).format("dddd Do MMMM YYYY à HH:mm:ss"),
            ])
          );
        });
        if (boutonActif === "csv") {

          const csvContent =
            "data:text/csv;charset=utf-8," +
            data.map((row) => row.join(",")).join("\n");
          const encodedUri = encodeURI(csvContent);
          const link = document.createElement("a");
          link.setAttribute("href", encodedUri);
          link.setAttribute("download", fileName);
          document.body.appendChild(link); // Required for Firefox
          link.click();
          document.body.removeChild(link);
        } else {
          // Create a new workbook and worksheet
          const workbook = XLSX.utils.book_new();
          const worksheet = XLSX.utils.aoa_to_sheet(data);
          // Add the worksheet to the workbook
          XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet 1");
          // Convert the workbook to an array buffer
          const buffer = XLSX.write(workbook, {
            type: "array",
            bookType: "xls",
          });
          // Create a Blob from the buffer
          const blob = new Blob([buffer], { type: "application/vnd.ms-excel" });
          // Save the Blob as a file using FileSaver.js
          saveAs(blob, fileName);
        }
      } else {
        exportDoc(
          response.data.results,
          typeReport,
          boutonActif,
          dateDebutT,
          dateFinT
        );
      }

      setIsLoading(false);
      history.push({
        pathname: location.pathname,
      });
    }
  };

  const handleError = (error) => {
    if (error.isAxiosError) {
      if ("response" in error) {
        if (error.response.status === 401) {
          setIsLoading(false);
        } else if (error.response.status === 404) {
          setIsLoading(false);
          setIsFound(false);
        } else {
          setIsLoading(false);
          setIsError(true);
          setError(error);
        }
      } else {
        setIsLoading(false);
        setIsError(true);
        setError(error);
      }
    } else {
      console.log("No Axios error");
    }
    if (boutonActif === "csv") {
      // done(false);
    }
    console.log(error);
  };

  const handleDowloadClick = async (event) => {
  
    setDateDebutT();
 
    

    if(typeReport === 'periodique'){
      if (!dateDebut.current.value || !dateFin.current.value) {
        notify("tc");
        if (boutonActif === "csv") {
          // done(true);
        }
        return;
      }

      const dateD = new Date(dateDebut.current.value);
      const dateF = new Date(dateFin.current.value);

      if (dateD.getTime() <= dateF.getTime()) {
        let fileName =
          typeReport === "periodique"
            ? "Rapport périodique du " +
              dateDebut.current.value +
              " au " +
              dateFin.current.value +
              ".csv"
            : "Rapport " + typeReport + ".csv";
        setDateDebutT(dateDebut.current.value);
        setDateFinT(dateFin.current.value);
        setCsvFilename(fileName);

        let data = {
          date_debut: dateDebut.current.value,
          date_echeance: dateFin.current.value,
          username: auth.user.username,
        };

        setIsLoading(true);

        let url = ApiConstants.baseUrlRequest + ApiConstants.getDataForExport;
        

        try {
          const response = await axios.get(url, {
            params: {
              ...data,
            },
          });
          handleResponse(response);
        } catch (error) {
          console.error("Error:", error);
        }
      } else {
        setDateErrorMessage(
          "Veuillez renseigner une prériode valide pour la génération de votre rapport."
        );
        notify("tc");
        if (boutonActif === "csv") {
          // done(false);
        }
        return;
      }
    }else{
      notify("tc");
    }
    
  };

  const handleClick = (bouton) => {
    setBoutonActif(bouton);
  };

  const notify = (place) => {
    var type = "danger";
    var options = {};
    options = {
      place: place,
      message: (
        <div>
          <div>{dateErrorMessage}</div>
        </div>
      ),
      type: type,
      icon: "nc-icon nc-bell-55",
      autoDismiss: 7,
    };
    notificationAlertRef.current.notificationAlert(options);
  };

  if (isLoading === true) {
    return (
      <Container fluid>
        <div className="d-flex justify-content-center align-items-center">
          <Dots color="#727981" size={32} speed={1} animating={isLoading} />
        </div>
      </Container>
    );
  }

  if (isLoading === false && isFound === false) {
    return <Error code={404} />;
  }

  if (isError === true) {
    if (error.message === "Network Error") {
      return <Error code={-1} />;
    }
    return <Error code={-2} />;
  }

  return (
    <>
      <div className="rna-container">
        <NotificationAlert ref={notificationAlertRef} />
      </div>
      <Container fluid>
        <Card>
          <Card.Header>
            <Card.Title as="h4">Exportation des données</Card.Title>
          </Card.Header>
          <Card.Body>
            <Card>
              <Card.Header>
                <Card.Title as="h4">Télécharger les fichiers</Card.Title>
              </Card.Header>

              <Card.Body>
                <Form>
                  <Row>
                    <Col className="text-center" md="12">
                      <Accordion defaultActiveKey="1">
                        <Accordion.Item eventKey="0">
                          <Accordion.Header>Texte explicatif</Accordion.Header>
                          <Accordion.Body>
                            Pour télécharger des fichiers il vous suffit de
                            choisir l'un des formats de fichiers qui vous est
                            proposé, ensuite vous sélectionnez le type de
                            rapport que vous voudriez télécharger ainsi que la
                            période pour enfin télécharger votre fichier
                          </Accordion.Body>
                        </Accordion.Item>
                      </Accordion>
                    </Col>
                  </Row>
                </Form>
              </Card.Body>
            </Card>
            <Card>
              <Card.Header>
                <Card.Title as="h4">Format du fichier</Card.Title>
              </Card.Header>
              <Card.Body>
                <Form>
                  <Row>
                    <Col className="text-center" md="4">
                      <Button
                        className="btn-fill btn-wd"
                        size="sm"
                        variant={boutonActif === "xls" ? "success" : "primary"}
                        onClick={() => handleClick("xls")}
                        id="ident1"
                      >
                        XLS
                      </Button>
                    </Col>
                    <Col className="text-center" md="4">
                      <Button
                        className="btn-fill btn-wd"
                        size="sm"
                        variant={boutonActif === "pdf" ? "success" : "primary"}
                        onClick={() => handleClick("pdf")}
                        id="ident2"
                      >
                        PDF
                      </Button>
                    </Col>
                    <Col className="text-center" md="4">
                      <Button
                        className="btn-fill btn-wd"
                        size="sm"
                        variant={boutonActif === "csv" ? "success" : "primary"}
                        onClick={() => handleClick("csv")}
                        id="ident3"
                      >
                        CSV
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </Card.Body>
            </Card>
            <Card>
              <Card.Body>
                <Row>
                  <Col md="12" className="mb-3">
                    <Form.Label
                      style={{
                        color: "black",
                        fontSize: "15",
                        fontWeight: "bold",
                      }}
                      htmlFor="composant"
                    >
                      Quel type de rapport voulez-vous?
                    </Form.Label>
                    <Select
                      data-trigger
                      name="choices-single-default"
                      id="composant"
                      options={rapportType}
                      placeholder="Sélectionner le type de rapport"
                      defaultValue={{
                        value: "periodique",
                        label: "rapport périodique",
                      }}
                      onChange={(option) => {
                        if (option.value !== "periodique") {
                          setDateErrorMessage("Actuellement seule le rapport périodique est pris en charge.");
                          setShowPeriod(false);

                        } else {
                          setDateErrorMessage("Veuillez renseigner les dates de début et de fin.");
                          setShowPeriod(true);
                        }
                        setTypeReport(option.value);
                      }}
                    />
                  </Col>
                </Row>
                <br></br>
                {showPeriod ? (
                  <Row>
                    <label
                      style={{
                        color: "black",
                        fontSize: "15",
                        fontWeight: "bold",
                      }}
                    >
                      Sélectionner la période
                    </label>
                    <Col md="6" lg="6" sm="12">
                      <Form.Group>
                        <label
                          className="my-2"
                          style={{
                            color: "black",
                            fontSize: "15",
                            fontWeight: "bold",
                          }}
                        >
                          Du
                        </label>
                        <input
                          class="form-control"
                          type="date"
                          name="Du"
                          style={{
                          }}
                          ref={dateDebut}
                        />
                      </Form.Group>
                    </Col>
                    <Col md="6" lg="6" sm="12">
                      <Form.Group>
                        <label
                          className="my-2"
                          style={{
                            color: "black",
                            fontSize: "15",
                            fontWeight: "bold",
                          }}
                        >
                          Au
                        </label>
                        <input
                          class="form-control"
                          type="date"
                          name="Au"
                          style={{
                            
                          }}
                          ref={dateFin}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                ) : null}
              </Card.Body>
            </Card>
            <div>
              <Row>
                <Col sm="12" className="d-flex justify-content-center">
                  <Button
                    className="btn-fill btn-wd my-5"
                    size="sm"
                    variant="primary"
                    onClick={handleDowloadClick}
                  >
                    Télécharger
                  </Button>
                </Col>
              </Row>
            </div>
          </Card.Body>
        </Card>
      </Container>
    </>
  );
}

export default ExportData;
