import "@fortawesome/fontawesome-free/css/all.min.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "assets/css/footer.css";
import "./assets/css/header.css";
import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import WelcomeLayout from "layouts/Welcome.js";
import Home from "layouts/Home";
import Connection from "views/Connection";
import Otp from "views/Otp";
import CreateQuotationWithFlowchart from "views/create-quotation-with-flowchart";
import AuthProvider from "./AuthProvider";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <BrowserRouter>
    <AuthProvider>
      <Switch>
        <Route
          exact
          path="/home/connection"
          render={(props) => <Connection {...props} />}
        />
        <Route
          exact
          path="/home/connection/verify"
          render={(props) => <Otp {...props} />}
        />
        <Route
          exact
          path="/home/devis-gratuit"
          render={(props) => <CreateQuotationWithFlowchart {...props} />}
        />
        <Route path="/home" render={(props) => <Home {...props} />} />
        <Route
          path="/welcome"
          render={(props) => <WelcomeLayout {...props} />}
        />
        <Redirect from="/" to="/home" />
      </Switch>
    </AuthProvider>
  </BrowserRouter>,
);
