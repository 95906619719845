import React from "react";
import { Button, Container, Row, Col } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { AiOutlineDownload } from "react-icons/ai";
import Table from "react-bootstrap/Table";
import JsPDF from "jspdf";

import { useLocation } from "react-router-dom";

import moment from "moment";
import "moment/locale/fr";
moment.locale("fr");

import generatePDF from "documents";
import { AuthContext } from "AuthProvider";

class InvoiceShow extends React.Component {
  constructor(props) {
    super(props);
  }

  static contextType = AuthContext;

  render() {
    const invoice = this.props.location.state.invoice;
    const invoiceType = this.props.location.state.invoiceType;
    return (
      <Container fluid>
        <Row className="mb-5">
          <Col sm="6" className="d-flex justify-content-left align-items-left">
            <figure>
              <img
                style={{height:'150px'}}
                src={require("assets/img/logo.png")}
                alt="..."
              />
            </figure>
          </Col>

          <Col sm="6" className="d-flex justify-content-end align-items-center">
            <Button
              className="btn-icon btn-fill"
              variant="primary"
              title="Télécharger"
              onClick={(e) => {
                generatePDF(invoice, invoiceType, this.context.marchand);
              }}
            >
              <AiOutlineDownload className="text-white" size={20} />
            </Button>
          </Col>
        </Row>
        <Row className="mb-5">
          <Col
            md="12"
            className="d-flex justify-content-center align-items-center"
          >
            <div className="bg-success py-1 px-2 rounded">
              <h3 className="text-white m-0 p-0">
                {invoiceType !== "Devis non associés" ? invoiceType : "Devis"}
              </h3>
            </div>
          </Col>
        </Row>

        <Row className="d-flex justify-content-between  mb-5">
          <Col sm="12" md="4">
            <p>
              <strong>Entreprise: </strong> <span>{this.context.marchand.name_enterprise.toUpperCase()}</span>
            </p>
            <p>
              <strong>Adresse: </strong> <span>{this.context.marchand.box !== null ? this.context.marchand.street + " " + this.context.marchand.number_street + ' /' + this.context.marchand.box + ' - ' + this.context.marchand.postal_code + " " + this.context.marchand.town : this.context.marchand.street + " " + this.context.marchand.number_street + ' ' + this.context.marchand.postal_code + " " + this.context.marchand.town}</span>
            </p>
            <p>
              <strong>Ville: </strong> <span>{this.context.marchand.town}</span>
            </p>
            <p>
              <strong>Téléphone: </strong> <span>{this.context.marchand.phone_number}</span>
            </p>
            <p>
              <strong>Référence Internet:</strong>{" "}
              <span> {this.context.marchand.site_internet}</span>
            </p>
          </Col>
          <Col sm="12" md="4">
            <p>
              <strong>Référence: </strong>{" "}
              <span>{invoice.num_facturation}</span>
            </p>
            <p>
              <strong>Date: </strong>{" "}
              <span>
                {moment(invoice.created_at).format(
                  "dddd Do MMMM YYYY à HH:mm:ss"
                )}
              </span>
            </p>
            <p>
              <strong>Échéance: </strong>{" "}
              <span>
                {moment(invoice.date_echeance).format(
                  "dddd Do MMMM YYYY à HH:mm:ss"
                )}
              </span>
            </p>
            {invoice.client.code_client && (
              <p>
                <strong>N°client: </strong>
                <span>{invoice.client.code_client}</span>
              </p>
            )}

            
          </Col>
          <Col sm="12" md="4">
            {invoice.client ? (
              <React.Fragment>
                <p>
                  <strong>Client: </strong> <span>{invoice.client.name}</span>
                </p>
                <p>
                  <strong>Adresse: </strong>{" "}
                  <span>

                  {invoice.client.box !== null && invoice.client.box !== undefined && (
                      <span>
                        {invoice.client.adresse.street} {invoice.client.number_street} / {invoice.client.box}{" "}
                        {invoice.client.adresse.postal_code} {invoice.client.adresse.town}
                      </span>
                    )}
                    {(invoice.client.box === null || invoice.client.box === undefined) && (
                      <span>
                        {invoice.client.adresse.street} {invoice.client.number_street}{" "}
                        {invoice.client.adresse.postal_code} {invoice.client.adresse.town}
                      </span>
                    )}
                  </span>
                </p>
                <p>
                  <strong>Région: </strong>{" "}
                  <span>
                    {invoice.client.adresse.region + "-" + invoice.client.adresse.country}
                  </span>
                </p>
             
              
                {invoiceType !== "Devis non associés" ? (
                  <p>
                    <strong>Téléphone: </strong>{" "}
                    <span> {invoice.client.phone_number}</span>
                  </p>
                ) : null}
              </React.Fragment>
            ) : null}
          </Col>
        </Row>

        <Row className="mb-5">
          <Col md="6" className="mb-3">
            <div className="row align-items-end custom-line">
              <div className="col">Surface </div>
              <div className="col" style={{ fontWeight: "bold" }}>
                {invoice.surface}{" "}
                <var>
                  m<sup>2</sup>
                </var>
              </div>
            </div>
          </Col>
          <Col md="6" className="mb-3">
            <div className="row align-items-end custom-line">
              <div className="col">Épaisseur </div>
              <div className="col" style={{ fontWeight: "bold" }}>
                {invoice.epaisseur + " cm"}
              </div>
            </div>
          </Col>
          <Col md="6" className="mb-3">
            <div className="row align-items-end custom-line">
              <div className="col">Application </div>
              <div className="col" style={{ fontWeight: "bold" }}>
              {invoice.type_service === 'sol_rez_de_chausse' ? 'sol rez-de-chaussée': invoice.type_service}
              </div>
            </div>
          </Col>
        </Row>

        <Row className="d-flex align-items-center justify-content-start mx-5 px-5">
          <Col sm="12">
            <p>Intitulé: Description des produits à facturer</p>
          </Col>
        </Row>
        <Row>
          <Col sm="12">
            <Table bordered hover>
              <thead>
                <tr>
                  <th
                    style={{
                      color: "black",
                      fontSize: "15",
                      fontWeight: "bold",
                    }}
                  >
                    Quantité
                  </th>
                  <th
                    style={{
                      color: "black",
                      fontSize: "15",
                      fontWeight: "bold",
                    }}
                  >
                    Unité
                  </th>
                  <th
                    style={{
                      color: "black",
                      fontSize: "15",
                      fontWeight: "bold",
                    }}
                  >
                    Désignation
                  </th>
                  <th
                    style={{
                      color: "black",
                      fontSize: "15",
                      fontWeight: "bold",
                    }}
                  >
                    Prix unitaire HT
                  </th>
                  <th
                    style={{
                      color: "black",
                      fontSize: "15",
                      fontWeight: "bold",
                    }}
                  >
                    Prix total HT
                  </th>
                </tr>
              </thead>
              <tbody>
                {invoice.ligne_articles.map((article) => (
                  <tr>
                    <td>{article.quantite}</td>
                    <td>{article.article.unit}</td>
                    <td>
                    <div>
                      <em>
                        <strong>
                        { article.type_service === 'sol_rez_de_chausse' ? 'Application: ' + 'sol rez-de-chaussée': 'Application: ' + article.type_service}
                         
                          </strong>
                      </em>
                      </div>
                      <div>
                        <strong>{article.article.libelle}</strong>
                      </div>
                      <div>{article.article.description}</div>
                      <div>
                        <em>
                        
                          {article.article.conductivity
                            ? 'Conductivité: ' +  article.article.conductivity + " W/m∙K"
                            : ""}
                        </em>
                      </div>
                      <div>
                          <em>
                              {["ouate de cellulose","Mousse Polyuréthane", "Polystyrène", "Ciment"].includes(article.article.libelle) && article.thickness_service 
                                  ? 'Épaisseur: ' + article.thickness_service + " cm" 
                                  : ''}
                          </em>
                      </div>
                      <div>
                        <em>
                         
                          {article.article.thermal_resistance
                            ? 'Résistance thermique: '+ article.article.thermal_resistance + " m²∙K/W"
                            : ""}
                        </em>
                      </div>
                    </td>
                    <td>{(article.price/article.quantite).toFixed(2)}</td>
                    <td>{article.price.toFixed(2)}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Col>
        </Row>
        <Row className="mb-5">
          <Col md="6"></Col>
          <Col md="6" className="d-flex justify-content-end">
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th
                    style={{
                      color: "black",
                      fontSize: "15",
                      fontWeight: "bold",
                    }}
                  >
                    Total HT
                  </th>
                  <th
                    style={{
                      color: "black",
                      fontSize: "15",
                      fontWeight: "bold",
                    }}
                  >
                    {"TVA à " + invoice.tva + "%"}
                  </th>
                  <th
                    style={{
                      color: "black",
                      fontSize: "15",
                      fontWeight: "bold",
                    }}
                  >
                    Total TTC en EUR
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{invoice.montant_ht}</td>
                  <td>{invoice.montant_tva}</td>
                  <td>{invoice.montant_ttc}</td>
                </tr>
              </tbody>
            </Table>
          </Col>
        </Row>
        <Row className="mb-5">
          <Col className="d-flex justify-content-evenly align-items-center">
            {invoiceType === "Facture" ? (
              <NavLink
                className="btn btn-fill btn-primary"
                to={{ pathname: "/welcome/facture" }}
              >
                Retour
              </NavLink>
            ) : invoiceType === "Devis" ? (
              <NavLink
                className="btn btn-fill btn-primary"
                to={{
                  pathname: "/welcome/devis",
                }}
              >
                Retour
              </NavLink>
            ) : invoiceType === "Bon de commande" ? (
              <NavLink
                className="btn btn-fill btn-primary"
                to={{
                  pathname: "/welcome/bondecommande",
                }}
              >
                Retour
              </NavLink>
            ) : invoiceType === "Devis non associés" ? (
              <NavLink
                className="btn btn-fill btn-primary"
                to={{
                  pathname: "/welcome/find_devis",
                }}
              >
                Retour
              </NavLink>
            ) : null}
          </Col>
        </Row>
      </Container>
    );
  }
}

export default InvoiceShow;
