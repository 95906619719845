import React, { useRef } from "react";
// react plugin for creating notifications over the dashboard
import NotificationAlert from "react-notification-alert";
// react-bootstrap components
import {
  Button,
  Card,
  Row,
  Col,
  Form,
} from "react-bootstrap";


function EmailModel(props) {
    const objet = useRef(null)
    const msg = useRef(null);

  return (
      <Card>
            <Card.Header>
            <Card.Title as="h4">{props.title}</Card.Title>
            </Card.Header>
            <Card.Body>
            <Form>
                <Row>
                <Col md="12">
                    <Form.Group>
                    <label>Objet</label>
                    <Form.Control
                    
                    defaultValue={props.emailModel.subject}
                        placeholder="Objet"
                        type="text"
                        ref={objet}
                    ></Form.Control>
                    </Form.Group>
                </Col>
                </Row>
                <Row>
                <Col md="12">
                    <label>Message</label>
                    <Form.Control
                        ref={msg}
                        defaultValue={props.emailModel.body}
                        as="textarea"
                        style={{height:"100px"}}
                        rows={3} 
                        placeholder="Veuillez saisir votre message"
                    />
                </Col>
                </Row>
                <Row>
                <Col className="text-center" md="12">
                    <Button 
                        className="btn-fill btn-wd" variant="info" size="sm"
                        onClick={() => {
                            props.setShowModal(true)
                            props.setNewEmailModel({
                                type: props.type,
                                body : msg.current.value,
                                subject : objet.current.value
                            })
                        }
                    }>
                    Enregistrer
                    </Button>
                </Col>
                </Row>
            </Form>
            
            </Card.Body>
        </Card>
  );
}

export default EmailModel;