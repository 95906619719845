import React from "react";

// react-bootstrap components
import {
  Badge,
  Button,
  Card,
  Form,
  Navbar,
  Table,
  Nav,
  Container,
  Row,
  Col,
} from "react-bootstrap";
import { Label } from "reactstrap";
import Pagination from "components/utils/Pagination";
import { FaSearch } from "react-icons/fa";
import { AiOutlineDownload, AiOutlineEye } from "react-icons/ai";
import { HiOutlineEllipsisVertical } from "react-icons/hi2";
import { BiEditAlt } from "react-icons/bi";
import { FiSend } from "react-icons/fi";
import { FaTrashAlt } from "react-icons/fa";
import axios from "axios";
import DataTable from "react-data-table-component";
import { useState, useEffect, useRef,  useContext } from "react";
import { useLocation } from "react-router-dom";
import { Dots } from "react-activity";
import "react-activity/dist/library.css";
import ApiConstants from "constant";
import { NavLink } from "react-router-dom";
import { Link } from "react-router-dom";
import Error from "components/utils/Error";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { Toast } from "primereact/toast";
import "primereact/resources/themes/lara-light-cyan/theme.css";
import "primeicons/primeicons.css";
import Select from "react-select";
import { AuthContext, useAuth } from "AuthProvider";



function Article(props) {
 

  const location = useLocation();
  const [articles, setArticles] = useState([]);
  const [filterarticles, setFilterArticles] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isFound, setIsFound] = useState(false);
  const [isError, setIsError] = useState(false);
  const [error, setError] = useState("");
  const [responseTime, setResponseTime] = useState(0);
  const [applications, setApplications] = useState([]);
  const [idCible, setIdCible] = useState(null);
  const price = useRef();
  const libelle = useRef();
  const dateFrom = useRef();
  const dateTo = useRef();

  const description = useRef();
  const image = useRef(null);
  const surface = useRef();
  const type = useRef();
  const unit = useRef();
  const brand = useRef();
  const conductivity = useRef();
  const tchinckness = useRef();
  const thermal_resistance = useRef();
  const id = useRef();
  const id_Cible = useRef();
  const devise = useRef();
  const toast = useRef(null);
  const toastLoading = useRef(null);
  const context = useContext(AuthContext);
  const { user } = useAuth();


 
 
  const handleResponseFetchApplications = (response) => {
    if (response.data.status == 200) {
     
      let applications = [];
      response.data.Cibles.map((application) => {
        applications.push({
          value: application.id.toString(),
          label: application.libelle,
        });
      });
      setApplications(applications);
    }
  };

  const fetchApplications = () => {
    let url = ApiConstants.baseUrlRequest + ApiConstants.getApplications;

    axios.get(url).then(handleResponseFetchApplications).catch(handleError);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    setIsError(false)
    const searchCriteria = {
      libelle: libelle.current.value !== "" ? libelle.current.value : null,
      price: price.current.value !== "" ? price.current.value : null,
      id_Cible: idCible,
      dateto: dateTo.current.value !== "" ? dateTo.current.value : null,
      dateFrom: dateFrom.current.value !== "" ? dateFrom.current.value : null,
      username: context.user.username,
    };


    axios
      .get(ApiConstants.baseUrlRequest + ApiConstants.searchArticles, {
        params: searchCriteria,
      })
      .then(function (response) {
        if (response.status == 200) {
          setIsLoading(false);
          setIsFound(true);
          setArticles(response.data.articles);
          setResponseTime(response.responseTime / 1000);
        }
      })
      .catch(handleError);
  };

  const deleteConfirm = (currentIdArticle) => {
    confirmDialog({
      message: "Voulez vous supprimer cet article ?",
      header: "Confirmation de suppression",
      icon: "pi pi-info-circle",
      defaultFocus: "reject",
      acceptClassName: "p-button-danger",
      rejectLabel: "Non",
      acceptLabel: "Oui",
      accept: () => {
        accept(currentIdArticle);
      },
      reject,
    });
  };

  const handleDeleteArticle = (response) => {
   
    if (response.status === 200) {
      setIsLoading(false);
   
      props.history.push({
        pathname: props.location.pathname,
        state: { operation: "delete" },
      });
    } else if (response.status == 404) {
      setIsLoading(false);
      setIsFound(false);
    }
  };

  const handleError = (error) => {
    if (error.isAxiosError) {
      if ("response" in error) {
        if (error.response.status === 401) {
          setIsLoading(false);
        } else if (error.response.status === 404) {
          setIsLoading(false);
          setIsFound(false);
        } else {
          setIsLoading(false);
          setIsError(true);
          setError(error);
        }
      } else {
        setIsLoading(false);
        setIsError(true);
        setError(error);
      }
    } else {
      console.log("No Axios error");
    }
    console.log(error);
  };

  const handleConvertError = (error) => {
    setIsLoading(false);
    setIsError(true);
    setError(error);
    setIsFound(false)
    console.log(error);
  };

  const accept = (currentIdArticle) => {
    setIsLoading(true);
    let url = ApiConstants.baseUrlRequest + ApiConstants.deleteArticle;
    axios
      .delete(url, {
        data: {
          username: context.user.username,
          id_article: currentIdArticle,
        },
      })
      .then(handleDeleteArticle)
      .catch(handleConvertError);
  };

  const reject = () => {
    toast.current.show({
      severity: "warn",
      summary: "Rejeté",
      detail: "Vous avez annulé la suppression.",
      life: 3000,
    });
  };

  const buttonStyle = {
    width: '30px',
    height: '30px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  };

  
  const column = [
    {
      name: "Intitulé",
      selector: (row) => row.libelle,
      sortable: true,
    },

    {
      name: "Prix",
      selector: (row) => row.price + " €",
      sortable: true,
    },

    {
      name: "Ép/Surf",
      selector: (row) =>
        row.thickness !== ""
          ? row.thickness
          : row.surface !== ""
          ? row.surface
          : "",
      sortable: true,
    },
    {
      name: "Type",
      selector: (row) => row.type,
      sortable: true,
    },
    {
      name: "conductivité",
      selector: (row) => row.conductivity,
      sortable: true,
    },
    {
      name: "Unité",
      selector: (row) => row.unit,
      sortable: true,
    },
    {
      name: "Actions",
      cell: (row) => {
    
        return (
          <div className="d-flex align-items-center justify-content-between">
            {context.isVisible("afficher un article") === false  ? (
              <Link
                className="btn btn-sm btn-fill btn-icon btn-info m-1"
                style={buttonStyle}
                data-toggle="tooltip"
                data-placement="top"
                title="Détail"
                data-original-title="Détails"
                to={{
                  pathname: "/welcome/article/show",
                  state: { article: row },
                }}
              >
                <AiOutlineEye className="text-white" size={20} />
              </Link>
          
            ) : null}
          
            {context.isVisible("modifier un article") === false  ? (
               
              <Link
                className="btn btn-sm btn-fill btn-icon btn-dark m-1"
                style={buttonStyle}
                data-toggle="tooltip"
                data-placement="top"
                title="Modifier"
                data-original-title="Modifier"
                to={{
                  pathname: "/welcome/article/create",
                  state: { article: row },
                }}
              >
                <BiEditAlt className="text-white" size={20} />
              </Link>
        
            ) : null}
        
            {context.isVisible("supprimer un article") === false  ?(
              <Button
                className="btn btn-sm btn-fill btn-icon btn-danger m-1"
                style={buttonStyle}
                title="Supprimer"
                onClick={() => {
                  deleteConfirm(row.id);
                }}
              >
                <FaTrashAlt className="text-white" size={20} />
              </Button>
            ) : null}
            
          </div>
        );
      },
    }
  ];
  useEffect(() => {
    const fetData = async () => {
      axios.interceptors.request.use((x) => {
        x.meta = x.meta || {};
        x.meta.requestStartedAt = new Date().getTime();
        return x;
      });
      axios.interceptors.response.use((x) => {
        x.responseTime = new Date().getTime() - x.config.meta.requestStartedAt;
        return x;
      });
      axios
        .get(ApiConstants.baseUrlRequest + ApiConstants.getArticlesEndpoint)
        .then(function (response) {
          if (response.status == 200) {
            setIsLoading(false);
            setIsFound(true);
            setArticles(response.data.articles);
            setResponseTime(response.responseTime / 1000);
            if (props.location.state) {
              if (props.location.state.operation === "delete") {
                toast.current.show({
                  severity: "success",
                  summary: "Confirmé",
                  detail: "Article supprimé.",
                  life: 3000,
                });
              }
            }
       
            const nameToFind = "Modifier un devis";
            const element = auth.user.profil_visibilities.find(obj => obj.name === nameToFind);
            
            if (element) {
              const visibility = element.visibility;
             
            } else {
            
            }
          } 
        })
        .catch(function (error) {
          setIsError(true);
          setError(error);
        });
    };
    fetData();
    fetchApplications();


  }, [location]);

  const handleFilter = (event) => {
    const newData = filterarticles.filter((row) =>
      row.libelle.toLowerCase().includes(event.target.value.toLowerCase())
    );
    setArticles(newData);
  };

  if (isLoading === false && isFound === true) {
    return (
      <Container fluid>
        <Toast ref={toast} />
        <ConfirmDialog />

        <Row>
          <Col>
            <Container fluid>
              <Row>
                <Col>
                  <h1 className="h1 mt-0">{"Rechercher un article"}</h1>
                </Col>
              </Row>
              <Row>
                <Col sm="12" className="d-flex justify-content-center">
                  <Card style={{ width: "100%" }}>
                    <Card.Title className="bg-secondary text-white fw-bold fs-5">
                      {" "}
                      <div className="m-3">
                        {" "}
                        <FaSearch className="me-3" /> Critères de recherche{" "}
                      </div>{" "}
                    </Card.Title>
                    <Card.Body className="p-5">
                      <Form onSubmit={handleSubmit}>
                        <Row>
                          <Col sm="6" className="px-1">
                            <Form.Group
                            
                              className=" d-flex  align-items-center justify-content-between mb-3"
                            >
                              <Form.Label className="me-3 text-end searchlabel">
                                Application
                              </Form.Label>
                              <Select
                              className="w-100"
                                data-trigger
                                name="choices-single-default"
                                id="application"
                                options={applications}
                                placeholder="choisir application"
                                onChange={(option) => {
                                  setIdCible(option.value);
                                }}
                              />
                            </Form.Group>
                          </Col>

                          <Col className="px-1" sm="6">
                            <Form.Group
                              controlId="libelle"
                              className="d-flex  align-items-center justify-content-between mb-3"
                            >
                              <Form.Label className="me-3 text-end searchlabel">
                                Intitulé
                              </Form.Label>
                              <Form.Control
                                placeholder={"Intitulé"}
                                type="text"
                                ref={libelle}
                              />
                            </Form.Group>
                          </Col>

                          <Col className="px-1" sm="6">
                            <Form.Group
                              controlId="price"
                              className="d-flex  align-items-center justify-content-between mb-3"
                            >
                              <Form.Label className="me-3 text-end searchlabel">
                                Prix
                              </Form.Label>
                              <Form.Control
                                placeholder="prix"
                                step={"0.0001"}
                                type="number"
                                ref={price}
                              />
                            </Form.Group>
                          </Col>

                          <Col className="px-1" sm="6">
                            <Form.Group
                              controlId="datetfrom"
                              className="d-flex  align-items-center justify-content-between mb-3"
                            >
                              <Form.Label className="me-3 text-end searchlabel">
                                Date de début
                              </Form.Label>
                              <Form.Control
                                placeholder="Date de début"
                                type="date"
                                ref={dateFrom}
                              />
                            </Form.Group>
                          </Col>
                          <Col className="px-1" sm="6">
                            <Form.Group
                              controlId="dateto"
                              className="d-flex  align-items-center justify-content-between mb-3"
                            >
                              <Form.Label className="me-3 text-end searchlabel">
                                Date de fin
                              </Form.Label>
                              <Form.Control
                                placeholder="Date de fin"
                                type="date"
                                ref={dateTo}
                              />
                            </Form.Group>
                          </Col>
                        </Row>

                        <div className="d-flex align-items-center justify-content-center py-3">
                          <Button
                            className="btn-fill pull-right"
                            type="submit"
                            variant="info"
                          >
                            Rechercher
                          </Button>
                        </div>
                        <div className="clearfix"></div>
                      </Form>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
              <Row>
                <Col sm="12"></Col>
              </Row>
            </Container>
          </Col>
        </Row>

        <Row>
          {}
          <div className="d-flex align-items-center justify-content-end py-3">
          {context.isVisible("Créer un article") === true ? (
            <Link
              to={{
                pathname: "/welcome/article/create",
                state: { article: {} },
              }}
            >
              <Button
                className="btn-fill pull-right m-4"
                type="submit"
                variant="success"                               
              >
                Nouveau article
              </Button>
            </Link>
            ) : null}
          </div>
          <div className="clearfix"></div>
        </Row>

        <Row>
          <Col sm="12" className="d-flex justify-content-between">
            <h1 className="h4">Résultats de recherche</h1>
            <h1 className="h4">
              {articles.length +
                " Résultats trouvé(s) en " +
                responseTime.toString() +
                " S"}{" "}
            </h1>
          </Col>
        </Row>
        {}
        <br />
        <Row>
          <Col md="12">
        
              <div style={{ height: 400, width: '100%' }}>
              <DataTable
              columns={column}
              data={articles}
              pagination
             
              responsive
              dense
              subHeader // Ajouter la propriété subHeader pour afficher la barre de recherche
              subHeaderComponent={
                <Col xl="3" lg="3" md="3">
                <input
                type="text"
                value={filterarticles.articles} // Utilisez la variable searchTerm pour la valeur du champ
                onChange={handleFilter} // Utilisez la fonction handleFilter pour l'événement onChange
                placeholder="Rechercher..."
                className="form-control"           
              /> </Col>// Ajouter un élément <input> pour le champ de recherche
              }
           
            ></DataTable>
              </div>
          
          </Col>
        </Row>

        {}
      </Container>
    );
  }
  if (isLoading === false && isFound === false) {
    return <Error code={404} />;
  }
  if (isError === true) {
    if (error.message === "Network Error") {
      return <Error code={-1} />;

    }
    return <Error code={-2} />;
  }
  return (
    <Container fluid>
      <div className="d-flex justify-content-center align-items-center">
        <Dots color="#727981" size={32} speed={1} animating={isLoading} />
      </div>
    </Container>
  );
}

export default Article;
