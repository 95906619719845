import React from "react";

class FooterLink extends React.Component{
    constructor(props){
        super(props);
    }

    render(){
        return(
            <div className="col-6 py-3 d-flex justify-content-start">
                <a href={this.props.href} target="_blank" className="footerLink" style={{ backgroundImage: `url(${this.props.bgImgSrc})` }}></a>
            </div>
            
        );
    }
}

export default FooterLink;