import React from "react";
import youtubeSvg from "assets/img/youtube_white.svg";
import facebookSvg from "assets/img/fb_white.svg";
import instagramSvg from "assets/img/instagram_white.svg";
import gmapSvg from "assets/img/gmap_white.svg";
import FooterLink from "components/utils/FooterLink";
import { useLocation } from "react-router-dom";

const Footer = () => {
  const location = useLocation()
  if(location.pathname === '/home/createpart'){
    return <></>
  }
  return (
    <div
      className="container-fluid pt-3 pb-5 mt-3 mb-0"
      style={{
        background: "rgba(0,0,0,0.8)",
        borderTop: "5px solid #8EA841",
        position: "relative",
        color: "#cccccc",
      }}
    >
      <section className="row d-flex justify-content-evenly align-items-start">
        <article className=" col-12 col-md-4 d-flex align-items-start justify-content-start">
          <figure>
            <img
              id="headerLogo"
              src={require("assets/img/Logo_full.png")}
              alt="..."
            />
          </figure>
        </article>
        <article className="col-6 col-sm-3 col-md-2 d-flex align-items-start justify-content-start">
          <div>
            <ul className="p-0 listContentFooter">
              <li
                className="py-2 text-primary"
                style={{ fontsize: "26px", color: "#6eb825" }}
              >
                <h1 className="h4 footerH1Color">NOS SERVICES</h1>
              </li>
              <li className="py-2 square border-bottom">
                <a
                  href="#"
                  style={{ color: "#cccccc", textDecorationLine: "none" }}
                >
                  Devis en ligne
                </a>
              </li>
            </ul>
          </div>
        </article>
        <article className="col-6 col-sm-3 col-md-2 d-flex align-items-start justify-content-start">
          <div>
            <ul className="p-0 listContentFooter">
              <li className="text-primary py-2">
                <h1 className="h4 footerH1Color">HEAD OFFICE</h1>
              </li>
              <li className="py-2 ">Rue de la Croisette 150B</li>
              <li className="py-2 ">7110 Strépy-Bracquegnies</li>
              <li className="py-2 ">Belgique</li>
            </ul>
          </div>
        </article>
        <article className="col-6 col-sm-3 col-md-2  d-flex align-items-start justify-content-start">
          <div>
            <ul className="p-0 listContentFooter">
              <li className="text-primary py-2">
                <h1 className="h4 footerH1Color ">CONTACT</h1>
              </li>
              <li className="py-2 ">Isocentre</li>
              <li className="py-2 ">+32 498 698 232</li>
              <li className="py-2 square border-bottom ">
                <a
                  href="mailto:info@isocentre.com"
                  style={{ color: "#cccccc", textDecorationLine: "none" }}
                >
                  info@isocentre.com
                </a>
              </li>
              <li className="pt-5 ">TVA : BE0780765866</li>
              <li>IBAN: BE06 1262 0997 1122</li>
            </ul>
          </div>
        </article>
        <article className="col-6 col-sm-3 col-md-2 d-flex align-items-start justify-content-start">
          <div>
            <ul className="p-0 listContentFooter">
              <li className="text-primary py-2">
                <h1 className="h4 footerH1Color">LIENS UTILES</h1>
              </li>
              <li className="row d-flex align-items-center">
                <FooterLink
                  href={"https://www.facebook.com/monisolationbe"}
                  bgImgSrc={facebookSvg}
                />
                <FooterLink
                  href={"javascript:;"}
                  bgImgSrc={youtubeSvg}
                />
                <FooterLink href={"https://www.instagram.com/monisolation.be?igsh=MWZvcGxlNTF6N3FzNg=="} bgImgSrc={instagramSvg} />
                <FooterLink
                  href={
                    "javascript:;"
                  }
                  bgImgSrc={gmapSvg}
                />
              </li>
            </ul>
          </div>
        </article>
      </section>
      <section>
        <p className="text-center mt-3">Copyright ©2024 All Rights Reserved <a className="text-secondary" target="_blank" href="https://worldvoicegroup.com/"> WORLD VOICE GROUP</a> </p>
      </section>
    </div>
  );
};

export default Footer;
