/*!

=========================================================
* Light Bootstrap Dashboard React - v2.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/light-bootstrap-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Dashboard from "views/Dashboard.js";
import UserProfile from "views/UserProfile.js";
import UserClients from "views/UserClients.js";
import UserSousTraitant from "views/UserSousTraitant.js";
import UserProfileClient from "views/UserProfileClient.js";
import Article from "views/Article";
import ExportData from "views/ExportData.js";
import EmailModelList from "views/EmailModelList.js";
import { FaSignOutAlt, FaUserCircle, FaAddressBook , FaGear} from "react-icons/fa";
import { IoDocumentTextOutline } from "react-icons/io5";
import {
  AiOutlineMail,
  AiOutlineDownload,
  AiOutlineDashboard,
  AiOutlineFile,
} from "react-icons/ai";
import { MdOutlineArticle, MdSettings } from "react-icons/md";
import Home from "layouts/Home";
import InvoiceList from "views/InvoiceList";
import AccountCreate from "views/AccountCreate";
import Adresse from "views/Adresse";
import Configuration from "views/Configuration";

const welcomeRoutes = [
  {
    path: "/dashboard",
    name: "Tableau de bord",
    icon: AiOutlineDashboard,
    component: Dashboard,
    layout: "/welcome",
    userType: ["marchand", "professionnel"],
  },
  {
    path: "/facture",
    name: "Facture",
    icon: IoDocumentTextOutline,
    component: InvoiceList,
    layout: "/welcome",
    userType: ["professionnel","marchand"],
  },
  {
    path: "/devis",
    name: "Devis",
    icon: IoDocumentTextOutline,
    component: InvoiceList,
    layout: "/welcome",
    userType: ["professionnel", "marchand", "partenaire", 'particulier'],
  },
  {
    path: "/find_devis",
    name: "Devis non associés",
    icon: IoDocumentTextOutline,
    component: InvoiceList,
    layout: "/welcome",
    userType: ["professionnel", "marchand"],
  },
  {
    path: "/bondecommande",
    name: "Bon de commande",
    icon: IoDocumentTextOutline,
    component: InvoiceList,
    layout: "/welcome",
    userType: ["professionnel","marchand"],
  },
  {
    path: "/article",
    name: "Article",
    icon: MdOutlineArticle,
    component: Article,
    layout: "/welcome",
    userType: ["marchand"]
  },
  {
    path: "/UserProfile",
    name: "Profil utilisateur",
    icon: FaUserCircle,
    component: UserProfileClient,
    layout: "/welcome",
    userType: ["professionnel", "partenaire"],
  },
  {
    path: "/user",
    name: "Partenaires",
    icon: FaUserCircle,
    component: UserProfile,
    layout: "/welcome",
    userType: ["marchand"],
  },
  {
    path: "/client",
    name: "Clients",
    icon: FaUserCircle,
    component: UserClients,
    layout: "/welcome",
    userType: ["marchand"],
  },
  {
    path: "/soustraitant",
    name: "Sous-traitant",
    icon: FaUserCircle,
    component: UserSousTraitant,
    layout: "/welcome",
    userType: ["marchand"],
  },
  {
    path: "/adresseavalider",
    name: "Adresse à valider",
    icon: FaAddressBook,
    component: Adresse,
    layout: "/welcome",
    userType: ["marchand"],
  },
  {
    path: "/emailmodel",
    name: "Modèle email",
    icon: AiOutlineMail,
    component: EmailModelList,
    layout: "/welcome",
    userType: ["marchand", "professionnel"],
  },
  {
    path: "/exportdata",
    name: "Exporter données",
    icon: AiOutlineDownload,
    component: ExportData,
    layout: "/welcome",
    userType: ["marchand", "professionnel"],
  },
  {
    path: "/createPartner",
    name: "créer un partenaire",
    icon: IoDocumentTextOutline,
    component: AccountCreate,
    layout: "/welcome",
    userType: [""],
  },
  {
    path: "/customer",
    name: "créer un client",
    icon: IoDocumentTextOutline,
    component: AccountCreate,
    layout: "/welcome",
    userType: [""],
  },
  {
    path: "/subcontractor",
    name: "créer un sous traitant",
    icon: IoDocumentTextOutline,
    component: AccountCreate,
    layout: "/welcome",
    userType: [""],
  },

  {
    path: "/configuration",
    name: "Configuration",
    icon: MdSettings,
    component: Configuration,
    layout: "/welcome",
    userType: ["marchand"],
  },
  
  {
    disconnect: true,
    path: "/home",
    name: "Déconnexion",
    icon: FaSignOutAlt,
    component: Home,
    layout: "/welcome",
    userType: ["marchand", "professionnel", "partenaire", 'particulier'],
  },
];

export default welcomeRoutes;
