import React from "react";

import { NavLink, useHistory } from "react-router-dom";
import { Navbar, Container, Nav, Dropdown, Button } from "react-bootstrap";
import avatar from "../../assets/img/faces/face-0.jpg";
import "../../assets/css/header.css";
import { useAuth } from "AuthProvider";
import { IoIosArrowForward } from "react-icons/io";
import { FaPowerOff } from "react-icons/fa";

class Header extends React.Component {
  constructor(props) {
    super(props);
  }

  handleConnectClick(e) {
    e.preventDefault();
    //this.props.changeContentLoad(1);
  }
  handleInscriptionClick(e) {
    e.preventDefault();
    //this.props.changeContentLoad(2);
  }
  handleHomeClick(e) {
    e.preventDefault();
  }

  handleRole(code_client) {
    if (code_client === "M") {
      return "Administrateur";
    } else if (code_client.split("_")[0] === "PARTI") {
      return "Particulier";
    } else if (code_client.split("_")[0] === "PR") {
      return "Professionnel";
    } else if (code_client.split("_")[0] === "PAR") {
      return "Partenaire";
    }
  }

  handleWelcomeClicked = (e) => {
    e.preventDefault(); // empecher le mécanisme normal de soumission du formulaire
    // const history = useHistory();
    // Programmatically navigate to a different route
    this.props.history.push("/welcome/dashboard");
  };

  handleLogout = (e) => {
    e.preventDefault(); // empecher le mécanisme normal de soumission du formulaire
    this.props.auth.logOut();
    this.props.history.push("/home");
  };

  render() {
    const isConnected = this.props.isConnected;
    const user = this.props.user;
    const count = this.props.notificationsNumber;
    // const history = useHistory()

    return (
      <header
        id="headerNav"
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          right: 0,
          width: "100%",
          borderBottom: "5px solid #8EA841",
          zIndex: "1000",
        }}
      >
        <Navbar
          expand="lg"
          className=" p-0 "
          style={{ background: "rgba(0,0,0,0.8)" }}
        >
          <Container fluid>
            <Navbar.Brand
              onClick={(e) => e.preventDefault()}
              className="m-0 p-0"
            >
              <figure className="m-0 p-0">
                <img
                  id="headerLogo"
                  src={require("assets/img/Logo_full.png")}
                  alt="Isocentre logo"
                />
              </figure>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" className="mr-2">
              <span className="navbar-toggler-bar burger-lines"></span>
              <span className="navbar-toggler-bar burger-lines"></span>
              <span className="navbar-toggler-bar burger-lines"></span>
            </Navbar.Toggle>
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="ms-auto d-flex align-items-lg-center justify-content-lg-center">
                <Nav.Item>
                  {isConnected && count > 0 ? (
                    <a
                      href="/welcome/adresseavalider"
                      class="dropdown-toggle"
                      data-toggle="dropdown"
                      role="button"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <i className="fas fa-bell"></i>
                      <span class="badgeAlert">{count}</span>
                      {/* <span class="caret"></span> */}
                    </a>
                  ) : null}
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    className="m-0"
                    href="#pablo"
                    onClick={(e) => this.handleHomeClick(e)}
                  >
                    <NavLink to="/home" className="nav-link">
                      <span className="no-icon">Accueil</span>
                    </NavLink>
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <a
                    href="https://www.iso-centre.be/"
                    className="nav-link"
                    target="_blank"
                  >
                    <span className="no-icon">Notre entreprise</span>
                  </a>
                </Nav.Item>
                {isConnected === true ? (
                  <Dropdown as="li" className="nav-item">
                    <Dropdown.Toggle
                      variant=" nav-link py-0 d-flex align-items-center"
                      href="#"
                      id="navbarDropdown"
                    >
                      <img
                        src={avatar}
                        height={50}
                        width={50}
                        alt="User-Profile"
                        className="theme-color-default-img img-fluid avatar avatar-50 avatar-rounded"
                      />
                      <div className="caption ms-3 ">
                        <h6 className="mb-0 caption-title">{user.name}</h6>
                        <p className="mb-0 caption-sub-title">
                          {this.handleRole(user.code_client)}
                        </p>
                      </div>
                    </Dropdown.Toggle>
                    <Dropdown.Menu as="ul" className="dropdown-menu-end">
                      <Dropdown.Item  style={{color:'#506f3f'}} >{user.name}</Dropdown.Item>
                      <Dropdown.Item
                      className="d-flex justify-content-between align-items-center"
                        onClick={(e) => this.handleWelcomeClicked(e)}
                        style={{color:'#506f3f'}}
                      >
                        <span>Opérations</span> <IoIosArrowForward className="ms-1"/>
                      </Dropdown.Item>
                      <Dropdown.Divider style={{backgroundColor:'#506f3f'}}/>
                      <Dropdown.Item className="d-flex justify-content-between align-items-center" style={{color:'#506f3f'}} onClick={(e) => this.handleLogout(e)}>
                      <span>Deconnexion</span> <FaPowerOff className="ms-1" />
                        
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                ) : (
                  <>
                    <Nav.Item>
                      <Nav.Link
                        className="m-0"
                        href="#pablo"
                        onClick={(e) => this.handleConnectClick(e)}
                      >
                        <NavLink
                          to="/home/connection"
                          className="btn btn-fill btn-sm"
                        >
                          Se connecter
                        </NavLink>
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link
                        className="m-0"
                        href="#pablo"
                        onClick={(e) => this.handleInscriptionClick(e)}
                      >
                        <NavLink
                          to="/home/create_account"
                          className="btn btn-fill btn-sm"
                        >
                          S'inscrire
                        </NavLink>
                      </Nav.Link>
                    </Nav.Item>
                  </>
                )}
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </header>
    );
  }
}

export default Header;
