import React from "react";
// react plugin for creating notifications over the dashboard
import NotificationAlert from "react-notification-alert";
import {
  Alert,
  Badge,
  Button,
  Card,
  Modal,
  Navbar,
  Nav,
  Container,
  Row,
  Col,
  Form,
} from "react-bootstrap";
import { useLocation, useHistory } from "react-router-dom";
import EmailModel from "components/utils/EmailModel";
import { useState, useEffect } from "react";
import { Dots } from "react-activity";
import "react-activity/dist/library.css";
import Error from "components/utils/Error";
import ApiConstants from "constant";
import axios from "axios";

function EmailModelList(props) {
  const location = useLocation()
  const history = useHistory()
  const [emailModel, setEmailModel] = useState({})
  const [isLoading, setIsLoading] = useState(true)
  const [isFound, setIsFound] = useState(false)
  const [isError, setIsError] = useState(false)
  const [error, setError] = useState('')
  const [newEmailModel, setNewEmailModel] = useState({})
  const [showModal, setShowModal] = React.useState(false);
  const notificationAlertRef = React.useRef(null);
  const notify = (place) => {
    var color = Math.floor(Math.random() * 5 + 1);
    var type;
    switch (color) {
      case 1:
        type = "primary";
        break;
      case 2:
        type = "success";
        break;
      case 3:
        type = "danger";
        break;
      case 4:
        type = "warning";
        break;
      case 5:
        type = "info";
        break;
      default:
        break;
    }
    var options = {};
    options = {
      place: place,
      message: (
        <div>
          <div>
            Welcome to <b>Light Bootstrap Dashboard React</b> - a beautiful
            freebie for every web developer.
          </div>
        </div>
      ),
      type: type,
      icon: "nc-icon nc-bell-55",
      autoDismiss: 7,
    };
    notificationAlertRef.current.notificationAlert(options);
  };

  useEffect(() => {
    let url = ApiConstants.baseUrlRequest + ApiConstants.getEmailsModels
    axios.get(url)
          .then(function (response) {
            if(response.status == 200){
              setIsLoading(false)
              setIsFound(true)
              setEmailModel(response.data.emails)
            }else if(response.status == 404){
                setIsLoading(false)
                setIsFound(false)
            }  
          })
          .catch(function (error) {
            setIsError(true)
            setError(error)
          });  
  }, [location])

  if(isLoading === false && isFound === true){
    return (
      <>
        <div className="rna-container">
          <NotificationAlert ref={notificationAlertRef} />
        </div>
        <Container fluid>
          <Card>
            <Card.Header>
              <Card.Title as="h4">Modèle Email</Card.Title>
            </Card.Header>
            <Card.Body>
              {
                Object.keys(emailModel).map((type) => {
                let title = ""
                switch(type){
                  case "devis" :
                    title = "Devis"
                    break
                  case "avoir" :
                    title = "Avoir"
                    break
                  case "facture" :
                    title = "Facture"
                    break
                  case "bon_commande" :
                  title = "Bon commande"
                  break
                }
                return <EmailModel  setNewEmailModel={setNewEmailModel} type={type} title={title} emailModel={emailModel[type]} setShowModal={setShowModal} {...props}/>
              })
              }
              
            </Card.Body>
          </Card>
          {/* Mini Modal */}
          <Modal className="modal-mini modal-primary" show={showModal} onHide={() => setShowModal(false)}>
            <Modal.Header className="justify-content-center">
              <div className="modal-profile">
                <i className="nc-icon nc-bulb-63"></i>
              </div>
            </Modal.Header>
            <Modal.Body className="text-center">
              <p>Voulez-vous enregistrer ce modèle?</p>
            </Modal.Body>
              <div className="modal-footer">
                <Button 
                  className="btn-simple"
                  type="button"
                  variant="link"
                  onClick={
                    () =>{
                      setShowModal(false)
                    }
                  }>
                  Non
                </Button>
                <Button 
                  className="btn-simple" 
                  type="button" 
                  variant="link" 
                  onClick={(e) => {
                    setShowModal(false)

                    setIsLoading(true)
                    let url = ApiConstants.baseUrlRequest +  ApiConstants.setEmailsModels + '/' + newEmailModel.type
                    axios.put(url, newEmailModel)
                                    .then(function(response){
                                      if(response.status === 200){
                                          history.push({
                                              pathname: location.pathname,
                                          });
                                          }else if(response.status == 404){
                                            setIsLoading(false)
                                            setIsFound(false)
                                          }  
                                    })
                                    .catch(function(error){
                                      setIsError(true)
                                      setError(error)
                                    });  
                  }}>
                  Oui
                </Button>
              </div>
          </Modal>
          {/* End Modal */}
        </Container>
      </>
    );
  }

  if(isLoading === false && isFound === false){
    return (<Error code={404}/>)
   }
   
   if(isError === true){
     if(error.message === "Network Error"){
         return (<Error code={-1}/>)
     }
      return (<Error code={-2}/>)
    }
   return (
     <Container fluid>
       <div className="d-flex justify-content-center align-items-center">
         <Dots color="#727981" size={32} speed={1} animating={isLoading} />
       </div>
     </Container>
   )

 
}

export default EmailModelList;