import { AiOutlineDownload, AiOutlineEye } from "react-icons/ai";
import { HiOutlineEllipsisVertical } from "react-icons/hi2";
import { BiEditAlt } from "react-icons/bi";
import { FiSend } from "react-icons/fi";
import { BsCartCheckFill, BsFillTrashFill } from "react-icons/bs";
import { LiaFileInvoiceSolid } from "react-icons/lia";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Button, Dropdown, Modal, Form, Row, Col } from "react-bootstrap";
import moment from "moment";
import "moment/locale/fr";
moment.locale("fr");
import routes from "router/welcome-routes.js";
import { AuthContext } from "AuthProvider";
import { useAuth } from "AuthProvider";
import axios from "axios";
import ApiConstants from "constant";
import { withRouter } from "react-router-dom";
import generatePDF from "documents";
import InvoiceList from "views/InvoiceList";
import CustomToggle from "components/utils/dropdowns";

class InvoiceLineTable extends React.Component {
  constructor(props) {
    super(props);
    this.handleConvertToFacture = this.handleConvertToFacture.bind(this);
    this.handleConvertError = this.handleConvertError.bind(this);
    this.handleConvertToBonCommande =
      this.handleConvertToBonCommande.bind(this);
    this.handleDeleteInvoice = this.handleDeleteInvoice.bind(this);
    this.handleAllocationInvoice = this.handleAllocationInvoice.bind(this);
    this.handleChangeStateInvoice = this.handleChangeStateInvoice.bind(this);
    this.handleModifierClick = this.handleModifierClick.bind(this);
    this.handleChangeStatusInvoiceClick = this.handleChangeStatusInvoiceClick.bind(this);
    this.handleSendEmailClick = this.handleSendEmailClick.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this); 
    this.getInvoiceType = this.getInvoiceType.bind(this);
    this.handleSendEmail = this.handleSendEmail.bind(this);
    this.handleRefuseStatusInvoiceClick = this.handleRefuseStatusInvoiceClick.bind(this);
  

    this.state = {
      showDropdown: false,
      showModal: false,
      showModal2: false,
      dateErrorMessage: "",
      date_realisation_debut : ""
    };

    this.handleDropdownToggle = this.handleDropdownToggle.bind(this);
    this.handleDropdownClose = this.handleDropdownClose.bind(this);
  }

  static contextType = AuthContext;


  handleModifierClick() {
    const { invoice, history } = this.props;
    history.push({
      pathname: "/welcome/devis/modif",
      state: { invoice: invoice },
    });
  }

  handleDropdownToggle() {
    this.setState((prevState) => ({
      showDropdown: !prevState.showDropdown,
    }));
  }
  handleDropdownClose = () => {
    this.setState({
      showDropdown: false,
    });
  };

  handleAllocateClick = () => {
    setShowPopup(true);
  };

  handleConvertToFacture(response) {
     (response);
     if (response.status === 200) {
      this.props.setIsLoading(false);
      
      this.props.history.push({
        pathname: "/welcome/facture/show",
        state: {
          invoice: response.data.facturations[0],
          invoiceType: "Facture",
        },
      });
    } else if (response.status == 404) {
      this.props.setIsLoading(false);
      this.props.setIsFound(false);
    }
  }
  handleConvertToBonCommande(response) {
    
    if (response.status === 200) {
      this.props.setIsLoading(false);
      this.props.history.push({
        pathname: "/welcome/bondecommande/show",
        state: {
          invoice: response.data.facturations[0],
          invoiceType: "Bon de commande",
        },
      });
    } else if (response.status == 404) {
      this.props.setIsLoading(false);
      this.props.setIsFound(false);
    }
  }

  handleChangeStateInvoice(response) {
    if (response.status === 200) {
      this.props.setIsLoading(false);
      this.props.history.push({
        pathname: this.props.location.pathname,
      });
    } else if (response.status == 404) {
      this.props.setIsLoading(false);
      this.props.setIsFound(false);
    }
  }

  handleSendEmail(response) {
    if (response.status === 200) {
      this.props.setIsLoading(false);
      this.props.history.push({
        pathname: this.props.location.pathname,
      });
    } else if (response.status == 404) {
      this.props.setIsLoading(false);
      this.props.setIsFound(false);
    }
  }

  handleAllocationInvoice(response) {
    if (response.status === 200) {
      this.props.setIsLoading(false);
      this.props.history.push({
        pathname: this.props.location.pathname,
      });
    } else if (response.status == 404) {
      this.props.setIsLoading(false);
      this.props.setIsFound(false);
    }
  }
  handleDeleteInvoice(response) {
    if (response.status === 200) {
      this.props.setIsLoading(false);
      this.props.history.push({
        pathname: this.props.location.pathname,
      });
    } else if (response.status == 404) {
      this.props.setIsLoading(false);
      this.props.setIsFound(false);
    }
  }
  handleConvertError(error) {
    this.props.setIsLoading(false);
    this.props.setIsError(true);
    this.props.setError(error);
    this.props.setIsFound(false);
    console.log(error);
  }

  handleChangeStatusInvoiceClick(e) {
    e.preventDefault();
    this.props.setIsLoading(true);
    let url = ApiConstants.baseUrlRequest + ApiConstants.setStatusInvoice;
    axios
      .put(url, {
        username: this.context.user.username,
        num_facturation: this.props.invoice.num_facturation,
      })
      .then(this.handleChangeStateInvoice)
      .catch(this.handleConvertError);
  }

  handleRefuseStatusInvoiceClick(e) {
    e.preventDefault();
    this.props.setIsLoading(true);
    let url = ApiConstants.baseUrlRequest + ApiConstants.updateDevis;

    axios
      .put(url, {
        num_facturation: this.props.invoice.num_facturation,
        status: "refuse",
        date_realisation_debut: null,
      })
      .then(this.handleChangeStateInvoice)
      .catch(this.handleConvertError);
  }

  handleDateChange(e){
    this.setState({ date_realisation_debut: e.target.value })

  }

  getMinDate(){
    const today = new Date();
    today.setDate(today.getDate() + 10);
    const minDate = today.toISOString().split("T")[0];
    return minDate;
  }

  handleSendEmailClick(e) {
    e.preventDefault();
    this.props.setIsLoading(true);
    let url = ApiConstants.baseUrlRequest + ApiConstants.setPdfToSend;
    let pdf = generatePDF(
      this.props.invoice,
      this.getInvoiceType(),
      this.context.marchand,
      "base64"
    );
    let pdfBase64 = pdf.split("base64,")[1];
    axios
      .post(url, {
        num_facturation: this.props.invoice.num_facturation,
        encodedFile: pdfBase64,
      })
      .then(this.handleSendEmail)
      .catch(this.handleConvertError);
  }

  getInvoiceType() {
    for (let i = 0; i < routes.length; i++) {
      if (
        this.props.location.pathname.indexOf(
          routes[i].layout + routes[i].path
        ) !== -1
      ) {
        return routes[i].name;
      }
    }
    return "Brand";
  }
  
  render() {
    const invoiceType = this.getInvoiceType();
    const auth = this.context;
    const { showDropdown } = this.state;
   

    const buttonStyle = {
      width: '30px',
      height: '30px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    };
    

    return (
      <tr>
        <td>
          <div className="d-flex align-items-center justify-content-start">
            {
              ((invoiceType === 'Devis' || invoiceType === 'Devis non associés') && auth.isVisible('Afficher un devis')) ||
              (invoiceType === 'Facture' && auth.isVisible('Afficher une facture')) ||
              (invoiceType === 'Bon de commande' && auth.isVisible('Afficher bon de commande')) ? (
            <Link
              className="btn btn-sm btn-fill btn-icon btn-info m-1"
              style={buttonStyle}
              data-toggle="tooltip"
              data-placement="top"
              title="Détail"
              data-original-title="Détails"
              to={ 
                invoiceType === "Facture"
                  ? {
                      pathname: "/welcome/facture/show",
                      state: {
                        invoice: this.props.invoice,
                        invoiceType: invoiceType,
                      },
                    }
                  : invoiceType === "Devis"
                  ? {
                      pathname: "/welcome/devis/show",
                      state: {
                        invoice: this.props.invoice,
                        invoiceType: invoiceType,
                      },
                    }
                  : invoiceType === "Bon de commande"
                  ? {
                      pathname: "/welcome/bondecommande/show",
                      state: {
                        invoice: this.props.invoice,
                        invoiceType: invoiceType,
                      },
                    }
                  : invoiceType === "Devis non associés"
                  ? {
                      pathname: "/welcome/find_devis/show",
                      state: {
                        invoice: this.props.invoice,
                        invoiceType: invoiceType,
                      },
                    }
                  : null
              }
            >
              <AiOutlineEye className="text-white" size={20} />
            </Link>
             ) : null
            }
            {auth.isVisible("Convertir une facturation") === true ? (
              (invoiceType === "Devis" &&
                this.props.invoice.status === "validé") ||
              (invoiceType === "Facture" &&
                this.props.invoice.status === "payée") ? (
                <Button
                  className="btn-icon btn-fill m-1"
                  style={buttonStyle}
                  variant="dark"
                  title="Bon de commande"
                  onClick={(e) => {
                    e.preventDefault();
                    this.props.setIsLoading(true);
                    let url =
                      ApiConstants.baseUrlRequest + ApiConstants.convertInvoice;
                    axios
                      .post(url, {
                        type: "bon_commande",
                        num_facturation: this.props.invoice.num_facturation,
                        username: this.context.user.username,
                      })
                      .then(this.handleConvertToBonCommande)
                      .catch(this.handleConvertError);
                  }}
                >
                  <BsCartCheckFill className="text-white" size={20} />
                </Button>
              ) : null
            ) : null}

            {auth.isVisible("Convertir une facturation") === true ? (
              (invoiceType === "Bon de commande" &&
                this.props.invoice.status === "terminé") ||
              (invoiceType === "Devis" &&
                this.props.invoice.status === "validé") ? (
                <Button
                  className="btn-icon btn-fill m-1"
                  style={buttonStyle}
                  variant="danger"
                  title="Facture"
                  onClick={(e) => {
                    e.preventDefault();
                    this.props.setIsLoading(true);
                    let url =
                      ApiConstants.baseUrlRequest + ApiConstants.convertInvoice;
                    axios
                      .post(url, {
                        type: "facture",
                        num_facturation: this.props.invoice.num_facturation,
                        username: this.context.user.username,
                      })
                      .then(this.handleConvertToFacture)
                      .catch(this.handleConvertError);
                  }}
                >
                  <LiaFileInvoiceSolid className="text-white" size={20} />
                </Button>
              ) : null
            ) : null}
            {auth.isVisible("Envoyer un devis") === true ? (
              <Button
                className="btn-sm btn-icon btn-fill btn-success m-1"
                style={buttonStyle}
                variant="success"
                title="Envoyer"
                onClick={this.handleSendEmailClick}
              >
                <FiSend className="text-white" size={20} />
              </Button>
            ) : null}
            {auth.isVisible("Télécharger un devis") === true ? (
              <Button
                className="btn-sm btn-icon btn-fill m-1"
                style={buttonStyle}
                variant="primary"
                title="Télécharger"
                onClick={(e) => {
                  generatePDF(
                    this.props.invoice,
                    invoiceType,
                    this.context.marchand
                  );
                }}
              >
                <AiOutlineDownload className="text-white" size={20} />
              </Button>
            ) : this.context.user.type === "client" ? null : null}
            {(invoiceType === "Facture" || invoiceType === "Devis") &&
              (this.props.invoice.status !== "brouillon" ? null : (this.context.user.type !== "professionnel" &&
                  this.props.location.pathname === "/welcome/find_devis") ||
                (this.props.location.pathname === "/welcome/devis" && this.context.user.type === "particulier" &&
                  this.props.invoice.allouer !== 1) ? null : (this.context.user !== null && this.context.isVisible("Supprimer devis") === true ? (
                  <Button
                    className="btn-sm btn-icon btn-fill m-1"
                    style={buttonStyle}
                    variant="danger"
                    title="Supprimer"
                    onClick={(e) => {
                      e.preventDefault();
                      this.props.setIsLoading(true);
                      let url =
                        ApiConstants.baseUrlRequest + ApiConstants.deleteInvoice;
                      axios
                        .delete(url, {
                          data: {
                            username: this.context.user.username,
                            num_facturation: this.props.invoice.num_facturation,
                          },
                        })
                        .then(this.handleDeleteInvoice)
                        .catch(this.handleConvertError);
                    }}
                  >
                    <BsFillTrashFill className="text-white" size={20} />
                  </Button>
                ): null
               ))}

            <Dropdown
              show={this.state.showDropdown}
              onToggle={() => this.handleDropdownToggle()}
              onClose={this.handleDropdownClose}
            >
              <Dropdown.Toggle
                as={CustomToggle}
                variant="warning"
                title="Plus d'actions"
              >
                <Button
                  className="btn btn-sm btn-warning btn-icon btn-fill m-1"
                  style={buttonStyle}
                  title="Plus d'actions"
                >
                  <HiOutlineEllipsisVertical className="text-white" size={20} />
                </Button>
              </Dropdown.Toggle>

              <Dropdown.Menu>
                {invoiceType === "Devis" ? (
                  <Dropdown.Item onClick={this.handleModifierClick}>
                    Modifier
                  </Dropdown.Item>
                ) : null}

                { (invoiceType === "Devis" && this.context.user.type === "particulier" && (this.props.invoice.status === "refusé" || this.props.invoice.status === "en attente" ))
                  || (invoiceType === "Devis" && this.context.user.type !== "particulier" && (this.props.invoice.status === "refusé" || this.props.invoice.status === "en attente" )) ? (
                  <Dropdown.Item onClick={(e) => {
                      e.preventDefault();
                      this.setState({ showModal2: true });
                    }}
                  >
                    Accepter
                    
                  </Dropdown.Item>
                ) : null}

                { invoiceType === "Devis" && this.context.user.type === "particulier" && this.props.invoice.status === "en attente" ? (
                  <Dropdown.Item onClick={this.handleRefuseStatusInvoiceClick}>
                     Refuser
                  </Dropdown.Item>
                ) : null}

                {invoiceType === "Devis non associés" ? (
                  <Dropdown.Item
                    onClick={(e) => {
                      e.preventDefault();
                      this.setState({ showModal: true });
                    }}
                  >
                    Attribuer
                  </Dropdown.Item>
                ) : null} 
                
                {invoiceType === "Devis non associés" && this.context
                  .user.userType === "marchand" ? (
                   <Dropdown.Item onClick={this.handleChangeStatusInvoiceClick}>
                    Accepter
                  </Dropdown.Item>
                ) : null}

                {invoiceType === "Devis" && this.props.invoice.allouer === 1 && this.context.user.type !== "particulier" ? (
                  <Dropdown.Item
                    onClick={(e) => {
                      let data = {
                        username: this.context.user.username,
                        num_facturation: this.props.invoice.num_facturation,
                      };
                      this.props.setIsLoading(true);
                      let url =
                        ApiConstants.baseUrlRequest +
                        ApiConstants.setAllocationInvoice;
                      axios
                        .put(url, data)
                        .then(this.handleAllocationInvoice)
                        .catch(this.handleConvertError);
                    }}
                  >
                    Désattribuer
                  </Dropdown.Item>
                ) : null}

                { 
                  ((invoiceType === "Devis" && auth.isVisible("Accepter un devis") === true &&  this.props.invoice.status === "brouillon" && this.context.user.type === "marchand" ) 
                  ||  
                  (invoiceType === "Facture" && auth.isVisible("Finaliser une facture") === true) 
                  ||
                  invoiceType === "Bon de commande") &&
                  this.props.invoice.status === "brouillon" ? (
                  <Dropdown.Item onClick={this.handleChangeStatusInvoiceClick}>
                    {invoiceType === "Devis" ? "Accepter" : "Finaliser"}
                  </Dropdown.Item>
                ) : null}

                

                {invoiceType === "Facture" &&
                  this.props.invoice.status === "à encaisser" ? (
                  <Dropdown.Item onClick={this.handleChangeStatusInvoiceClick}>
                    Payer content
                  </Dropdown.Item>
                ) : null}
                {invoiceType === "Devis" && auth.isVisible("Valider un devis") === true &&
                  this.props.invoice.status === "accepté" &&
                  this.props.allouer !== true ? (
                  <Dropdown.Item onClick={this.handleChangeStatusInvoiceClick}>
                    Valider
                  </Dropdown.Item> 
                ) : null}
              </Dropdown.Menu>
            </Dropdown>
            <Modal
              show={this.state.showModal}
              onHide={() => this.setState({ showModal: false })}
              style={{ backdropFilter: 'none' }}

            >
              <Modal.Header closeButton>
                <Modal.Title>Votre période de travail</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <p>
                  La période de réalisation de ce devis doit être supérieur à :
                </p>
                <p className="text-warning fw-bold">
                  {" "}
                  {`${moment(this.props.invoice.date_debut).format(
                    "MM/DD/YYYY"
                  )}`}
                </p>
                <Form>
                  <Row>
                    <Col>
                      <Form.Group
                        controlId="dateD"
                        className="d-flex justify-content-between  align-items-center"
                      >
                        <Form.Label className="me-3 text-end searchlabel">
                          Début
                        </Form.Label>

                        <Form.Control
                          type="date"
                          ref={(input) => (this.dateD = input)}
                        />
                      </Form.Group>
                      <Form.Group></Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Group
                        controlId="dateF"
                        className="d-flex justify-content-between  align-items-center"
                      >
                        <Form.Label className="me-3 text-end searchlabel">
                          Fin
                        </Form.Label>

                        <Form.Control
                          type="date"
                          ref={(input) => (this.dateF = input)}
                        />
                      </Form.Group>
                      <Form.Group></Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <p className="text-danger mt-3">
                        {this.state.dateErrorMessage}
                      </p>
                    </Col>
                  </Row>
                </Form>
              </Modal.Body>
              <Modal.Footer className="d-flex justify-content-center">
                <Button
                  variant="dark"
                  className="btn-fill"
                  onClick={(e) => {
                    if (!this.dateD.value || !this.dateF.value) {
                      this.setState({
                        dateErrorMessage:
                          "Veuillez renseigner les dates de début et de fin.",
                      });
                      return;
                    }
                    const realDateDebut = new Date(
                      this.props.invoice.date_debut
                    );
                    
                    const workDateDebut = new Date(this.dateD.value);
                    const workDateFin = new Date(this.dateF.value);

                    if (
                      realDateDebut.getTime() <= workDateDebut.getTime() &&
                      workDateFin.getTime() >= workDateDebut.getTime()
                    ) {
                      this.setState({ showModal: false });
                      let data = {
                        username: this.context.user.username,
                        date_realisation_fin:
                          moment(workDateFin).format("DD/MM/YYYY"),
                        date_realisation_debut:
                          moment(workDateDebut).format("DD/MM/YYYY"),
                        num_facturation: this.props.invoice.num_facturation,
                      };
                      this.props.setIsLoading(true);
                      let url =
                        ApiConstants.baseUrlRequest +
                        ApiConstants.setAllocationInvoice;
                      axios
                        .put(url, data)
                        .then(this.handleAllocationInvoice)
                        .catch(this.handleConvertError);
                    } else {
                      this.setState({
                        dateErrorMessage:
                          "Veuillez renseigner une prériode de travail valide pour se devis.",
                      });
                      return;
                    }
                  }}
                >
                  Attribuer
                </Button>
              </Modal.Footer>
            </Modal>
            
            <Modal show={this.state.showModal2} onHide={() => this.setState({ showModal2: false })}
              id="accept_modal"
           
              
            >
            <Modal.Header closeButton className="d-flex justify-content-center">
              <Modal.Title className="justify-content-center">
                Votre période de travail
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p>A quelle date désirez-vous débuter vos travaux</p>
              <Form>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="date_realisation_debut"
                    >
                      <Form.Label column sm="2">
                        date
                      </Form.Label>
                      <Col sm="10">
                        <Form.Control
                          type="date"
                          value={this.state.date_realisation_debut}
                          onChange={this.handleDateChange}
                          min={this.getMinDate()}
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <p className="text-danger mt-3">{this.state.dateErrorMessage}</p>
                  </Col>
                </Row>
              </Form>
            </Modal.Body>
            <Modal.Footer className="d-flex justify-content-center">
              <Button
                variant="success"
                className="btn-fill bg-success"
                onClick={(e) => {
                  e.preventDefault();
                  this.props.setIsLoading(true);
                  if (!this.state.date_realisation_debut) {
                    setDateErrorMessage(
                      "Veuillez renseigner la date de début."
                    );
                    return;
                  }
                  let url = ApiConstants.baseUrlRequest + ApiConstants.setStatusInvoice;

                  axios
                    .put(url, {
                      username: this.context.user.username,
                      num_facturation: this.props.invoice.num_facturation,
                    })
                    .then(this.handleChangeStateInvoice)
                    .catch(this.handleConvertError);
                }}
                >
                  Confirmer
                </Button>
              </Modal.Footer>
            </Modal>
          </div>
        </td>
      </tr>
    );
  }
}

export default InvoiceLineTable;
