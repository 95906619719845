import React from "react";


import { Dots } from "react-activity";
import "react-activity/dist/library.css";

class Loading extends React.Component{
    constructor(props){
        super(props);
    }

    render(){
        return (
            <section className="d-flex justify-content-center align-items-center" style={{ width: '100%',}}>
               <div className="d-flex justify-content-center align-items-center" style={{ width: '50rem', height:'30rem' }} >
                    <Dots color="#6bbf57" size={32} speed={1} animating={this.props.isLoading} />
                </div>
            </section>
        );
    }
}

export default Loading;