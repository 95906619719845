import adresse_img from "assets/img/cone.png";
import password from "assets/img/password.png";
import new_user from "assets/img/new_user.png";
import React, { useState, useEffect } from "react";
import ApiConstants from "constant";
import axios from "axios";
import Select from "react-select";
import "assets/css/address.css";
import validator from "validator";
import { Button, Col, Row, Modal, Form } from "react-bootstrap";
import { Spinner } from "react-activity";
import { MdVerifiedUser } from "react-icons/md";
import { useAuth } from "AuthProvider";
import { remove_style_script_home } from "../utils";
import { masking_transition } from "../utils";
import PhoneInput from "react-phone-number-input";
import { customStyleForReactSelect } from "../utils";

import {
	getCountryCallingCode,
	isPossiblePhoneNumber,
	parsePhoneNumber,
	formatPhoneNumberIntl,
} from "react-phone-number-input";
import { event } from "jquery";

function CreateQuotationWithFlowchart() {
	const auth = useAuth();

	const [isUserFound, setIsUserFound] = useState(false);
	const [user, setUser] = useState(() => {
		const storedUser = localStorage.getItem('user');
		return storedUser ? JSON.parse(storedUser) : null;
	});

	const [inputValue, setInputValue] = useState("");
	const [postalCode, setPostalCode] = useState("");
	const [countryCode, setCountryCode] = useState("");
	const [street, setStreet] = useState("");
	const [country, setCountry] = useState("");
	const [region, setRegion] = useState("");
	const [newAddress, setNewAddress] = useState(false);
	const [isStreetFound, setIsStreetFound] = useState(true);
	const [town, setTown] = useState("");
	const [towns, setTowns] = useState([]);
	const [streets, setStreets] = useState([]);
	const [showPostalCodeError, setShowPostalCodeError] = useState(false);
	const [showNoAddressFound, setShowNoAddressFound] = useState(false);
	const [adresse, setAdresse] = useState([]);

	const [otpToVerify, setOtpToVerify] = useState("");
	const [otpErrorMessage, setOtpErrorMessage] = useState("");

	const [otpConfirmSpinnerIsLoading, setOtpConfirmSpinnerIsLoading] =
		useState(false);
	const [showModal, setShowModal] = useState(false);
	const [otpVerificationTitle, setOtpVerificationTitle] = useState("");

	const [phoneNumber, setPhoneNumber] = useState("");
	const [phoneWhatsappNumber, setPhoneWhatsappNumber] = useState("");

	const [email, setEmail] = useState("");
	const [emailFacturaction, setEmailFacturaction] = useState("");

	const [isPossibleNumber, setIsPossibleNumber] = useState(true);
	const [isPossibleWhatsappNumber, setIsPossibleWhatsappNumber] =
		useState(true);

	const [isOtpPhoneVerified, setIsOtpPhoneVerified] = useState(false);
	const [otpPhoneSpinnerIsLoading, setOtpPhoneSpinnerIsLoading] =
		useState(false);

	const [isOtpEmailVerified, setIsOtpEmailVerified] = useState(false);
	const [otpEmailSpinnerIsLoading, setOtpEmailSpinnerIsLoading] =
		useState(false);

	const [isOtpWhatsappVerified, setIsOtpWhatsappVerified] = useState(false);
	const [otpWhatsappSpinnerIsLoading, setOtpWhatsappSpinnerIsLoading] =
		useState(false);

	const [otpEmailErrorMessage, setOtpEmailErrorMessage] = useState("");
	const [otpPhoneErrorMessage, setOtpPhoneErrorMessage] = useState("");
	const [otpWhatsappErrorMessage, setOtpWhatsappErrorMessage] = useState("");

	const [otpToVerifyChantier, setOtpToVerifyChantier] = useState("");
	const [otpErrorMessageChantier, setOtpErrorMessageChantier] = useState("");

	const [
		otpConfirmSpinnerIsLoadingChantier,
		setOtpConfirmSpinnerIsLoadingChantier,
	] = useState(false);
	const [showModalChantier, setShowModalChantier] = useState(false);
	const [otpVerificationTitleChantier, setOtpVerificationTitleChantier] =
		useState("");

	const [phoneWhatsappNumberChantier, setPhoneWhatsappNumberChantier] =
		useState("");

	const [
		isPossibleWhatsappNumberChantier,
		setIsPossibleWhatsappNumberChantier,
	] = useState(true);

	const [isOtpPhoneVerifiedChantier, setIsOtpPhoneVerifiedChantier] =
		useState(false);
	const [
		otpPhoneSpinnerIsLoadingChantier,
		setOtpPhoneSpinnerIsLoadingChantier,
	] = useState(false);

	const [isOtpWhatsappVerifiedChantier, setIsOtpWhatsappVerifiedChantier] =
		useState(false);
	const [
		otpWhatsappSpinnerIsLoadingChantier,
		setOtpWhatsappSpinnerIsLoadingChantier,
	] = useState(false);

	const [otpPhoneErrorMessageChantier, setOtpPhoneErrorMessageChantier] =
		useState("");
	const [otpWhatsappErrorMessageChantier, setOtpWhatsappErrorMessageChantier] =
		useState("");

	const [otpToVerifyFacturation, setOtpToVerifyFacturation] = useState("");
	const [otpErrorMessageFacturation, setOtpErrorMessageFacturation] =
		useState("");

	const [
		otpConfirmSpinnerIsLoadingFacturation,
		setOtpConfirmSpinnerIsLoadingFacturation,
	] = useState(false);
	const [showModalFacturation, setShowModalFacturation] = useState(false);
	const [otpVerificationTitleFacturation, setOtpVerificationTitleFacturation] =
		useState("");

	const [phoneWhatsappNumberFacturation, setPhoneWhatsappNumberFacturation] =
		useState("");

	const [
		isPossibleWhatsappNumberFacturation,
		setIsPossibleWhatsappNumberFacturation,
	] = useState(true);

	const [isOtpPhoneVerifiedFacturation, setIsOtpPhoneVerifiedFacturation] =
		useState(false);
	const [
		otpPhoneSpinnerIsLoadingFacturation,
		setOtpPhoneSpinnerIsLoadingFacturation,
	] = useState(false);

	const [isOtpEmailVerifiedFacturation, setIsOtpEmailVerifiedFacturation] =
		useState(false);
	const [
		otpEmailSpinnerIsLoadingFacturation,
		setOtpEmailSpinnerIsLoadingFacturation,
	] = useState(false);

	const [
		isOtpWhatsappVerifiedFacturation,
		setIsOtpWhatsappVerifiedFacturation,
	] = useState(false);
	const [
		otpWhatsappSpinnerIsLoadingFacturation,
		setOtpWhatsappSpinnerIsLoadingFacturation,
	] = useState(false);

	const [otpEmailErrorMessageFacturation, setOtpEmailErrorMessageFacturation] =
		useState("");
	const [otpPhoneErrorMessageFacturation, setOtpPhoneErrorMessageFacturation] =
		useState("");
	const [
		otpWhatsappErrorMessageFacturation,
		setOtpWhatsappErrorMessageFacturation,
	] = useState("");

	const userData = JSON.parse(localStorage.getItem("userData"));

	//***************************************************************************/

	const [inputValueChantier, setInputValueChantier] = useState("");
	const [postalCodeChantier, setPostalCodeChantier] = useState("");
	const [countryCodeChantier, setCountryCodeChantier] = useState("");
	const [streetChantier, setStreetChantier] = useState("");
	const [countryChantier, setCountryChantier] = useState("");
	const [regionChantier, setRegionChantier] = useState("");
	const [newAddressChantier, setNewAddressChantier] = useState(false);
	const [isStreetFoundChantier, setIsStreetFoundChantier] = useState(true);
	const [townChantier, setTownChantier] = useState("");
	const [townsChantier, setTownsChantier] = useState([]);
	const [streetsChantier, setStreetsChantier] = useState([]);
	const [showPostalCodeErrorChantier, setShowPostalCodeErrorChantier] =
		useState(false);
	const [showNoAddressFoundChantier, setShowNoAddressFoundChantier] =
		useState(false);
	const [adresseChantier, setAdresseChantier] = useState([]);
	const [phoneNumberChantier, setPhoneNumberChantier] = useState("");
	const [isPossibleNumberChantier, setIsPossibleNumberChantier] =
		useState(true);

	//*********************************************************************************************** */

	const [inputValueFacturation, setInputValueFacturation] = useState("");
	const [postalCodeFacturation, setPostalCodeFacturation] = useState("");
	const [countryCodeFacturation, setCountryCodeFacturation] = useState("");
	const [streetFacturation, setStreetFacturation] = useState("");
	const [countryFacturation, setCountryFacturation] = useState("");
	const [regionFacturation, setRegionFacturation] = useState("");
	const [newAddressFacturation, setNewAddressFacturation] = useState(false);
	const [isStreetFoundFacturation, setIsStreetFoundFacturation] =
		useState(true);
	const [townFacturation, setTownFacturation] = useState("");
	const [townsFacturation, setTownsFacturation] = useState([]);
	const [streetsFacturation, setStreetsFacturation] = useState([]);
	const [showPostalCodeErrorFacturation, setShowPostalCodeErrorFacturation] =
		useState(false);
	const [showNoAddressFoundFacturation, setShowNoAddressFoundFacturation] =
		useState(false);
	const [adresseFacturation, setAdresseFacturation] = useState([]);
	const [phoneNumberFacturation, setPhoneNumberFacturation] = useState("");
	const [isPossibleNumberFacturation, setIsPossibleNumberFacturation] =
		useState(true);
	const [whatsappDefault, setWhatsappDefault] = useState("");
	const [uniqueId, setUniqueId] = useState(null);
	const [selectedOption, setSelectedOption] = useState("");
	const [isConnected, setIsConnected] = useState(
		JSON.parse(localStorage.getItem("user")) ? true : false,
	);

	let childLinkForStyleFreeDevis = null;
	let childScriptShow = null;
	let childLinkForBoostrap = null;
	let childLinkForLightBoostrapReactv2_0_0 = null;
	let childScriptFreeDevis = null;

	React.useEffect(() => {
		//remove all home styles
		remove_style_script_home();

		// Adding bootstrap
		let linkForBoostrap = document.createElement("link");
		linkForBoostrap.id = "bootstrap_free_devis";
		linkForBoostrap.type = "text/css";
		linkForBoostrap.rel = "stylesheet";
		linkForBoostrap.href = "/css/bootstrap.min.css";
		childLinkForBoostrap = document.head.appendChild(linkForBoostrap);

		// Adding style for free devis
		let linkForStyleFreeDevis = document.createElement("link");
		linkForStyleFreeDevis.id = "style_free_devis";
		linkForStyleFreeDevis.type = "text/css";
		linkForStyleFreeDevis.rel = "stylesheet";
		linkForStyleFreeDevis.href = "/css/style_free_devis.css";
		childLinkForStyleFreeDevis = document.head.appendChild(
			linkForStyleFreeDevis,
		);

		// Adding light bootstrap
		let linkLightBoostrapReactv2_0_0 = document.createElement("link");
		linkLightBoostrapReactv2_0_0.type = "text/css";
		linkLightBoostrapReactv2_0_0.id = "style_lightBoostrapReactv2_0_0";
		linkLightBoostrapReactv2_0_0.rel = "stylesheet";
		linkLightBoostrapReactv2_0_0.href =
			"/css/light-bootstrap-dashboard-react.css";
		childLinkForLightBoostrapReactv2_0_0 = document.head.appendChild(
			linkLightBoostrapReactv2_0_0,
		);

		//script for masking transition
		childScriptShow = masking_transition(
			"show_free_devis_home_page",
			"free_devis_home_page",
			"visible",
		);

		//Addind Script for free devis
		let script_free_devis = document.createElement("script");
		script_free_devis.type = "text/javascript";
		script_free_devis.id = "free-devis-js";
		script_free_devis.src = "/js/script.js";
		childScriptFreeDevis = document.body.appendChild(script_free_devis);

		// returned function will be called on component unmount
		//Remove all welcome page when go to home
		return () => {
			if (childLinkForStyleFreeDevis) {
				document.head.removeChild(childLinkForStyleFreeDevis);
			}
			if (childScriptShow) {
				document.body.removeChild(childScriptShow);
			}

			if (childLinkForBoostrap) {
				document.head.removeChild(childLinkForBoostrap);
			}

			if (childLinkForLightBoostrapReactv2_0_0) {
				document.head.removeChild(childLinkForLightBoostrapReactv2_0_0);
			}

			if (childScriptFreeDevis) {
				document.body.removeChild(childScriptFreeDevis);
			}
		};
	}, []);


	useEffect(() => {
		const checkUser = () => {
			const storedUser = localStorage.getItem('user');
			if (storedUser) {
				setUser(JSON.parse(storedUser));
				setIsUserFound(true); // Mark user as found
			} else {
				setUser(null);
			}
		};

		// Check user on mount if not already found
		if (!isUserFound) {
			checkUser();
		}

		// Optional: Set up an interval to check user every few seconds
		const intervalId = setInterval(() => {
			if (!isUserFound) {
				checkUser();
			} else {
				clearInterval(intervalId); // Stop checking if user is found
			}
		}, 5000); // Check every 5 seconds

		// Cleanup interval on unmount
		return () => clearInterval(intervalId);
	}, [isUserFound]); // Add isUserFound to dependencies




	const handleOptionChange = (event) => {
		setSelectedOption(event.target.value);
	};

	const sendotp = async (type, whereToSendOtp) => {
		let dataTosend = null;
		const uniqueid = auth.generateUniqueId();
		const address = whereToSendOtp;
		setUniqueId(uniqueid);
		const dataotp = {
			userid: 1,
			token: process.env.REACT_APP_TOKEN,
			serviceid: 4,
			address: address,
			type:
				type === "sms_chantier" || type === "sms_facturation"
					? "sms"
					: type === "email_facturation"
						? "email"
						: type,
			username: "admin",
			uniqueid: uniqueid,
		};

		const hash = auth.generateHash(dataotp);
		const datawithhash = { ...dataotp };
		datawithhash.hash = hash;
		dataTosend = datawithhash;

		if (type === "whatsApp") {
			const dataotp2 = {
				username: "XXXX",
				password: "YYYY",
				number: address,
				message: "Message to sent",
				type: "chat",
			};

			const hash2 = auth.md5Hash(dataotp2);

			const datawithhash2 = { ...dataotp2 };
			datawithhash2.hash = hash2;
			dataTosend = datawithhash2;
		}

		let response = await auth.sendotp(dataTosend);

		// alert("enter")
		if (response && response.status === "SUCCESS") {
			// alert("passser")
			if (type === "email") {
				setShowModal(true);
				setOtpVerificationTitle("Vérification de votre adresse e-mail");
				setOtpEmailSpinnerIsLoading(false);
			} else if (type === "sms") {
				setShowModal(true);
				setOtpVerificationTitle("Vérification de votre numéro de téléphone");
				setOtpPhoneSpinnerIsLoading(false);
			} else if (type === "sms_chantier") {
				setShowModalChantier(true);
				setOtpVerificationTitleChantier(
					"Vérification de votre numéro de téléphone",
				);
				setOtpPhoneSpinnerIsLoadingChantier(false);
			} else if (type === "sms_facturation") {
				setShowModalFacturation(true);
				setOtpVerificationTitleFacturation(
					"Vérification de votre numéro de téléphone",
				);
				setOtpPhoneSpinnerIsLoadingFacturation(false);
			} else if (type === "whatsApp") {
				setShowModal(true);
				setOtpVerificationTitle("Vérification de votre numéro whatsapp");
				setOtpWhatsappSpinnerIsLoading(false);
			} else if (type === "whatsApp_chantier") {
				setShowModalChantier(true);
				setOtpVerificationTitleChantier(
					"Vérification de votre numéro whatsapp",
				);
				setOtpWhatsappSpinnerIsLoadingChantier(false);
			} else if (type === "whatsApp_facturation") {
				setShowModalFacturation(true);
				setOtpVerificationTitleFacturation(
					"Vérification de votre numéro whatsapp",
				);
				setOtpWhatsappSpinnerIsLoadingFacturation(false);
			} else if (type === "email_facturation") {
				setShowModalFacturation(true);
				setOtpVerificationTitleFacturation(
					"Vérification de votre adresse e-mail",
				);
				setOtpEmailSpinnerIsLoadingFacturation(false);
			}
		} else {
			// alert("echerccccc")
			//to delete something
			if (type === "email") {
				setShowModal(false);
				// setOtpVerificationTitle('Vérification de votre adresse e-mail')
				setOtpEmailSpinnerIsLoading(false);
				setOtpEmailErrorMessage("Échec d'envoie du code de vérification.");
			} else if (type === "sms") {
				setShowModal(false);
				// setOtpVerificationTitle('Vérification de votre numéro de téléphone')
				setOtpPhoneSpinnerIsLoading(false);
				setOtpPhoneErrorMessage("Échec d'envoie du code de vérification.");
			} else if (type === "sms_chantier") {
				setShowModalChantier(false);
				// setOtpVerificationTitleChantier('Vérification de votre numéro de téléphone')
				setOtpPhoneSpinnerIsLoadingChantier(false);
				setOtpPhoneErrorMessageChantier(
					"Échec d'envoie du code de vérification.",
				);
			} else if (type === "sms_facturation") {
				setShowModalFacturation(false);
				// setOtpVerificationTitleFacturation('Vérification de votre numéro de téléphone')
				setOtpPhoneSpinnerIsLoadingFacturation(false);
				setOtpPhoneErrorMessageFacturation(
					"Échec d'envoie du code de vérification.",
				);
			} else if (type === "whatsApp") {
				setShowModal(false);
				// setOtpVerificationTitle('Vérification de votre numéro whatsapp')
				setOtpWhatsappSpinnerIsLoading(false);
				setOtpWhatsappErrorMessage("Échec d'envoie du code de vérification.");
			} else if (type === "whatsApp_chantier") {
				setShowModalChantier(false);
				// setOtpVerificationTitleChantier('Vérification de votre numéro whatsapp')
				setOtpWhatsappSpinnerIsLoadingChantier(false);
				setOtpWhatsappErrorMessageChantier(
					"Échec d'envoie du code de vérification.",
				);
			} else if (type === "whatsApp_facturation") {
				setShowModalFacturation(false);
				// setOtpVerificationTitleFacturation('Vérification de votre numéro whatsapp')
				setOtpWhatsappSpinnerIsLoadingFacturation(false);
				setOtpWhatsappErrorMessageFacturation(
					"Échec d'envoie du code de vérification.",
				);
			} else if (type === "email_facturation") {
				setShowModalFacturation(false);
				// setOtpVerificationTitleFacturation('Vérification de votre adresse e-mail')
				setOtpEmailSpinnerIsLoadingFacturation(false);
				setOtpEmailErrorMessageFacturation(
					"Échec d'envoie du code de vérification.",
				);
			}
		}
	};

	const checkotp = async (type) => {
		const dataotp = {
			userid: 1,
			token: process.env.REACT_APP_TOKEN,
			totp:
				type === "chantier"
					? parseInt(otpToVerifyChantier)
					: type === "facturation"
						? parseInt(otpToVerifyFacturation)
						: parseInt(otpToVerify),
			uniqueid: uniqueId,
		};

		const hash = auth.generateHash(dataotp);
		const datawithhash = { ...dataotp };
		datawithhash.hash = hash;

		let response = await auth.checkotp(datawithhash);

		if (response && response.status === "SUCCESS") {
			if (type === "chantier") {
				handleCloseModalChantier();
				if (
					otpVerificationTitleChantier ===
					"Vérification de votre adresse e-mail"
				) {
					setIsOtpEmailVerifiedChantier(true);
				} else if (
					otpVerificationTitleChantier ===
					"Vérification de votre numéro de téléphone"
				) {
					setIsOtpPhoneVerifiedChantier(true);
					setPhoneWhatsappNumberChantier(phoneNumberChantier);
				} else if (
					otpVerificationTitleChantier ===
					"Vérification de votre numéro whatsapp"
				) {
					setIsOtpWhatsappVerifiedChantier(true);
				}
				setOtpConfirmSpinnerIsLoadingChantier(false);
				return;
			} else if (type === "facturation") {
				handleCloseModalFacturation();
				if (
					otpVerificationTitleFacturation ===
					"Vérification de votre adresse e-mail"
				) {
					setIsOtpEmailVerifiedFacturation(true);
				} else if (
					otpVerificationTitleFacturation ===
					"Vérification de votre numéro de téléphone"
				) {
					setIsOtpPhoneVerifiedFacturation(true);
					setPhoneWhatsappNumberFacturation(phoneNumber);
				} else if (
					otpVerificationTitleFacturation ===
					"Vérification de votre numéro whatsapp"
				) {
					setIsOtpWhatsappVerifiedFacturation(true);
				}
				setOtpConfirmSpinnerIsLoadingFacturation(false);
				return;
			}

			if (otpVerificationTitle === "Vérification de votre adresse e-mail") {
				setIsOtpEmailVerified(true);
				handleCloseModal();
			} else if (
				otpVerificationTitle === "Vérification de votre numéro de téléphone"
			) {
				setIsOtpPhoneVerified(true);
				setPhoneWhatsappNumber(phoneNumber);
				handleCloseModal();
			} else if (
				otpVerificationTitle === "Vérification de votre numéro whatsapp"
			) {
				setIsOtpWhatsappVerified(true);
				handleCloseModal();
			}
			setOtpConfirmSpinnerIsLoading(false);
		} else {
			// alert("$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$")
			//to delete something
			if (otpVerificationTitle === "Vérification de votre adresse e-mail") {
				setIsOtpEmailVerified(false);
				setOtpErrorMessage("Le code incorrect");
				// handleCloseModal()
			} else if (
				otpVerificationTitle === "Vérification de votre numéro de téléphone"
			) {
				setIsOtpPhoneVerified(false);
				setOtpErrorMessage("Le code incorrect");
				setPhoneWhatsappNumber(phoneNumber);
				// handleCloseModal()
			} else if (
				otpVerificationTitle === "Vérification de votre numéro whatsapp"
			) {
				setIsOtpWhatsappVerified(false);
				setOtpErrorMessage("Le code incorrect");
				// handleCloseModal()
			}
			setOtpConfirmSpinnerIsLoading(false);
		}
	};

	const initValue = () => {
		setInputValue("");
		setPostalCode("");
		setCountryCode("");
		setStreet("");
		setCountry("");
		setRegion("");
		setNewAddress(false);
		setIsStreetFound(true);
		setTown("");
		setTowns([]);
		setStreets([]);
		setShowPostalCodeError(false);
		setShowNoAddressFound(false);
		setAdresse([]);
	};

	const handlePhoneVerificationSubmit = () => {
		sendotp("sms", phoneNumber.split(" ").join("").substring(1));
	};

	const handlePhoneVerificationSubmitChantier = () => {
		sendotp(
			"sms_chantier",
			phoneNumberChantier.split(" ").join("").substring(1),
		);
	};

	const handlePhoneVerificationSubmitFacturation = () => {
		sendotp(
			"sms_facturation",
			phoneNumberFacturation.split(" ").join("").substring(1),
		);
	};

	const handleEmailVerificationSubmit = () => {
		sendotp("email", email);
	};

	const handleEmailVerificationSubmitFacturation = () => {
		sendotp("email_facturation", emailFacturaction);
	};

	const handleWhatsappVerificationSubmit = () => {
		sendotp("whatsApp", phoneWhatsappNumber.split(" ").join("").substring(1));
	};

	const handleWhatsappVerificationSubmitChantier = () => {
		sendotp(
			"whatsApp_chantier",
			phoneWhatsappNumberChantier.split(" ").join("").substring(1),
		);
	};

	const handleWhatsappVerificationSubmitFacturation = () => {
		sendotp(
			"whatsApp_facturation",
			phoneWhatsappNumberFacturation.split(" ").join("").substring(1),
		);
	};

	const handleCloseModal = () => {
		setOtpErrorMessage("");
		setShowModal(false);
	};

	const handleCloseModalChantier = () => {
		setOtpErrorMessageChantier("");
		setShowModalChantier(false);
	};

	const handleCloseModalFacturation = () => {
		setOtpErrorMessageFacturation("");
		setShowModalFacturation(false);
	};

	const handleOtpChange = (e) => {
		setOtpToVerify(e.target.value);
	};

	const handleOtpChangeChantier = (e) => {
		setOtpToVerifyChantier(e.target.value);
	};

	const handleOtpChangeFacturation = (e) => {
		setOtpToVerifyFacturation(e.target.value);
	};

	const handleConfirmVerificationSubmit = () => {
		if (!otpToVerify) {
			setOtpErrorMessage("Veuillez renseigner le code que vous avez reçu.");
			setOtpConfirmSpinnerIsLoading(false);
			return;
		}
		checkotp();
	};

	const handleConfirmVerificationSubmitChantier = () => {
		if (!otpToVerifyChantier) {
			setOtpErrorMessageChantier(
				"Veuillez renseigner le code que vous avez reçu.",
			);
			setOtpConfirmSpinnerIsLoadingChantier(false);
			return;
		}
		checkotp("chantier");
	};

	const handleConfirmVerificationSubmitFacturation = () => {
		if (!otpToVerifyFacturation) {
			setOtpErrorMessageFacturation(
				"Veuillez renseigner le code que vous avez reçu.",
			);
			setOtpConfirmSpinnerIsLoadingFacturation(false);
			return;
		}
		checkotp("facturation");
	};

	const handleDeliveryItemAdd = (event) => {
		initValue();
	};

	const handleKeyUp = (event) => {
		setInputValue(event.target.value);
	};

	const handleCountryChange = (option) => {
		setCountryCode(option.value);
	};

	const handleCountryChangeChantier = (option) => {
		setCountryCodeChantier(option.value);
	};

	const handleCountryChangeFacturation = (option) => {
		setCountryCodeFacturation(option.value);
	};

	const handleTownChange = (option) => {
		const temp = adresse.filter((element) => element.town == option.value);
		setStreets(temp.map((address) => address.street));
		setRegion(temp.length > 0 ? temp[0].region : "");
		setTown(option.value);
	};

	const handleTownChangeChantier = (option) => {
		const temp = adresseChantier.filter(
			(element) => element.town == option.value,
		);
		setStreetsChantier(temp.map((address) => address.street));
		setRegionChantier(temp.length > 0 ? temp[0].region : "");
		setTownChantier(option.value);
	};

	const handleTownChangeFacturation = (option) => {
		const temp = adresseFacturation.filter(
			(element) => element.town == option.value,
		);
		setStreetsFacturation(temp.map((address) => address.street));
		setRegionFacturation(temp.length > 0 ? temp[0].region : "");
		setTownFacturation(option.value);
	};

	const handlePhoneNumberChange = (value, whatsapp = false) => {
		if (whatsapp) {
			setIsOtpWhatsappVerified(false);
		} else {
			setIsOtpPhoneVerified(false);
		}
		if (value && isPossiblePhoneNumber(value)) {
			// setIsPossibleNumber(true)
			const phone = parsePhoneNumber(value);
			if (phone.country === "BE") {
				const phoneDecomposition = formatPhoneNumberIntl(value).split(" ");
				const countryIndicator = phoneDecomposition[0];
				let onlyPhone = phoneDecomposition
					.slice(-(phoneDecomposition.length - 1))
					.join("");
				if (onlyPhone.length == 10) {
					if (onlyPhone[0] === "0") {
						onlyPhone === onlyPhone.slice(1);
					}
				}
				const phoneResult = formatPhoneNumberIntl(
					countryIndicator + "" + onlyPhone,
				);
				if (whatsapp) {
					setPhoneWhatsappNumber(phoneResult);
				} else {
					setPhoneNumber(phoneResult);
					setPhoneWhatsappNumber(phoneResult);
				}
			} else {
				const phoneResult = formatPhoneNumberIntl(value);
				if (whatsapp) {
					setPhoneWhatsappNumber(phoneResult);
				} else {
					setPhoneNumber(phoneResult);
					setPhoneWhatsappNumber(phoneResult);
				}
			}
			if (whatsapp) {
				setIsPossibleWhatsappNumber(true);
			} else {
				setIsPossibleNumber(true);
			}
		} else {
			if (whatsapp) {
				setIsPossibleWhatsappNumber(false);
			} else {
				setIsPossibleNumber(false);
			}
		}
	};

	const handlePhoneNumberChangeChantier = (value, whatsapp = false) => {
		if (whatsapp) {
			setIsOtpWhatsappVerifiedChantier(false);
		} else {
			setIsOtpPhoneVerifiedChantier(false);
		}
		if (value && isPossiblePhoneNumber(value)) {
			const phone = parsePhoneNumber(value);
			if (phone.country === "BE") {
				const phoneDecomposition = formatPhoneNumberIntl(value).split(" ");
				const countryIndicator = phoneDecomposition[0];
				let onlyPhone = phoneDecomposition
					.slice(-(phoneDecomposition.length - 1))
					.join("");
				if (onlyPhone.length == 10) {
					if (onlyPhone[0] === "0") {
						onlyPhone === onlyPhone.slice(1);
					}
				}
				const phoneResult = formatPhoneNumberIntl(
					countryIndicator + "" + onlyPhone,
				);
				if (whatsapp) {
					setPhoneWhatsappNumberChantier(phoneResult);
				} else {
					setPhoneNumberChantier(phoneResult);
					setPhoneWhatsappNumberChantier(phoneResult);
				}
			} else {
				const phoneResult = formatPhoneNumberIntl(value);
				if (whatsapp) {
					setPhoneWhatsappNumberChantier(phoneResult);
				} else {
					setPhoneNumberChantier(phoneResult);
					setPhoneWhatsappNumberChantier(phoneResult);
				}
			}
			if (whatsapp) {
				setIsPossibleWhatsappNumberChantier(true);
			} else {
				setIsPossibleNumberChantier(true);
			}
		} else {
			if (whatsapp) {
				setIsPossibleWhatsappNumberChantier(false);
			} else {
				setIsPossibleNumberChantier(false);
			}
		}
	};

	const handlePhoneNumberChangeFacturation = (value, whatsapp = false) => {
		if (whatsapp) {
			setIsOtpWhatsappVerifiedFacturation(false);
		} else {
			setIsOtpPhoneVerifiedFacturation(false);
		}
		if (value && isPossiblePhoneNumber(value)) {
			const phone = parsePhoneNumber(value);
			if (phone.country === "BE") {
				const phoneDecomposition = formatPhoneNumberIntl(value).split(" ");
				const countryIndicator = phoneDecomposition[0];
				let onlyPhone = phoneDecomposition
					.slice(-(phoneDecomposition.length - 1))
					.join("");
				if (onlyPhone.length == 10) {
					if (onlyPhone[0] === "0") {
						onlyPhone === onlyPhone.slice(1);
					}
				}
				const phoneResult = formatPhoneNumberIntl(
					countryIndicator + "" + onlyPhone,
				);
				if (whatsapp) {
					setPhoneWhatsappNumberFacturation(phoneResult);
				} else {
					setPhoneNumberFacturation(phoneResult);
					setPhoneWhatsappNumberFacturation(phoneResult);
				}
			} else {
				const phoneResult = formatPhoneNumberIntl(value);
				if (whatsapp) {
					setPhoneWhatsappNumberFacturation(phoneResult);
				} else {
					setPhoneNumberFacturation(phoneResult);
					setPhoneWhatsappNumberFacturation(phoneResult);
				}
			}
			if (whatsapp) {
				setIsPossibleWhatsappNumberFacturation(true);
			} else {
				setIsPossibleNumberFacturation(true);
			}
		} else {
			if (whatsapp) {
				setIsPossibleWhatsappNumberFacturation(false);
			} else {
				setIsPossibleNumberFacturation(false);
			}
		}
	};

	const handlePostalCodeChange = (event) => {
		setNewAddress(false);
		const PC = event.target.value;
		// const country_code = this.state.country_code;
		if (PC === "" || PC.length < 4) {
			setPostalCode("");
			setTown("");
			setCountry("");
			setRegion("");
			setTowns([]);
			setStreets([]);
			setShowNoAddressFound(false);
			setShowPostalCodeError(false);

			if (PC.length < 4) {
				setShowPostalCodeError(true);
			}
			return; // Sortez de la fonction pour éviter l'appel à l'API avec un code postal vide
		}
		// Supprimez les caractères supplémentaires du code postal
		const trimmedPostalCode = PC.slice(0, 4);
		// Mettez à jour l'état avec le code postal tronqué

		setPostalCode(trimmedPostalCode);
		setShowNoAddressFound(false);
		setShowPostalCodeError(false);

		let queryParams = {
			type: "create_account",
			country_code: countryCode,
			//keywords: encodeURIComponent(postalCode)
			keywords: encodeURIComponent(trimmedPostalCode),
		};
		let url = ApiConstants.baseUrlRequest + ApiConstants.getAdresse;
		axios
			.get(url, { params: queryParams })
			.then((response) => {
				if (response.status === 200) {
					const data = response.data;
					if (data && data.adresses && data.adresses.length > 0) {
						setAdresse(data.adresses);
						const addresses = data.adresses;
						const firstAddress = addresses[0];

						setPostalCode(trimmedPostalCode);
						setTown(firstAddress.town);
						setStreet(firstAddress.street);
						setCountry(firstAddress.country);
						setRegion(firstAddress.region);
						setTowns(addresses.map((address) => address.town));
						setStreets(addresses.map((address) => address.street));
					} else {
						setShowNoAddressFound(true);
					}
				} else if (response.status === 404) {
					// setIsLoading(false);
					// setIsFound(false);
				}
			})
			.catch(function (error) {
				console.error("Erreur lors de la requête :", error);
			});
	};

	const handlePostalCodeChangeChantier = (event) => {
		setNewAddressChantier(false);
		const PC = event.target.value;
		if (PC === "" || PC.length < 4) {
			setPostalCodeChantier("");
			setTownChantier("");
			setCountryChantier("");
			setRegionChantier("");
			setTownsChantier([]);
			setStreetsChantier([]);
			setShowNoAddressFoundChantier(false);
			setShowPostalCodeErrorChantier(false);

			if (PC.length < 4) {
				setShowPostalCodeErrorChantier(true);
			}
			return; // Sortez de la fonction pour éviter l'appel à l'API avec un code postal vide
		}
		// Supprimez les caractères supplémentaires du code postal
		const trimmedPostalCode = PC.slice(0, 4);
		// Mettez à jour l'état avec le code postal tronqué

		setPostalCodeChantier(trimmedPostalCode);
		setShowNoAddressFoundChantier(false);
		setShowPostalCodeErrorChantier(false);

		let queryParams = {
			type: "create_account",
			country_code: countryCodeChantier,
			//keywords: encodeURIComponent(postalCode)
			keywords: encodeURIComponent(trimmedPostalCode),
		};
		let url = ApiConstants.baseUrlRequest + ApiConstants.getAdresse;
		axios
			.get(url, { params: queryParams })
			.then((response) => {
				if (response.status === 200) {
					const data = response.data;
					if (data && data.adresses && data.adresses.length > 0) {
						setAdresseChantier(data.adresses);
						const addresses = data.adresses;
						const firstAddress = addresses[0];

						setPostalCodeChantier(trimmedPostalCode);
						setTownChantier(firstAddress.town);
						setStreetChantier(firstAddress.street);
						setCountryChantier(firstAddress.country);
						setRegionChantier(firstAddress.region);
						setTownsChantier(addresses.map((address) => address.town));
						setStreetsChantier(addresses.map((address) => address.street));
					} else {
						setShowNoAddressFoundChantier(true);
					}
				} else if (response.status === 404) {
					// setIsLoading(false);
					// setIsFound(false);
				}
			})
			.catch(function (error) {
				console.error("Erreur lors de la requête :", error);
			});
	};

	const handlePostalCodeChangeFacturation = (event) => {
		setNewAddressFacturation(false);
		const PC = event.target.value;
		if (PC === "" || PC.length < 4) {
			setPostalCodeFacturation("");
			setTownFacturation("");
			setCountryFacturation("");
			setRegionFacturation("");
			setTownsFacturation([]);
			setStreetsFacturation([]);
			setShowNoAddressFoundFacturation(false);
			setShowPostalCodeErrorFacturation(false);

			if (PC.length < 4) {
				setShowPostalCodeErrorFacturation(true);
			}
			return; // Sortez de la fonction pour éviter l'appel à l'API avec un code postal vide
		}
		// Supprimez les caractères supplémentaires du code postal
		const trimmedPostalCode = PC.slice(0, 4);
		// Mettez à jour l'état avec le code postal tronqué

		setPostalCodeFacturation(trimmedPostalCode);
		setShowNoAddressFoundFacturation(false);
		setShowPostalCodeErrorFacturation(false);

		let queryParams = {
			type: "create_account",
			country_code: countryCodeFacturation,
			//keywords: encodeURIComponent(postalCode)
			keywords: encodeURIComponent(trimmedPostalCode),
		};
		let url = ApiConstants.baseUrlRequest + ApiConstants.getAdresse;
		axios
			.get(url, { params: queryParams })
			.then((response) => {
				if (response.status === 200) {
					const data = response.data;
					if (data && data.adresses && data.adresses.length > 0) {
						setAdresseFacturation(data.adresses);
						const addresses = data.adresses;
						const firstAddress = addresses[0];

						setPostalCodeFacturation(trimmedPostalCode);
						setTownFacturation(firstAddress.town);
						setStreetFacturation(firstAddress.street);
						setCountryFacturation(firstAddress.country);
						setRegionFacturation(firstAddress.region);
						setTownsFacturation(addresses.map((address) => address.town));
						setStreetsFacturation(addresses.map((address) => address.street));
					} else {
						setShowNoAddressFoundFacturation(true);
					}
				} else if (response.status === 404) {
					// setIsLoading(false);
					// setIsFound(false);
				}
			})
			.catch(function (error) {
				console.error("Erreur lors de la requête :", error);
			});
	};

	const handleStreetChange = (option, { action }) => {
		if (action === "select-option") {
			const street = option.value;
			setStreet(street);
			if (street === "Autre") {
				setNewAddress(true);
				setStreet("");
			} else {
				setNewAddress(false);
				setStreet(street);
			}
		}
	};

	const handleStreetChangeChantier = (option, { action }) => {
		if (action === "select-option") {
			const street = option.value;
			setStreetChantier(street);
			if (street === "Autre") {
				setNewAddressChantier(true);
				setStreetChantier("");
			} else {
				setNewAddressChantier(false);
				setStreetChantier(street);
			}
		}
	};

	const handleStreetChangeFacturation = (option, { action }) => {
		if (action === "select-option") {
			const street = option.value;
			setStreetFacturation(street);
			if (street === "Autre") {
				setNewAddressFacturation(true);
				setStreetFacturation("");
			} else {
				setNewAddressFacturation(false);
				setStreetFacturation(street);
			}
		}
	};

	const filterOptions = (option, inputValue) => {
		if (typeof inputValue !== "string") {
			return true;
		}

		const street = option.value.toLowerCase();
		const searchValue = inputValue.toLowerCase();

		if (street.includes(searchValue)) {
			setIsStreetFound(true);
			return true;
		}

		// Check if there are no matching options and the current option is "Autres"
		if (option.value === "Autre") {
			setIsStreetFound(false);
			return true;
		}

		return false;
	};

	const filterOptionsChantier = (option, inputValue) => {
		if (typeof inputValue !== "string") {
			return true;
		}

		const street = option.value.toLowerCase();
		const searchValue = inputValue.toLowerCase();

		if (street.includes(searchValue)) {
			setIsStreetFoundChantier(true);
			return true;
		}

		// Check if there are no matching options and the current option is "Autres"
		if (option.value === "Autre") {
			setIsStreetFoundChantier(false);
			return true;
		}

		return false;
	};

	const handleGetUser = () => {
		const userData = JSON.parse(localStorage.getItem("userData"));
		//props.handleLogin();
		// setConnected(true)
	};

	const handleClick = () => {
		history.push({
			pathname: "/home",
		});
	};

	const filterOptionsFacturation = (option, inputValue) => {
		if (typeof inputValue !== "string") {
			return true;
		}

		const street = option.value.toLowerCase();
		const searchValue = inputValue.toLowerCase();

		if (street.includes(searchValue)) {
			setIsStreetFoundFacturation(true);
			return true;
		}

		// Check if there are no matching options and the current option is "Autres"
		if (option.value === "Autre") {
			setIsStreetFoundFacturation(false);
			return true;
		}

		return false;
	};

	let streetOptions = [];
	if (streets.length > 0) {
		streetOptions = streets.map((street) => ({
			value: street,
			label: street,
		}));
		streetOptions.unshift({ value: "Autre", label: "Autre" });
	}

	let townsOptions = [];
	if (towns.length > 0) {
		townsOptions = [...new Set(towns)].map((town) => ({
			value: town,
			label: town,
		}));
	}

	let streetOptionsChantier = [];
	if (streetsChantier.length > 0) {
		streetOptionsChantier = streetsChantier.map((street) => ({
			value: street,
			label: street,
		}));
		streetOptionsChantier.unshift({ value: "Autre", label: "Autre" });
	}

	let townsOptionsChantier = [];
	if (townsChantier.length > 0) {
		townsOptionsChantier = [...new Set(townsChantier)].map((town) => ({
			value: town,
			label: town,
		}));
	}

	let streetOptionsFacturation = [];
	if (streetsFacturation.length > 0) {
		streetOptionsFacturation = streetsFacturation.map((street) => ({
			value: street,
			label: street,
		}));
		streetOptionsFacturation.unshift({ value: "Autre", label: "Autre" });
	}

	let townsOptionsFacturation = [];
	if (townsFacturation.length > 0) {
		townsOptionsFacturation = [...new Set(townsFacturation)].map((town) => ({
			value: town,
			label: town,
		}));
	}

	return (
		<div id="free_devis_home_page" style={{ visibility: "hidden" }}>
			{/* WEBSITE HEADER */}
			<div className="modal_custom" />
			{/* MODAL part */}
			<div className="commun_modal all_modal_box">
				<div className="box_title">{/* Titre de la modal */}</div>
				<div className="commun_modal_msg">{/* Message de la modal */}</div>
			</div>
			<div className="commun_modal_tooltips all_modal_box">
				<div className="box_title">{/* Titre de la modal */}</div>
				<div className="commun_modal_tooltips_in">
					<div className="commun_modal_tooltips_msg">
						{/* Message de la modal */}
					</div>
					<div className="commun_modal_tooltips_fade" />
				</div>
			</div>
			<div className="commun_modal_order_by_ammount all_modal_box">
				<div className="box_title box_title_white">Montant souhaité ?</div>
				<div className="commun_modal_msg_free_ammount">
					(Minimum 200 EUR)
					<div className="input_row input_row_compact">
						<label htmlFor="order_qty_produit_alternative_by_price">
							Montant *
						</label>
						<input
							id="order_qty_produit_alternative_by_price"
							name="order_qty_produit_alternative_by_price"
							type="text"
							autoComplete="something-new"
						/>
						<div className="clear" />
					</div>
					<div className="btn_container_right_margin_top">
						<input
							type="button"
							id="order_qty_produit_alternative_by_price_update"
							className="btn_margin_top"
							defaultValue="Valider"
						/>
					</div>
				</div>
			</div>
			<div className="commun_modal_free_ammount all_modal_box">
				<div className="box_title box_title_white">Montant libre</div>
				<div className="commun_modal_msg_free_ammount">
					Un produit ou un service n'existe pas dans le système, pas de souci,
					ajouter un montant libre au panier en complétant ces 2 champs
					<div className="input_row input_row_compact">
						<label htmlFor="free_amount_label">Dénomination *</label>
						<input
							id="free_amount_label"
							name="free_amount_label"
							type="text"
							autoComplete="something-new"
						/>
						<div className="clear" />
					</div>
					<div className="input_row input_row_compact">
						<label htmlFor="free_amount">Montant *</label>
						<input
							id="free_amount"
							name="free_amount"
							type="text"
							autoComplete="something-new"
						/>
						<div className="clear" />
					</div>
					<div className="btn_container_right_margin_top">
						<input
							type="button"
							id="free_amount_create_product"
							className="btn_margin_top"
							defaultValue="Valider"
						/>
					</div>
				</div>
			</div>
			<div className="agenda_modal_loading all_modal_box" />

			<div className="login_box_container all_modal_box">
				<div id="login_box">
					<div className="box_title">Connectez-vous</div>
					<div className="group">
						<input data-role="none" type="text" id="mem_login" required="" />
						<span className="highlight" />
						<span className="bar" />
						<label data-role="none">Adresse email</label>
						<div className="clear" />
					</div>
					<p id="login_error_mail" style={{ color: "red", display: "none" }}>
						{" "}
						adresse mail introuvable
					</p>

					<div className="btn_container_right_margin_top">
						<input
							type="button"
							id="login_from_website"
							className="btn_margin_top"
							defaultValue="Connexion"
						/>
					</div>
					<div className="clear" />
					<input
						type="button"
						id="create_new_account"
						className="btn_mini"
						defaultValue="Nouveau, créer un compte"
					/>

					<div className="clear" />
				</div>
			</div>

			<div className="verify_box_container all_modal_box">
				<div id="verify_box">
					<div id className="box_title">
						Confirmation de votre OTP
					</div>
					<div className="group">
						<input data-role="none" type="text" id="mem_otp" required="" />
						<span className="highlight" />
						<span className="bar" />
						<label data-role="none">OTP</label>
						<div className="clear" />
					</div>

					<p id="verify_error_otp" style={{ color: "red", display: "none" }}>
						{" "}
						OTP incorrecte
					</p>
					<p
						id="verify_error_otp_expired"
						style={{ color: "red", display: "none" }}
					>
						{" "}
						OTP expiré
					</p>

					<input
						type="button"
						id="verify_account"
						className="btn_mini"
						defaultValue="Vérifier"
						onClick={handleGetUser}
					/>

					<div className="clear" />
				</div>
			</div>

			<div className="create_account_box_container all_modal_box">
				<div className="box_title box_title_white">Créer votre compte</div>
				<div className="create_account_box_container_col">
					<div className="input_row input_row_compact">
						<label htmlFor="NEW_ACC_member_label">Titre *</label>
						<select
							id="NEW_ACC_member_label"
							name="NEW_ACC_member_label"
							autoComplete="something-new"
						>
							<option value={0}>Mme</option>
							<option value={1}>Mr</option>
						</select>
						<div className="clear" />
					</div>
					<div className="input_row input_row_compact">
						<label htmlFor="NEW_ACC_member_nom">Nom *</label>
						<input
							id="NEW_ACC_member_nom"
							name="NEW_ACC_member_nom"
							type="text"
							autoComplete="something-new"
							placeholder="Votre nom"
						/>
						<div className="clear" />
					</div>
					<div className="input_row input_row_compact">
						<label htmlFor="NEW_ACC_member_prenom">Prénom *</label>
						<input
							id="NEW_ACC_member_prenom"
							name="NEW_ACC_member_prenom"
							type="text"
							autoComplete="something-new"
							placeholder="Votre prénom"
						/>
						<div className="clear" />
						<div id="possible_nom_prenom_duplicate" />
					</div>
					<div
						className="input_row input_row_compact"
						id="NEW_ACC_member_tva_bloc"
					>
						<label htmlFor="NEW_ACC_member_tva">Numéro de TVA</label>
						<input
							type="text"
							id="NEW_ACC_member_tva"
							name="NEW_ACC_member_tva"
							placeholder="Votre numéro de TVA"
						/>
						<div className="clear" />
					</div>
					<div className="input_row input_row_compact">
						<label htmlFor="NEW_ACC_member_email">Adresse e-mail *</label>
						<Row>
							<Col sm="12" lg="9" className="mb-2">
								<input
								
									id="NEW_ACC_member_email"
									name="NEW_ACC_member_email"
									type="text"
									autoComplete="something-new"
									onChange={(value) => {
										setIsOtpEmailVerified(false);
										setEmail(value.target.value);
									}}
									placeholder="Votre adresse e-mail"
								/>
							</Col>
							<Col sm="12" lg="3" className="mb-2">
								{!isOtpEmailVerified ? (
									<Button
										variant="primary"
										className="btn-fill btn-fill2 btn-sm"
										onClick={(e) => {
											e.preventDefault();
											setOtpEmailSpinnerIsLoading(true);
											handleEmailVerificationSubmit();
										}}
										disabled={
											!validator.isEmail(email) ||
											(auth.user !== null && auth.user.type === "partenaire")
										}
									>
										<div className="d-flex justify-content-between align-items-center">
											<span className="me-2 text-verify">Vérifier</span>
											<Spinner
												color="#8EA841"
												size={10}
												speed={1}
												animating={otpEmailSpinnerIsLoading}
											/>
										</div>
									</Button>
								) : (
									<Button
										variant="primary"
										className="btn-fill btn-sm"
										onClick={(e) => {
											e.preventDefault();
										}}
									>
										<MdVerifiedUser size={25} />
									</Button>
								)}
							</Col>
						</Row>

						<p style={{ color: "red" }}>{otpEmailErrorMessage}</p>

						<div className="clear" />
					</div>
					<div className="input_row input_row_compact">
						<label htmlFor="NEW_ACC_member_gsm">
							Téléphone mobile *{" "}
							<span style={{ fontSize: 11 }}>(ex: 0485123456)</span>
						</label>

						<Row>
							<Col sm="12" lg="9" className="mb-2">
								<PhoneInput
									international
									defaultCountry="BE"
									countryCallingCodeEditable={false}
									// value={phoneNumber}
									onChange={(value) => {
										handlePhoneNumberChange(value);
									}}
									style={{ display: "inline-flex" }}
								/>
								
							</Col>
							<Col sm="12" lg="3" className="mb-2">
								{!isOtpPhoneVerified ? (
									<Button
										variant="primary"
										className="btn-fill btn-fill2 btn-sm"
										onClick={(e) => {
											e.preventDefault();
											setOtpPhoneSpinnerIsLoading(true);
											handlePhoneVerificationSubmit();
										}}
										disabled={
											!isPossibleNumber ||
											phoneNumber === "" ||
											(auth.user !== null && auth.user.type === "partenaire")
										}
									>
										<div className="d-flex justify-content-between align-items-center">
											<span className="me-2 text-verify">Vérifier</span>
											<Spinner
												color="#8EA841"
												size={10}
												speed={1}
												animating={otpPhoneSpinnerIsLoading}
											/>
										</div>
									</Button>
								) : (
									<Button
										variant="primary"
										className="btn-fill btn-sm"
										onClick={(e) => {
											e.preventDefault();
										}}
									>
										<MdVerifiedUser size={25} />
									</Button>
								)}
							</Col>
						</Row>

						{!isPossibleNumber && (
							<p style={{ color: "red" }}>
								Saisissez un numéro de téléphone valide
							</p>
						)}
						<p style={{ color: "red" }}>{otpPhoneErrorMessage}</p>

						<input
							type="hidden"
							name="NEW_ACC_member_gsm"
							id="NEW_ACC_member_gsm"
							defaultValue={phoneNumber}
						/>
						<div className="clear" />
					</div>
					<div className="input_row input_row_compact">
						<label htmlFor="NEW_ACC_member_gsm2">
							Numéro WHATSAPP*{" "}
							<span style={{ fontSize: 11 }}>(ex: 0485123456)</span>
						</label>

						<Row>
							<Col sm="12" lg="9" className="mb-2">
								<PhoneInput
									value={phoneNumber}
									international
									defaultCountry="BE"
									countryCallingCodeEditable={false}
									// value={phoneNumber}
									onChange={(value) => handlePhoneNumberChange(value, true)} // Use the bound method
									style={{ display: "inline-flex" }}
								/>
							</Col>
							<Col sm="12" lg="3" className="mb-2">
								<Button
									variant="primary"
									className="btn-fill btn-fill2 btn-sm"
									disabled={true}
								>
									<div className="d-flex justify-content-between align-items-center">
										<span className="me-2 text-verify">Vérifier</span>
										<Spinner
											color="#fff"
											size={10}
											speed={1}
											animating={otpWhatsappSpinnerIsLoading}
										/>
									</div>
								</Button>
							</Col>
						</Row>

						{!isPossibleWhatsappNumber && (
							<p style={{ color: "red" }} className="mb-0">
								Saisissez un numéro de téléphone valide
							</p>
						)}
						<p style={{ color: "red" }}>{otpWhatsappErrorMessage}</p>
						<input
							type="hidden"
							name="NEW_ACC_member_whatsapp_gsm2"
							id="NEW_ACC_member_whatsapp_gsm2"
							defaultValue={phoneWhatsappNumber}
						/>
						<div className="clear" />
					</div>
				</div>
				<div className="create_account_box_container_col_right">
					<div className="input_row input_row_compact">
						<input
							type="hidden"
							name="NEW_ACC_address_new"
							id="NEW_ACC_address_new"
							defaultValue={newAddress}
						/>
						<label>Pays *</label>

						<Select
							autoComplete="something-new"
							placeholder='Votre pays'
							isSearchable
							style={{ marginBottom: 5 }}
							styles={customStyleForReactSelect}
							options={[
								{
									value: "",
									label: "Votre pays",
								},
								{
									value: "BE",
									label: "Belgique",
								},
								{
									value: "LU",
									label: "Luxembourg",
								},
							]}
							onChange={handleCountryChange}
						/>
						<input
							type="hidden"
							name="pays_NEW_ACC"
							id="pays_NEW_ACC"
							defaultValue={countryCode}
						/>
						<input
							type="hidden"
							name="nom_pays_NEW_ACC"
							id="nom_pays_NEW_ACC"
							defaultValue={country}
						/>
						{/* <input id="member_cp" style="margin-bottom:5px;" name="member_cp" type="text" autocomplete="something-new" placeholder="'.return_lng('form_base_adresse_postale_cp', $lng).'">
            <input id="member_city" style="margin-bottom:5px;" name="member_city" type="text" autocomplete="something-new" placeholder="'.return_lng('form_base_adresse_postale_ville', $lng).'"> */}
					</div>
					<div className="input_row input_row_compact">
						<label htmlFor="cp_NEW_ACC">Code postal *</label>
						<input
							id="cp_NEW_ACC"
							name="cp_NEW_ACC"
							type="text"
							autoComplete="something-new"
							onChange={handlePostalCodeChange}
							placeholder="Votre code postal"
						/>
						{showPostalCodeError && (
							<p style={{ color: "red" }}>
								Le code postal doit contenir au moins 4 caractères.
							</p>
						)}
						{showNoAddressFound && (
							<p style={{ color: "red" }}>
								le code postale saisie n’existe pas,veuillez entrer un code
								postal valide.
							</p>
						)}
						<div className="clear" />
					</div>
					<div className="input_row input_row_compact">
						<label>Ville *</label>
						<Select
							style={{ marginBottom: 5 }}
							styles={customStyleForReactSelect}
							// style={{ marginBottom: 5 }}
							autoComplete="something-new"
							placeholder="Votre Ville"
							isSearchable
							options={townsOptions}
							onChange={handleTownChange}
						/>
						<input
							type="hidden"
							name="NEW_ACC_member_address_town"
							id="NEW_ACC_member_address_town"
							defaultValue={town}
						/>
						<div className="clear" />
					</div>
					<div className="input_row input_row_compact">
						<label htmlFor="NEW_ACC_member_address_street">Rue *</label>
						{!isStreetFound && (
							<p style={{ color: "red" }}>
								Votre rue n'a pas été trouvé dans notre base de donnée.
							</p>
						)}
						{newAddress ? (
							<input
								id="NEW_ACC_member_address_street"
								style={{ marginBottom: 5 }}
								name="NEW_ACC_member_address_street"
								type="text"
								autoComplete="something-new"
								placeholder="Votre rue"
							/>
						) : (
							<div>
								<Select
									styles={customStyleForReactSelect}
									// style={{ marginBottom: 5 }}
									autoComplete="something-new"
									placeholder="Votre rue"
									isSearchable
									options={streetOptions}
									filterOption={filterOptions}
									onChange={handleStreetChange}
								/>
								<input
									type="hidden"
									name="NEW_ACC_member_address_street"
									id="NEW_ACC_member_address_street"
									defaultValue={street}
								/>
							</div>
						)}
						<div className="clear" />
					</div>
					<div className="input_row input_row_compact">
						<label htmlFor="NEW_ACC_member_address_number_street">
							Numéro de la rue *
						</label>
						<input
							id="NEW_ACC_member_address_number_street"
							style={{ marginBottom: 5 }}
							name="NEW_ACC_member_address_number_street"
							type="text"
							autoComplete="something-new"
							placeholder="Votre numéro de rue"
						/>
						<div className="clear" />
					</div>
					<div className="input_row input_row_compact">
						<label htmlFor="NEW_ACC_member_address_box">Boîte postale</label>
						<input
							id="NEW_ACC_member_address_box"
							style={{ marginBottom: 5 }}
							name="NEW_ACC_member_address_box"
							type="text"
							autoComplete="something-new"
							placeholder="Votre boîte postale"
						/>
						<div className="clear" />
					</div>
					<div className="input_row input_row_compact">
						<label htmlFor="NEW_ACC_member_address_region">Région *</label>

						<input
							id="NEW_ACC_member_address_region"
							style={{ marginBottom: 5 }}
							name="NEW_ACC_member_address_region"
							type="text"
							autoComplete="something-new"
							disabled
							defaultValue={region}
							placeholder="Votre région"
						/>

						<input
							id="cp_ID_NEW_ACC"
							name="cp_ID_NEW_ACC"
							type="hidden"
							defaultValue={0}
						/>
						<div className="clear" />
					</div>

					<div className="input_row input_row_compact">
						<div id="co_res_NEW_ACC" />
						<div className="input_comment">
							<span>
								Si besoin, vous aurez la possibilité de créer une adresse de
								facturation différentes à l'étape suivante...
							</span>
						</div>
						<div className="clear" />
						<div className="clear" />
					</div>
				</div>
				<div className="clear" />
				<div className="input_row">
					<div className="hide_on_anonym">
						<input
							id="NEW_ACC_member_newsletter"
							type="checkbox"
							defaultValue={1}
							name="NEW_ACC_member_newsletter"
						/>
						<label
							className="checkbox_line_height full_width"
							htmlFor="NEW_ACC_member_newsletter"
						>
							Désirez-vous recevoir nos newsletters ?
						</label>
					</div>
					<input
						id="NEW_ACC_member_agreed"
						type="checkbox"
						defaultValue={1}
						name="NEW_ACC_member_agreed"
					/>
					<label
						className="checkbox_line_height full_width"
						htmlFor="NEW_ACC_member_agreed"
					>
						Accepter nos conditions d'utilisation &amp; politique de
						confidentialité
					</label>
					<div className="clear" />
					<div className="input_comment">
						<span>Lire nos </span>
						<a href="#" onClick={(e) => e.preventDefault()}>
							conditions d'utilisation
						</a>{" "}
						<span>ou/et </span>
						<a href="#" onClick={(e) => e.preventDefault()}>
							politique de confidentialité
						</a>
						<span> dans une nouvelle page ?</span>
					</div>
				</div>
				<input
					type="hidden"
					name="NEW_ACC_verified_email"
					id="NEW_ACC_verified_email"
					defaultValue={isOtpEmailVerified}
				/>
				<input
					type="hidden"
					name="NEW_ACC_verified_phone_number"
					id="NEW_ACC_verified_phone_number"
					defaultValue={isOtpPhoneVerified}
				/>
				<input
					type="hidden"
					name="NEW_ACC_verified_whatsapp_phone_number"
					id="NEW_ACC_verified_whatsapp_phone_number"
					defaultValue={isOtpWhatsappVerified}
				/>
				<input
					type="hidden"
					name="NEW_ACC_user_type"
					id="NEW_ACC_user_type"
					defaultValue={user !== null ? user.type : ""}
				/>
				<div className="btn_container_right_margin_top">
					<input
						type="button"
						id="create_account_go"
						className="btn_margin_top btn_valid"
						defaultValue="Valider"
					// disabled={!((auth.user !== null && auth.user.type === "partenaire"))}
					/>
				</div>
				<div className="clear" />
			</div>
			<div className="create_delivery_address_box_container all_modal_box">
				<div className="box_title box_title_white">
					Adresse du chantier et personne de contact
				</div>
				<input type="hidden" id="tank_type_in_devis" />
				<input type="hidden" id="tank_materiau_in_devis" />
				<input type="hidden" id="tank_capacity_in_devis" />
				<input type="hidden" id="tank_age_in_devis" />
				<input type="hidden" id="tank_sys_anti_debord_in_devis" />
				<input type="hidden" id="tank_event_location_in_devis" />
				<div className="create_account_box_container_col">
					<div
						className="input_row input_row_compact"
						style={{ display: "none" }}
					>
						<label htmlFor="tank_delivery_driver_order_in_devis">
							Le chauffeur doit se présenter ?
						</label>
						<select
							id="tank_delivery_driver_order_in_devis"
							name="tank_delivery_driver_order_in_devis"
						>
							<option value="yes_before">Oui, avant de livrer</option>
							<option value="yes_after">Oui, après avoir livré</option>
							<option value="no">Non</option>
						</select>
						<div className="clear" />
					</div>
					<div className="input_row input_row_compact">
						<label htmlFor="contact_tank_in_devis">Personne de contact *</label>
						<input
							type="text"
							id="contact_tank_in_devis"
							name="contact_tank_in_devis"
							placeholder="Nom(s) et prénom(s)"
						/>
						<div className="clear" />
					</div>

					<div className="input_row input_row_compact">
						<label htmlFor="tel_tank_in_devis">
							Téléphone mobile *{" "}
							<span style={{ fontSize: 11 }}>(ex: 0485123456)</span>
						</label>

						<Row>
							<Col sm="12" lg="9" className="mb-2">
								<PhoneInput
									international
									defaultCountry="BE"
									countryCallingCodeEditable={false}
									// value={phoneNumber}
									onChange={(value) => {
										handlePhoneNumberChangeChantier(value);
									}}
									style={{ display: "inline-flex" }}
								/>
							</Col>
							<Col sm="12" lg="3" className="mb-2">
								{!isOtpPhoneVerifiedChantier ? (
									<Button
										variant="primary"
										className="btn-fill btn-fill2 btn-sm"
										onClick={(e) => {
											e.preventDefault();
											setOtpPhoneSpinnerIsLoadingChantier(true);
											handlePhoneVerificationSubmitChantier();
										}}
										disabled={
											!isPossibleNumberChantier ||
											phoneNumberChantier === "" ||
											(auth.user !== null && auth.user.type === "partenaire")
										}
									>
										<div className="d-flex justify-content-between align-items-center">
											<span className="me-2 text-verify">Vérifier</span>
											<Spinner
												color="#8EA841"
												size={10}
												speed={1}
												animating={otpPhoneSpinnerIsLoadingChantier}
											/>
										</div>
									</Button>
								) : (
									<Button
										variant="primary"
										className="btn-fill btn-sm"
										onClick={(e) => {
											e.preventDefault();
										}}
									>
										<MdVerifiedUser size={25} />
									</Button>
								)}
							</Col>
						</Row>

						{!isPossibleNumberChantier && (
							<p style={{ color: "red" }}>
								Saisissez un numéro de téléphone valide
							</p>
						)}
						<p style={{ color: "red" }}>{otpPhoneErrorMessageChantier}</p>
						<input
							type="hidden"
							name="tel_tank_in_devis"
							id="tel_tank_in_devis"
							defaultValue={phoneNumberChantier}
						/>
						<div className="clear" />
					</div>
					<div className="input_row input_row_compact">
						<label htmlFor="tel_tank_in_devis">
							Numéro WHATSAPP*{" "}
							<span style={{ fontSize: 11 }}>(ex: 0485123456)</span>
						</label>

						<Row>
							<Col sm="12" lg="9" className="mb-2">
								<PhoneInput
									value={phoneNumberChantier}
									international
									defaultCountry="BE"
									countryCallingCodeEditable={false}
									// value={phoneNumber}
									onChange={(value) =>
										handlePhoneNumberChangeChantier(value, true)
									} // Use the bound method
									style={{ display: "inline-flex" }}
								/>
							</Col>
							<Col sm="12" lg="3" className="mb-2">
								<Button
									variant="primary"
									className="btn-fill btn-fill2 btn-sm"
									disabled={true}
								>
									<div className="d-flex justify-content-between align-items-center">
										<span className="me-2 text-verify">Vérifier</span>
										<Spinner
											color="#8EA841"
											size={10}
											speed={1}
											animating={otpWhatsappSpinnerIsLoadingChantier}
										/>
									</div>
								</Button>
							</Col>
						</Row>

						{!isPossibleWhatsappNumberChantier && (
							<p style={{ color: "red" }}>
								Saisissez un numéro de téléphone valide
							</p>
						)}
						<p style={{ color: "red" }}>{otpWhatsappErrorMessageChantier}</p>
						<input
							type="hidden"
							name="tel_whatsapp_tank_in_devis"
							id="tel_whatsapp_tank_in_devis"
							defaultValue={phoneWhatsappNumberChantier}
						/>
						<div className="clear" />
					</div>
				</div>
				<div className="create_account_box_container_col_right">
					<div className="input_row input_row_compact">
						<input
							type="hidden"
							name="pays_tank_in_devis"
							id="pays_tank_in_devis"
							defaultValue={countryCodeChantier}
						/>
						<input
							type="hidden"
							name="nom_pays_tank_in_devis"
							id="nom_pays_tank_in_devis"
							defaultValue={countryChantier}
						/>
						<label> Pays *</label>
						<Select
							styles={customStyleForReactSelect}
							autoComplete="something-new"
							placeholder='Selectionner un pays'
							isSearchable
							style={{ marginBottom: 5 }}
							options={[
								{
									value: "",
									label: "Selectionner un pays",
								},
								{
									value: "BE",
									label: "Belgique",
								},
								{
									value: "LU",
									label: "Luxembourg",
								},
							]}
							onChange={handleCountryChangeChantier}
						// filterOption={filterOptions}
						/>
						<div className="clear" />
					</div>
					<div className="input_row input_row_compact">
						<label htmlFor="cp_tank_in_devis">Code postal *</label>
						<input
							id="cp_tank_in_devis"
							name="cp_tank_in_devis"
							type="text"
							autoComplete="something-new"
							onChange={handlePostalCodeChangeChantier}
							placeholder="Entrer un code postal"
						/>
						{showPostalCodeErrorChantier && (
							<p style={{ color: "red" }}>
								Le code postal doit contenir au moins 4 caractères.
							</p>
						)}
						{showNoAddressFoundChantier && (
							<p style={{ color: "red" }}>
								le code postale saisie n’existe pas,veuillez entrer un code
								postal valide.
							</p>
						)}
						<div className="clear" />
					</div>
					<div className="input_row input_row_compact">
						<label>Ville *</label>
						<Select
							style={{ marginBottom: 5 }}
							styles={customStyleForReactSelect}
							autoComplete="something-new"
							placeholder="Choisir une ville"
							isSearchable
							options={townsOptionsChantier}
							onChange={handleTownChangeChantier}
						/>

						<input
							type="hidden"
							name="town_tank_in_devis"
							id="town_tank_in_devis"
							defaultValue={townChantier}
						/>
						<div className="clear" />
					</div>
					<div className="input_row input_row_compact">
						<label htmlFor="town_tank_in_devis">Rue *</label>
						{!isStreetFoundChantier && (
							<p style={{ color: "red" }}>
								Votre rue n'a pas été trouvé dans notre base de donnée.
							</p>
						)}
						{newAddressChantier ? (
							<input
								id="street_tank_in_devis"
								style={{ marginBottom: 5 }}
								name="street_tank_in_devis"
								type="text"
								autoComplete="something-new"
								placeholder="Entrer une rue"
							/>
						) : (
							<div>
								<Select
									styles={customStyleForReactSelect}
									autoComplete="something-new"
									placeholder="Choisir une rue"
									isSearchable
									options={streetOptionsChantier}
									filterOption={filterOptionsChantier}
									onChange={handleStreetChangeChantier}
								/>
								<input
									type="hidden"
									name="street_tank_in_devis"
									id="street_tank_in_devis"
									defaultValue={streetChantier}
								/>
							</div>
						)}
						<div className="clear" />
					</div>
					<div className="input_row input_row_compact">
						<label htmlFor="street_number_tank_in_devis">
							Numéro de la rue *
						</label>
						<input
							id="street_number_tank_in_devis"
							style={{ marginBottom: 5 }}
							name="street_number_tank_in_devis"
							type="text"
							autoComplete="something-new"
							placeholder="Entrer un numéro de rue"
						/>
						<div className="clear" />
					</div>
					<div className="input_row input_row_compact">
						<label htmlFor="box_tank_in_devis">Boîte Postale </label>
						<input
							id="box_tank_in_devis"
							style={{ marginBottom: 5 }}
							name="box_tank_in_devis"
							type="text"
							autoComplete="something-new"
							placeholder="Entrer une boîte postale"
						/>
						<div className="clear" />
					</div>
					<div className="input_row input_row_compact">
						<label htmlFor="town_tank_in_devis">Région *</label>
						<input
							id="town_tank_in_devis"
							style={{ marginBottom: 5 }}
							name="town_tank_in_devis"
							type="text"
							autoComplete="something-new"
							disabled
							defaultValue={regionChantier}
						/>
						<input
							id="cp_ID_tank_in_devis"
							name="cp_ID_tank_in_devis"
							type="hidden"
							defaultValue={5}
						/>
						<div className="clear" />
					</div>
				</div>
				<div id="co_res_tank_in_devis" />
				<div className="clear" />
				<div
					className="btn_container_right_margin_top"
					style={{
						borderTop: "1px solid #cccccc",
						marginTop: 10,
						paddingTop: 10,
					}}
				>
					<input
						type="hidden"
						name="tel_verified_tank_in_devis"
						id="tel_verified_tank_in_devis"
						defaultValue={isOtpPhoneVerifiedChantier}
					/>
					<input
						type="hidden"
						name="tel_whatsapp_verified_tank_in_devis"
						id="tel_whatsapp_verified_tank_in_devis"
						defaultValue={isOtpWhatsappVerifiedChantier}
					/>
					<input
						type="hidden"
						name="user_type_tank_in_devis"
						id="user_type_tank_in_devis"
						defaultValue={user !== null ? user.type : ""}
					/>
					<input
						id="add_tank_in_devis"
						type="button"
						className="btn_margin_top btn_valid"
						defaultValue="Valider"
						// disabled={
						//   !(isOtpPhoneVerifiedChantier || isOtpWhatsappVerifiedChantier)
						// }
						onClick={(e) => {
							//   setIsOtpPhoneVerifiedChantier(false);
							//   setIsOtpWhatsappVerifiedChantier(false);
						}}
					/>
				</div>
				<div className="clear" />
			</div>
			<div className="create_invoicing_address_box_container all_modal_box">
				<div className="box_title box_title_white">Adresse de facturation</div>
				<div className="create_account_box_container_col">
					<div className="input_row input_row_compact">
						<label htmlFor="inv_add_has_tva_in_devis">
							Assujetit à la TVA ?
						</label>
						<select
							id="inv_add_has_tva_in_devis"
							name="inv_add_has_tva_in_devis"
						>
							<option value="yes">Oui</option>
							<option value="no">Non</option>
						</select>
						<div className="clear" />
					</div>
					<div className="input_row input_row_compact">
						<span>
							Si, oui indiquer un numéro de TVA et un nom d'entreprise
						</span>
					</div>
					<div className="input_row input_row_compact">
						<label htmlFor="inv_add_num_tva_in_devis">Numéro de TVA *</label>
						<input
							type="text"
							id="inv_add_num_tva_in_devis"
							name="inv_add_num_tva_in_devis"
							placeholder="Entrer un numéro de TVA"
						/>
						<div className="clear" />
					</div>
					<div className="input_row input_row_compact">
						<label htmlFor="inv_add_company_name_in_devis">
							Dénomination de l'entreprise *
						</label>
						<input
							type="text"
							id="inv_add_company_name_in_devis"
							name="inv_add_company_name_in_devis"
							placeholder="Entrer une dénomination"
						/>
						<div className="clear" />
					</div>
					<div className="input_row input_row_compact">
						<label htmlFor="inv_add_contact_name_in_devis">
							Personne de contact *
						</label>
						<input
							type="text"
							id="inv_add_contact_name_in_devis"
							name="inv_add_contact_name_in_devis"
							placeholder="Nom(s) et prénom(s)"
						/>
						<div className="clear" />
					</div>
					<div className="input_row input_row_compact">
						<label htmlFor="inv_add_contact_phone_in_devis">
							Téléphone mobile *{" "}
							<span style={{ fontSize: 11 }}>(ex: 0485123456)</span>
						</label>
						<Row>
							<Col sm="12" lg="9" className="mb-2">
								<PhoneInput
									international
									defaultCountry="BE"
									countryCallingCodeEditable={false}
									// value={phoneNumber}
									onChange={(value) => {
										handlePhoneNumberChangeFacturation(value);
									}}
									style={{ display: "inline-flex" }}
								/>
							</Col>
							<Col sm="12" lg="3" className="mb-2">
								{!isOtpPhoneVerifiedFacturation ? (
									<Button
										variant="primary"
										className="btn-fill btn-fill2 btn-sm"
										onClick={(e) => {
											e.preventDefault();
											setOtpPhoneSpinnerIsLoadingFacturation(true);
											//process and call api here. here is some action we can do when api call is correct

											//simulation call time response
											setTimeout(
												handlePhoneVerificationSubmitFacturation,
												1000,
											);
										}}
										disabled={
											!isPossibleNumberFacturation ||
											phoneNumberFacturation === "" ||
											(auth.user !== null && auth.user.type === "partenaire")
										}
									>
										<div className="d-flex justify-content-between align-items-center">
											<span className="me-2 text-verify">Vérifier</span>
											<Spinner
												color="#8EA841"
												size={10}
												speed={1}
												animating={otpPhoneSpinnerIsLoadingFacturation}
											/>
										</div>
									</Button>
								) : (
									<Button
										className="btn-fill btn-sm"
										onClick={(e) => {
											e.preventDefault();
										}}
									>
										<MdVerifiedUser size={25} />
									</Button>
								)}
							</Col>
						</Row>

						{!isPossibleNumberFacturation && (
							<p style={{ color: "red" }}>
								Saisissez un numéro de téléphone valide
							</p>
						)}
						<p style={{ color: "red" }}>{otpPhoneErrorMessageFacturation}</p>
						<input
							type="hidden"
							name="inv_add_contact_phone_in_devis"
							id="inv_add_contact_phone_in_devis"
							defaultValue={phoneNumberFacturation}
						/>
						<div className="clear" />
					</div>
					<div className="input_row input_row_compact">
						<label htmlFor="tel_tank_in_devis">
							Numéro WHATSAPP*{" "}
							<span style={{ fontSize: 11 }}>(ex: 0485123456)</span>
						</label>
						<Row>
							<Col sm="12" lg="9" className="mb-2">
								<PhoneInput
									value={phoneNumberFacturation}
									international
									defaultCountry="BE"
									countryCallingCodeEditable={false}
									// value={phoneNumber}
									onChange={(value) =>
										handlePhoneNumberChangeFacturation(value, true)
									} // Use the bound method
									style={{ display: "inline-flex" }}
								/>
							</Col>
							<Col sm="12" lg="3" className="mb-2">
								<Button className="btn-fill btn-fill2 btn-sm" disabled={true}>
									<div className="d-flex justify-content-between align-items-center">
										<span className="me-2 text-verify">Vérifier</span>
										<Spinner
											color="#8EA841"
											size={10}
											speed={1}
											animating={otpWhatsappSpinnerIsLoadingFacturation}
										/>
									</div>
								</Button>
							</Col>
						</Row>

						{!isPossibleWhatsappNumberFacturation && (
							<p style={{ color: "red" }}>
								Saisissez un numéro de téléphone valide
							</p>
						)}
						<p style={{ color: "red" }}>{otpWhatsappErrorMessageFacturation}</p>
						<input
							type="hidden"
							name="inv_add_contact_whatsapp_phone_in_devis"
							id="inv_add_contact_whatsapp_phone_in_devis"
							defaultValue={phoneWhatsappNumberFacturation}
						/>
						<div className="clear" />
					</div>

					<div className="input_row input_row_compact">
						<label htmlFor="inv_add_contact_mail_in_devis">E-mail *</label>
						<Row>
							<Col sm="12" lg="9" className="mb-2">
								<input
									type="text"
									id="inv_add_contact_mail_in_devis"
									name="inv_add_contact_mail_in_devis"
									autoComplete="something-new"
									onChange={(value) => {
										setIsOtpEmailVerifiedFacturation(false);
										setEmailFacturaction(value.target.value);
									}}
									placeholder="Entrer une adresse e-mail"
								/>
							</Col>
							<Col sm="12" lg="3" className="mb-2">
								{!isOtpEmailVerifiedFacturation ? (
									<Button
										variant="primary"
										className="btn-fill btn-fill2 btn-sm"
										onClick={(e) => {
											e.preventDefault();
											setOtpEmailSpinnerIsLoadingFacturation(true);
											handleEmailVerificationSubmitFacturation();
										}}
										disabled={
											!validator.isEmail(emailFacturaction) ||
											(auth.user !== null && auth.user.type === "partenaire")
										}
									>
										<div className="d-flex justify-content-between align-items-center">
											<span className="me-2 text-verify">Vérifier</span>
											<Spinner
												color="#8EA841"
												size={10}
												speed={1}
												animating={otpEmailSpinnerIsLoadingFacturation}
											/>
										</div>
									</Button>
								) : (
									<Button
										className="btn-fill btn-sm"
										onClick={(e) => {
											e.preventDefault();
										}}
									>
										<MdVerifiedUser size={25} />
									</Button>
								)}
							</Col>
						</Row>

						<p style={{ color: "red" }}>{otpEmailErrorMessageFacturation}</p>
						<div className="clear" />
					</div>
				</div>
				<div className="create_account_box_container_col_right">
					<div className="input_row input_row_compact">
						<input
							type="hidden"
							name="pays_inv_add_in_devis"
							id="pays_inv_add_in_devis"
							defaultValue={countryCodeFacturation}
						/>
						<input
							type="hidden"
							name="nom_pays_inv_add_in_devis"
							id="nom_pays_inv_add_in_devis"
							defaultValue={countryFacturation}
						/>
						<label> Pays *</label>
						<Select
							placeholder='Selectionner un pays'
							styles={customStyleForReactSelect}
							autoComplete="something-new"
							isSearchable
							style={{ marginBottom: 5 }}
							options={[
								{
									value: "",
									label: "Selectionner un pays",
								},
								{
									value: "BE",
									label: "Belgique",
								},
								{
									value: "LU",
									label: "Luxembourg",
								},
							]}
							onChange={handleCountryChangeFacturation}
						/>
						<div className="clear" />
					</div>
					<div className="input_row input_row_compact">
						<label htmlFor="cp_inv_add_in_devis">Code postal *</label>
						<input
							id="cp_inv_add_in_devis"
							name="cp_inv_add_in_devis"
							type="text"
							autoComplete="something-new"
							onChange={handlePostalCodeChangeFacturation}
							placeholder="Entrer un code postal"
						/>
						{showPostalCodeErrorFacturation && (
							<p style={{ color: "red" }}>
								Le code postal doit contenir au moins 4 caractères.
							</p>
						)}
						{showNoAddressFoundFacturation && (
							<p style={{ color: "red" }}>
								le code postale saisie n’existe pas,veuillez entrer un code
								postal valide.
							</p>
						)}
						<div className="clear" />
					</div>
					<div className="input_row input_row_compact">
						<label>Ville *</label>
						<Select
							style={{ marginBottom: 5 }}
							styles={customStyleForReactSelect}
							autoComplete="something-new"
							placeholder="Choisir une ville"
							isSearchable
							options={townsOptionsFacturation}
							onChange={handleTownChangeFacturation}
						/>
						<input
							type="hidden"
							name="address_town_inv_add_in_devis"
							id="address_town_inv_add_in_devis"
							defaultValue={townFacturation}
						/>
						<div className="clear" />
					</div>
					<div className="input_row input_row_compact">
						<label htmlFor="address_street_inv_add_in_devis">Rue *</label>
						{!isStreetFoundFacturation && (
							<p style={{ color: "red" }}>
								Votre rue n'a pas été trouvé dans notre base de donnée.
							</p>
						)}
						{newAddressFacturation ? (
							<input
								id="address_street_inv_add_in_devis"
								style={{ marginBottom: 5 }}
								name="address_street_inv_add_in_devis"
								type="text"
								autoComplete="something-new"
								placeholder="Entrer une rue"
							/>
						) : (
							<div>
								<Select
									// style={{ marginBottom: 5 }}
									styles={customStyleForReactSelect}
									autoComplete="something-new"
									placeholder="Choisir une rue"
									isSearchable
									options={streetOptionsFacturation}
									filterOption={filterOptionsFacturation}
									onChange={handleStreetChangeFacturation}
								/>
								<input
									type="hidden"
									name="address_street_inv_add_in_devis"
									id="address_street_inv_add_in_devis"
									defaultValue={streetFacturation}
								/>
							</div>
						)}
						<div className="clear" />
					</div>
					<div className="input_row input_row_compact">
						<label htmlFor="address_number_street_inv_add_in_devis">
							Numéro de la rue *
						</label>
						<input
							id="address_number_street_inv_add_in_devis"
							style={{ marginBottom: 5 }}
							name="address_number_street_inv_add_in_devis"
							type="text"
							autoComplete="something-new"
							placeholder="Entrer un numéro de rue"
						/>
						<div className="clear" />
					</div>
					<div className="input_row input_row_compact">
						<label htmlFor="address_box_inv_add_in_devis">Boîte postale</label>
						<input
							id="address_box_inv_add_in_devis"
							style={{ marginBottom: 5 }}
							name="address_box_inv_add_in_devis"
							type="text"
							autoComplete="something-new"
							placeholder="Entrer une boîte postale"
						/>
						<div className="clear" />
					</div>
					<div className="input_row input_row_compact">
						<label htmlFor="address_town_inv_add_in_devis">Région *</label>
						<input
							id="address_town_inv_add_in_devis"
							style={{ marginBottom: 5 }}
							name="address_town_inv_add_in_devis"
							type="text"
							autoComplete="something-new"
							defaultValue={regionFacturation}
						/>

						<input
							id="cp_ID_inv_add_in_devis"
							name="cp_ID_inv_add_in_devis"
							type="hidden"
							defaultValue={5}
						/>
						<div className="clear" />
					</div>
				</div>
				<div id="co_res_inv_add_in_devis" />
				<div className="clear" />
				<div
					className="btn_container_right_margin_top"
					style={{
						borderTop: "1px solid #cccccc",
						marginTop: 10,
						paddingTop: 10,
					}}
				>

					<input
						type="hidden"
						name="inv_add_contact_mail_verified_in_devis"
						id="inv_add_contact_mail_verified_in_devis"
						defaultValue={isOtpEmailVerifiedFacturation}
					/>
					<input
						type="hidden"
						name="inv_add_contact_phone_verified_in_devis"
						id="inv_add_contact_phone_verified_in_devis"
						defaultValue={isOtpPhoneVerifiedFacturation}
					/>
					<input
						type="hidden"
						name="inv_add_contact_phone_whatsapp_verified_in_devis"
						id="inv_add_contact_phone_whatsapp_verified_in_devis"
						defaultValue={isOtpWhatsappVerifiedFacturation}
					/>
					<input
						type="hidden"
						name="inv_add_user_type"
						id="inv_add_user_type"
						defaultValue={user !== null ? user.type : ""}
					/>
					<input
						type="button"
						className="btn_margin_top update_inv_add_go_in_devis btn_valid"
						defaultValue="Valider"
						// disabled={
						// 	!(
						// 		isOtpEmailVerifiedFacturation ||
						// 		isOtpPhoneVerifiedFacturation ||
						// 		isOtpWhatsappVerifiedFacturation
						// 	)
						// }
						onClick={(e) => {
							// setIsOtpEmailVerifiedFacturation(false);
							// setIsOtpPhoneVerifiedFacturation(false);
							// setIsOtpWhatsappVerifiedFacturation(false);
						}}
					/>
				</div>
				<div className="clear" />
			</div>
			{/* menu part */}

			<div className="order_banner_bg" id="anchor_module_command">
				<div className="order_banner">
					<div className="order_banner_title" style={{ textAlign: "center" }}>
						Votre devis gratuit en ligne
						<div className="order_banner_sub_title">
							Facile, rapide et intuitif &nbsp;! Ce devis gratuit vous permettra
							d'obtenir un estimatif précis pour votre budget isolation.
						</div>
					</div>

					<div className="order_basket_container">
						<div className="order_tva_rate_container">
							<b>TVA : </b>
							<span id="order_tva_rate">21</span>%
						</div>
						<div className="order_total_qty_container">
							<b>Produit : </b>
							<span id="order_total_qty">0</span>
						</div>
						<div
							className="order_total_container show_hide_prices"
							style={{ display: "none" }}
						>
							<b>Total : </b>
							<span className="order_total">0</span> EUR HTVA
						</div>
					</div>

					<div className="order_module" style={{ height: 549 }}>
						<div className="order_steps_container">
							<span
								data-rel={1}
								className="order_steps_container_dot order_steps_container_dot_current"
							>
								1
							</span>
							<span data-rel={2} className="order_steps_container_line" />
							<span data-rel={2} className="order_steps_container_dot">
								2
							</span>
							<span data-rel={3} className="order_steps_container_line" />
							<span data-rel={3} className="order_steps_container_dot">
								3
							</span>
							<span data-rel={4} className="order_steps_container_line" />
							<span data-rel={4} className="order_steps_container_dot">
								4
							</span>
							<span
								data-rel={5}
								className="order_steps_container_line hide_on_adjust"
							/>
							<span
								data-rel={5}
								className="order_steps_container_dot hide_on_adjust"
							>
								5
							</span>
							<span
								data-rel={6}
								className="order_steps_container_line hide_on_adjust"
							/>
							<span
								data-rel={6}
								className="order_steps_container_dot hide_on_adjust"
							>
								6
							</span>
						</div>
						<div className="order_all_slide_container">
							<div className="order_all_slide_container_extended">
								<div
									id="order_all_slide_id_1"
									className="order_all_slide"
									data-rel={1}
								>
									<div className="order_all_slide_left_visual" />
									<div className="order_all_slide_right_form">
										<div className="order_all_slide_title order_all_slide_title_add_margin">
											Votre chantier est-il dans notre rayon d'action&nbsp;?
										</div>
										<form
											id="step_1_search_cp"
											className="order_all_slide_form"
										>
											<div className="container_FRorBE">
												<span
													className="hide_on_mobile"
													style={{ color: "#F9F9EA" }}
												>
													Votre chantier se situe
												</span>{" "}
												<a
													href="#"
													onClick={(e) => e.preventDefault()}
													data-rel="BE"
													className="js_switch_country_list fake_button fake_button_selected"
												>
													en Belgique
												</a>{" "}
												<span style={{ color: "#F9F9EA" }}>ou </span>
												<a
													href="#"
													onClick={(e) => e.preventDefault()}
													data-rel="LU"
													className="js_switch_country_list fake_button"
												>
													au Luxembourg
												</a>
											</div>
											<div
												style={{
													position: "relative",
													display: "inline-block",
													width: "100%",
													color: "#fff",
												}}
											>
												<input
													type="text"
													defaultValue=""
													id="order_client_cp"
													name="order_client_cp"
													placeholder="Code postal ou ville (Belgique)"
													autoComplete="chrome-off"
													style={{ width: "100%" }}
												/>
												<input id="cp_ID" name="cp_ID" type="hidden" />
												<input
													id="cp_GPS"
													name="cp_GPS"
													type="hidden"
													defaultValue={0}
												/>
												<div id="co_res" />
											</div>
											<div style={{ minHeight: 150 }}>
												<div className="rayon_action_nok">
													<span>
														Vous êtes à la limite de notre rayon d'action...
													</span>
													Laissez nous vos coordonnées afin que nous puissions
													discuter de votre projet.
													<br />
													<input
														type="text"
														id="recommand_partenaire_nom"
														placeholder="Votre nom"
														style={{
															marginTop: 5,
															height: 30,
															minHeight: 30,
															fontSize: 14,
														}}
													/>{" "}
													<input
														type="text"
														id="recommand_partenaire_tel"
														placeholder="Votre téléphone"
														style={{
															marginTop: 5,
															height: 30,
															minHeight: 30,
															fontSize: 14,
														}}
													/>{" "}
													<a
														onClick={(e) => e.preventDefault()}
														href="#"
														className="js_recommand_partenaire_go fake_button"
														style={{ marginTop: 5 }}
													>
														Envoyer
													</a>
												</div>
												<div className="rayon_action_ok">
													<span>
														Parfait, nous pouvons intervenir sur votre
														chantier&nbsp;!
													</span>
													Vous vous trouvez dans notre rayon d'action.
												</div>
											</div>

											<div
												style={{
													marginTop: 20,
													background: "#FFFFFF",
													padding: 10,
													color: "#8EA841",
												}}
											>
												Les prix qui vous seront communiqués dans le devis que
												vous recevrez dans quelques minutes restent indicatifs,
												nous tentons d'évaluer votre projet le plus précisément
												possible mais les fluctuations du marché en cette
												période peuvent impacter les prix de nos produits, merci
												pour votre compréhension.
											</div>
										</form>
									</div>
									<div className="clear" />
								</div>
								{/*
                 */}
								<div
									id="order_all_slide_id_2"
									className="order_all_slide"
									data-rel={2}
								>
									<div className="order_all_slide_title">
										Vous établissez ce devis en tant que...
									</div>
									<form className="order_all_slide_form">
										<div className="order_product_item_container">
											<div
												id="order_client_you_are_single"
												className="order_product_item"
											>
												<span className="order_product_item_image" />
												<span className="order_product_item_label">
													Particulier
												</span>
											</div>
											<div
												id="order_client_or"
												style={{ display: "inline-block", lineHeight: "140px" }}
											>
												<b>OU</b>
											</div>
											<div
												id="order_client_you_are_company"
												className="order_product_item"
											>
												<span className="order_product_item_image" />
												<span className="order_product_item_label">
													Professionnel
												</span>
											</div>
										</div>
										<div>
											<div id="order_client_corporate_domain_container">
												<label style={{ width: "100%" }}>
													<b>A propos de votre TVA ?</b>
												</label>

												<div className="input_row">
													<input
														id="pro_tva_coco"
														type="checkbox"
														defaultChecked
														defaultValue={1}
														name="pro_tva_coco"
													/>
													<label
														className="checkbox_line_height full_width"
														htmlFor="pro_tva_coco"
														style={{
															textAlign: "left",
															maxWidth: 300,
															width: "100%",
														}}
													>
														Cocontractant (BE)
													</label>
													<div className="clear" />
												</div>
												<div className="input_row">
													<input
														id="pro_tva_intra"
														type="checkbox"
														defaultValue={1}
														name="pro_tva_intra"
													/>
													<label
														className="checkbox_line_height full_width"
														htmlFor="pro_tva_intra"
														style={{
															textAlign: "left",
															maxWidth: 300,
															width: "100%",
														}}
													>
														Intracommunautaire
													</label>
													<div className="clear" />
												</div>
											</div>
											<div id="order_house_old_container">
												<label style={{ width: "100%" }} className="mb-1">
													<b>Votre bien à...</b>
												</label>
												<div
													className="input_row"
													style={{ paddingTop: "0px", paddingBottom: "0px" }}
												>
													<input
														id="less_10"
														type="checkbox"
														defaultChecked
														defaultValue={1}
														name="less_10"
													/>
													<label
														className="checkbox_line_height full_width"
														htmlFor="less_10"
														style={{
															textAlign: "left",
															maxWidth: 300,
															width: "100%",
														}}
													>
														Moins de 10 ans (TVA 21%)
													</label>
													<div className="clear" />
												</div>
												<div
													className="input_row"
													style={{ paddingTop: "0px", paddingBottom: "0px" }}
												>
													<input
														id="more_10"
														type="checkbox"
														defaultValue={1}
														name="more_10"
													/>
													<label
														className="checkbox_line_height full_width"
														htmlFor="more_10"
														style={{
															textAlign: "left",
															maxWidth: 300,
															width: "100%",
														}}
													>
														Plus de 10 ans (TVA 6%)
													</label>
													<div className="clear" />
												</div>
											</div>
										</div>
									</form>
								</div>
								{/*
                 */}
								<div
									id="order_all_slide_id_3"
									className="order_all_slide"
									data-rel={3}
								>
									<form
										className="order_all_slide_form"
										style={{ marginTop: 0 }}
									>
										<div className="order_house_png">
											<div id="order_house_png_filter" className="" />
										</div>
										<div className="order_house_png_txt">
											<div className="order_all_slide_title">
												Choisir une zone à isoler
											</div>
											<div>
												Vous pourrez ajouter d'autres zones par la suite...
											</div>
											<div style={{ marginTop: 20 }}>
												<select
													id="order_zone_name"
													name="order_zone_name"
													style={{ width: "100%" }}
													onChange={handleOptionChange}
												>
													<option value="" selected="">
														Choisir une zone...
													</option>
													<option value="toiture_pent">Toiture en pente</option>
													<option value="sol_cave">Sol cave</option>
													<option value="sol_rez_de_chausse">
														Sol rez-de-chaussée
													</option>
													<option value="sol_etage">Sol étage</option>
													<option value=" sol_comble">
														Sol comble (par projection)
													</option>
													<option value="comble_perdu">
														Combles perdues (par insufflation)
													</option>
													<option value="plafond_cave">Plafond de cave</option>
													<option value="plafond_entre_etage">
														Plafond entre étage
													</option>
													<option value="mur_toiture">Mur toiture</option>
													<option value="cloisons_interieures">
														Murs intérieurs (par projection)
													</option>
													<option value="mur_creux_isuffation">
														Murs creux (par insufflation)
													</option>
													<option value="vide_ventile">Vide ventilé</option>
													<option value="chape">Chape finition (ciment)</option>
													{/* ONLY ADMIN */}
													{/* <option value="mur_ext">Murs extérieurs</option> */}
													{/* <option value="mur_cave">Murs de cave</option> */}
												</select>
												<div
													id="info_zone_avec_chauff_sol"
													style={{
														display:
															selectedOption === "chape" ? "block" : "none",
														marginTop: 15,
													}}
													className="input_row"
												>
													<input
														id="chauff_sol"
														type="checkbox"
														defaultValue={1}
														name="chauff_sol"
													// style={{ marginRight: "80px" }}
													/>
													<label
														className="checkbox_line_height full_width"
														htmlFor="chauff_sol"
														style={{ width: "100%", paddingRight: "15%" }}
													>
														{selectedOption === "chape"
															? "Prévoyez-vous le placement d’un chauffage au sol ? Si c’est le cas cocher la case pour l’ajout de l'option fibre."
															: "Prévoyez-vous le placement d’un chauffage au sol ? Si c’est le cas cocher la case pour l’ajout de l'option écrétage."}
													</label>
													<div className="clear" />
													<br />
													<i>(Si coché, l'option écrétage est obligatoire)</i>
												</div>

												<div
													id="info_zone_avec_access_grenier"
													style={{ display: "none", marginTop: 15 }}
													className="input_row"
												>
													<input
														id="access_grenier"
														type="checkbox"
														defaultValue={1}
														name="access_grenier"
													/>
													<label
														className="checkbox_line_height full_width"
														htmlFor="access_grenier"
														style={{ width: "100%", paddingRight: "15%" }}
													>
														N'a-t-il pas un accès à votre grenier ?
													</label>
													<div className="clear" />
													<i>
														(Si coché, l'option passage par l'extérieur est
														obligatoire)
													</i>
												</div>

												<div
													id="info_zone_avec_access_min"
													style={{ display: "none", marginTop: 15 }}
													className="input_row mx-2"
												>
													<input
														id="access_min"
														type="checkbox"
														defaultValue={1}
														name="access_min"
													/>
													<label
														className="checkbox_line_height full_width"
														htmlFor="access_min"
														style={{ width: "100%", paddingRight: "15%" }}
													>
														Veuillez cocher la case si votre grenier n’est pas
														accessible par un accès de minimum 60 cm sur 60 cm.
													</label>
													<div className="clear" />
													<br />
													<i>(Si coché, vous passez à comble perdu)</i>
												</div>

												<div
													id="info_zone_avec_plastique"
													style={{ display: "none", marginTop: 15 }}
													className="input_row mx-2"
												>
													<input
														id="plastique"
														type="checkbox"
														defaultValue={1}
														name="plastique"
													/>
													<label
														className="checkbox_line_height full_width"
														htmlFor="plastique"
														style={{ width: "100%", paddingRight: "15%" }}
													>
														Veuillez cocher la case si votre toiture n’est pas
														équipé d’une sous toiture ou tout autre revêtement
														qui permettrait que la mousse entrent en contact
														avec la couverture de toiture (tuiles,ardoise,etc..)
													</label>
													<div className="clear" />
													<br />
													<i>
														(Si coché, l'option sous toiture est obligatoire)
													</i>
												</div>
											</div>
											<div className="clear" />
											<div style={{ marginTop: 20 }}>
												<div style={{ marginBottom: 20, color: "#FFFFFF" }}>
													{selectedOption === "chape"
														? "Afin de calculer la quantité de chape à prévoir, merci d'indiquer la suface de cette zone en m² et l'épaisseur de chape souhaitée en cm."
														: "Afin de calculer la quantité d'isolant à prévoir, merci d'indiquer la suface de cette zone en m² et l'épaisseur d'isolant souhaitée en cm."}
												</div>
											</div>
											<div className="clear" />
											<div
												id="product_container"
												className="order_product_item_container"
												style={{ display: "none" }}
											>
												<div
													data-rel="BRANCH_4@FAMILY_155"
													className="order_product_item js_family"
												>
													<span
														className="order_product_item_image"
														style={{ backgroundImage: "url(img/)" }}
													/>
													<span className="order_product_item_label">
														Isolant sol (cellule fermée)
													</span>
												</div>
												<div
													data-rel="BRANCH_4@FAMILY_153"
													className="order_product_item js_family"
												>
													<span
														className="order_product_item_image"
														style={{ backgroundImage: "url(img/)" }}
													/>
													<span className="order_product_item_label">
														Isolant sol (cellule fermée)
													</span>
												</div>
												<div
													data-rel="BRANCH_4@FAMILY_156"
													className="order_product_item js_family"
												>
													<span
														className="order_product_item_image"
														style={{ backgroundImage: "url(img/)" }}
													/>
													<span className="order_product_item_label">
														Isolant sous-toiture, mur et plafond (cellule fermée
														/ INTERNE)
													</span>
												</div>
												<div
													data-rel="BRANCH_4@FAMILY_159"
													className="order_product_item js_family"
												>
													<span
														className="order_product_item_image"
														style={{ backgroundImage: "url(img/)" }}
													/>
													<span className="order_product_item_label">
														Isolant sous-toiture, mur et plafond (cellule fermée
														/ INTERNE)
													</span>
												</div>
												<div
													data-rel="BRANCH_4@FAMILY_152"
													className="order_product_item js_family"
												>
													<span
														className="order_product_item_image"
														style={{ backgroundImage: "url(img/)" }}
													/>
													<span className="order_product_item_label">
														Isolant sous-toiture, mur et plafond (cellule
														ouverte)
													</span>
												</div>
												<div
													data-rel="BRANCH_4@FAMILY_158"
													className="order_product_item js_family"
												>
													<span
														className="order_product_item_image"
														style={{ backgroundImage: "url(img/)" }}
													/>
													<span className="order_product_item_label">
														Pure floc
													</span>
												</div>
												<div
													data-rel="BRANCH_4@FAMILY_157"
													className="order_product_item js_family"
												>
													<span
														className="order_product_item_image"
														style={{ backgroundImage: "url(img/)" }}
													/>
													<span className="order_product_item_label"></span>
												</div>
											</div>
											<div id="product_qty_container">
												<div className="input_row">
													<label
														style={{ width: "35%", display: "inline-block" }}
													>
														Surface
													</label>
													<input
														style={{ width: "48%", display: "inline-block" }}
														type="text"
														id="order_qty_produit"
														name="order_qty_produit"
														placeholder="Entrer une surface"
													/>
													<div
														style={{
															textAlign: "right",
															width: "10%",
															display: "inline-block",
															lineHeight: "45px",
															paddingLeft: 5,
															paddingRight: 5,
															color: "#FFFFFF",
														}}
													>
														m²
													</div>
												</div>
												<div className="input_row">
													<label
														style={{ width: "35%", display: "inline-block" }}
													>
														Epaisseur
													</label>
													<input
														style={{ width: "48%", display: "inline-block" }}
														type="text"
														id="order_epais_produit"
														name="order_epais_produit"
														placeholder="Entrer une épaisseur"
													/>
													<div
														style={{
															textAlign: "right",
															width: "10%",
															display: "inline-block",
															lineHeight: "45px",
															paddingLeft: 5,
															paddingRight: 5,
															color: "#FFFFFF",
														}}
													>
														cm
													</div>
													<div
														id="comment_zone"
														style={{
															marginTop: 5,
															fontStyle: "italic",
															fontSize: 12,
															color: "#FFFFFF",
														}}
													/>
												</div>

												{selectedOption !== "chape" && (
													<div style={{ paddingTop: 8, color: "#FFFFFF" }}>
														Coefficients R et lambda (λ) pour votre choix
														<br />
														R=
														<span style={{ fontWeight: "bold" }} id="coef_r">
															0.00
														</span>{" "}
														λ=
														<span
															style={{ fontWeight: "bold" }}
															id="coef_lambda"
														>
															0.000
														</span>
													</div>
												)}
												<div
													className="show_hide_prices"
													style={{ display: "none" }}
												>
													<label id="label_order_qty_produit_res">
														Epais.(cm) X <span id="order_unit_price">0</span>{" "}
														EUR soit{" "}
														<span id="label_order_qty_produit_res_tot">0</span>{" "}
														EUR
													</label>
												</div>
												<br />
												<div className="order_all_slide_title">Options ?</div>
												<br />
												<div
													id="option_container"
													className="order_product_item_container order_product_item_allow_multiple"
												/>
											</div>
										</div>
										<div className="clear" />
									</form>
									<div className="clear" />
								</div>
								{/*
                 */}
								<div id="order_all_slide_id_4" className="order_all_slide">
									<div className="order_all_slide_title">
										Récap de votre devis
									</div>
									<div
										id="recap_order"
										style={{
											marginTop: 20,
											background: "#FFFFFF",
											color: "#8EA841",
										}}
									></div>
									<div style={{ textAlign: "center" }}>
										<input
											type="button"
											className="add_sign btn_margin_top js_add_product"
											data-rel=""
											defaultValue="Ajouter une autre zone ?"
										/>
										<input
											type="button"
											className="add_sign btn_margin_top js_add_free_amount"
											data-rel=""
											defaultValue="Ajouter un montant libre ?"
											style={{ display: "none" }}
										/>
									</div>
								</div>
								{/*
                 */}
								<div id="order_all_slide_id_5" className="order_all_slide">
									<div className="order_all_slide_title">
										{" Vous allez recevoir votre devis instantanément !"}
									</div>
									<div>
										Pour cela, nous avons besoin de vos coordonnées pour vous
										envoyer votre devis par email. Cela peut se faire de deux
										façons...
									</div>
									<form className="order_all_slide_form">
										<div
											className="user_logged"
											style={{ display: "none", color: "#fff" }}
										>
											Merci <b className="user_full_name">Init...</b> !<br />
											Vous compte a été créé avec succès.
											<br />
											<br />
											<div
												style={{
													textAlign: "left",
													borderTop: "1px solid #cccccc",
													paddingTop: 20,
												}}
											>
												<b>Adresse du chantier</b>
												<br />
												<div id="delivery_item_list"></div>
												<br />
												<br />
												<b>Adresse de facturation</b>
												<br />
												Par défaut, nous avons utilisé votre adresse de chantier
												comme adresse de facturation, mais si celle-ci est
												différente ou que vous avez besoin d'indiquer un numéro
												de TVA (pour les profesionnel) vous pouvez cliquer sur
												"Créer une autre adresse de facturation".
												<div id="invoicing_devis_item_list"></div>
											</div>
										</div>
										<div
											className="user_not_logged order_product_item_container"
											style={{}}
										>
											<div
												style={{ display: "none", height: '160px' }}
												id="order_client_particular"
												className="order_product_item js_create_new_devis_particular"
											>
												<span
													className="order_product_item_image"
													style={{ backgroundImage: `url(${adresse_img})` }}
												/>
												<span className="mt-2 order_product_item_label">
													Choisir une adresse de facturation / chantier
												</span>
											</div>
											{auth.user === null ||
												(auth.user !== null &&
													auth.isVisible(
														"Créer le devis d'un client possédant déjà un compte",
													)) ? (
												<div
													id="order_client_already"
													className="order_product_item js_connexion"
												>
													<span
														className="order_product_item_image"
														style={{ backgroundImage: `url(${password})` }}
													/>
													<span className="order_product_item_label">
														Déjà un compte ? Connexion
													</span>
												</div>
											) : null}
											<div
												id="order_client_new"
												className="order_product_item js_create_new_account"
											>
												<span
													className="order_product_item_image"
													style={{ backgroundImage: `url(${new_user})` }}
												/>
												<span className="order_product_item_label">
													En créant un compte !
												</span>
											</div>
										</div>
									</form>
								</div>
								{/*
                 */}
								<div id="order_all_slide_id_6" className="order_all_slide">
									<div className="order_all_slide_title">
										Parfait ! Votre devis est terminé
									</div>
									<form className="order_all_slide_form">
										<div className="pay_solution_label_container">
											À présent vous pouvez générer votre devis afin de
											l'accepter ou de le refuser.{" "}
											<span className="order_devis_id"></span>
											<span
												className="show_hide_prices"
												style={{ display: "none" }}
											>
												, montant total{" "}
											</span>
										</div>
										<div
											className="pay_solution_order_total_container show_hide_prices"
											style={{ display: "none" }}
										>
											<span className="order_total">0.00</span> EUR HTVA
										</div>
										<div className="pay_solution_container">
											<div className="pay_solution_item">
												<input
													type="button"
													className="pay_on_delivery inline_devis "
													defaultValue="Générer votre devis"
													data-rep="generer"
												/>
												<div
													style={{
														marginTop: 10,
														background:
															"url(img/paiement_driver.png) no-repeat center",
														backgroundSize: "auto 40px",
														height: 40,
													}}
												/>
											</div>
										</div>
									</form>
								</div>
							</div>
						</div>
					</div>
					<div className="order_all_slide_container_footer">
						<input
							type="button"
							className="order_all_slide_navig_btn back_arrow"
							data-rel="prev"
							defaultValue="Précédent"
						/>
						<input
							type="button"
							className="order_all_slide_navig_btn arrow-right"
							defaultValue="Suivant"
							data-rel="next"
						/>
						<input
							type="button"
							className="order_save_adjust"
							data-rel="next"
							defaultValue="Sauver vos modifications"
							style={{ display: "none" }}
						/>
						<input type="hidden" id="order_current_slide" defaultValue={1} />
						<div className="clear" />
					</div>
				</div>
			</div>
			<Modal show={showModal} onHide={handleCloseModal}>
				<Modal.Header closeButton className="d-flex justify-content-center">
					<Modal.Title className="justify-content-center">
						{otpVerificationTitle}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<p>Veuillez entrer le code que vous avez reçu.</p>
					<Form onSubmit={(e) => e.preventDefault()}>
						<Row>
							<Col>
								<Form.Group as={Row} className="mb-3" controlId="otp_to_verify">
									<Form.Label column sm="2">
										Code
									</Form.Label>
									<Col sm="10">
										<Form.Control type="number" onChange={handleOtpChange} />
									</Col>
								</Form.Group>
							</Col>
						</Row>

						<Row>
							<Col>
								<p className="text-danger mt-3">{otpErrorMessage}</p>
							</Col>
						</Row>
					</Form>
				</Modal.Body>
				<Modal.Footer className="d-flex justify-content-center">
					<Button
						variant="success"
						className="btn-fill bg-success"
						onClick={(e) => {
							e.preventDefault();
							setOtpConfirmSpinnerIsLoading(true);
							handleConfirmVerificationSubmit();
						}}
					>
						<div className="d-flex justify-content-between align-items-center">
							<span className="me-2">Confirmer</span>
							<Spinner
								color="#fff"
								size={10}
								speed={1}
								animating={otpConfirmSpinnerIsLoading}
							/>
						</div>
					</Button>
				</Modal.Footer>
			</Modal>
			<Modal show={showModalChantier} onHide={handleCloseModalChantier}>
				<Modal.Header closeButton className="d-flex justify-content-center">
					<Modal.Title className="justify-content-center">
						{otpVerificationTitleChantier}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<p>Veuillez entrer le code que vous avez reçu.</p>
					<Form onSubmit={(e) => e.preventDefault()}>
						<Row>
							<Col>
								<Form.Group as={Row} className="mb-3" controlId="otp_to_verify">
									<Form.Label column sm="2">
										Code
									</Form.Label>
									<Col sm="10">
										<Form.Control
											type="number"
											onChange={handleOtpChangeChantier}
										/>
									</Col>
								</Form.Group>
							</Col>
						</Row>

						<Row>
							<Col>
								<p className="text-danger mt-3">{otpErrorMessageChantier}</p>
							</Col>
						</Row>
					</Form>
				</Modal.Body>
				<Modal.Footer className="d-flex justify-content-center">
					<Button
						variant="success"
						className="btn-fill bg-success"
						onClick={(e) => {
							e.preventDefault();
							setOtpConfirmSpinnerIsLoadingChantier(true);
							handleConfirmVerificationSubmitChantier();
						}}
					>
						<div className="d-flex justify-content-between align-items-center">
							<span className="me-2">Confirmer</span>
							<Spinner
								color="#fff"
								size={10}
								speed={1}
								animating={otpConfirmSpinnerIsLoadingChantier}
							/>
						</div>
					</Button>
				</Modal.Footer>
			</Modal>
			<Modal show={showModalFacturation} onHide={handleCloseModalFacturation}>
				<Modal.Header closeButton className="d-flex justify-content-center">
					<Modal.Title className="justify-content-center">
						{otpVerificationTitleFacturation}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<p>Veuillez entrer le code que vous avez reçu.</p>
					<Form onSubmit={(e) => e.preventDefault()}>
						<Row>
							<Col>
								<Form.Group as={Row} className="mb-3" controlId="otp_to_verify">
									<Form.Label column sm="2">
										Code
									</Form.Label>
									<Col sm="10">
										<Form.Control
											type="number"
											onChange={handleOtpChangeFacturation}
										/>
									</Col>
								</Form.Group>
							</Col>
						</Row>

						<Row>
							<Col>
								<p className="text-danger mt-3">{otpErrorMessageFacturation}</p>
							</Col>
						</Row>
					</Form>
				</Modal.Body>
				<Modal.Footer className="d-flex justify-content-center">
					<Button
						variant="success"
						className="btn-fill bg-success"
						onClick={(e) => {
							e.preventDefault();
							setOtpConfirmSpinnerIsLoadingFacturation(true);
							handleConfirmVerificationSubmitFacturation();
						}}
					>
						<div className="d-flex justify-content-between align-items-center">
							<span className="me-2">Confirmer</span>
							<Spinner
								color="#fff"
								size={10}
								speed={1}
								animating={otpConfirmSpinnerIsLoadingFacturation}
							/>
						</div>
					</Button>
				</Modal.Footer>
			</Modal>
		</div>
	);
}

export default CreateQuotationWithFlowchart;
