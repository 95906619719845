import React from "react";
import SingleFile from "components/Uploader/SingleFile";
import {
  Badge,
  Button,
  Card,
  Form,
  Navbar,
  Nav,
  Container,
  Row,
  Col,
} from "react-bootstrap";

import axios from "axios";
import ApiConstants from "constant";
import { Dots } from "react-activity";
import "react-activity/dist/library.css";
import Error from "components/utils/Error";
import Select from "react-select";
import { AuthContext } from "AuthProvider";
import html2canvas from "html2canvas";

class ArticleCreate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      validated: false,
      isLoading: false,
      isFound: true,
      isError: false,
      error: "",
      applications: [],
      selectError: false,
      selectedOption: null,
      image: Object.keys(this.props.location.state.article).length === 0
      ? null
      : this.props.location.state.article.image,
      
      id_cible:
        Object.keys(this.props.location.state.article).length === 0
          ? null
          : this.props.location.state.article.id_Cible,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleResponse = this.handleResponse.bind(this);
    this.handleError = this.handleError.bind(this);
    this.fetchApplications = this.fetchApplications.bind(this);
    this.handleResponseFetchApplications =
      this.handleResponseFetchApplications.bind(this);
      this. handleFileSelect =
      this. handleFileSelect.bind(this);
    
  }


  
static contextType = AuthContext;
  componentDidMount() {
    this.fetchApplications();
  }

  handleFileSelect = (selectedFile) => {
    if (selectedFile) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64Data = reader.result.split(',')[1];
        this.setState({ image: base64Data.trim() });
      };
      reader.readAsDataURL(selectedFile);
    } else {
      this.setState({ image: null });
    }
  };

  handleResponseFetchApplications(response) {
    if (response.data.status == 200) {
    
      let applications = [];
      response.data.Cibles.map((application) => {
        applications.push({
          value: application.id.toString(),
          label: application.libelle,
        });
      });
      this.setState({ applications: applications });
    }
  }


  

  fetchApplications() {
    let url = ApiConstants.baseUrlRequest + ApiConstants.getApplications;

    axios
      .get(url)
      .then(this.handleResponseFetchApplications)
      .catch(this.handleError);
  }

  handleResponse(response) {
  
    this.props.history.push({
      pathname: "/welcome/article/show",
      state: { article: response.data.articles[0], image: this.state.image},
    });
  }

  handleError(error) {
    if (error.isAxiosError) {
      if ("response" in error) {
        if (error.response.status === 401) {
        
          this.setState({ isLoading: false });
        } else if (error.response.status === 404) {
          
          this.setState({ isLoading: false });
          this.setState({ isFound: false });
        } else {
         
          this.setState({ isLoading: false });
          this.setState({ isError: true });
          this.setState({ error: error });
         
        }
      } else {
    
        this.setState({ isLoading: false });
        this.setState({ isError: true });
        this.setState({ error: error });
      }
    } else {
   
    }

    console.log(error);
  }

  handleSubmit(event, article, isEdition) {
  
    event.preventDefault();
    const hasError = true;

    const form = event.currentTarget;
    if( this.state.id_cible === null){

      this.setState({ selectError: hasError });
    }

  

    if (form.checkValidity() === false) {
      event.stopPropagation();
      this.setState({ validated: true });
      
    } else {
      this.setState({ validated: false });
      this.setState({ isLoading: true });
    
      let data = {
        libelle: this.designation.value,
        price: this.price.value,
        description: this.description.value,
        username: this.context.user.username,
        image: this.state.image,
  
        thickness:
          !isEdition ||
          (isEdition && article.thickness !== "" && article.surface === "")
            ? this.thickness.value
            : null,
        type: this.type.value,
        brand:
          !isEdition ||
          ("libelle" in article && !article.libelle.includes("Forfait"))
            ? this.brand.value
            : null,
        conductivity:
          !isEdition ||
          ("libelle" in article && !article.libelle.includes("Forfait"))
            ? this.conductivity.value
            : null,
        thermal_resistance:
          !isEdition ||
          ("libelle" in article && !article.libelle.includes("Forfait"))
            ? this.thermal_resistance.value
            : null,
        surface:
          !isEdition ||
          (isEdition && article.surface !== "" && article.thickness === "")
            ? this.surface.value
            : null,
        unit:
          !isEdition ||
          ("libelle" in article && !article.libelle.includes("Forfait"))
            ? this.unit.value
            : null,
        id_Cible: this.state.id_cible,
      };

      if (Object.keys(this.props.location.state.article).length !== 0) {
        data.id_article = article.id;
      }
     
      let url = ApiConstants.baseUrlRequest;
 

     
      let method = "";

      if (isEdition) {
        url = url + ApiConstants.editArticle;
        method = "put";
      } else {
        url = url + ApiConstants.addArticle;
        method = "post";
      }

     
      axios({ method: method, url: url, data: data })
        .then(this.handleResponse)
        .catch(this.handleError);
    }
  }

  render() {
    let article = this.props.location.state.article;
    let isEdition =
      Object.keys(this.props.location.state.article).length === 0
        ? false
        : true;

        const { selectError } = this.state;
        const { image } = this.state;
        
        const customStyles = {
          control: (provided, state) => ({
            ...provided,
        
            borderColor: this.state.selectError ? 'red' : provided.borderColor,
          }),
        };
    if (this.state.isLoading === true) {
      return (
        <Container fluid>
          <div className="d-flex justify-content-center align-items-center">
            <Dots
              color="#727981"
              size={32}
              speed={1}
              animating={this.state.isLoading}
            />
          </div>
        </Container>
      );
    }

    if (this.state.isLoading === false && this.state.isFound === false) {
      return <Error code={404} />;
    }

    if (this.state.isError === true) {
      if (this.state.error.message === "Network Error") {
        return <Error code={-1} />;
      }
      return <Error code={-2} />;
    }

    return (
      <>
        <Container fluid>
          <Row>
            <Col sm="12" className="d-flex justify-content-center">
              <Card style={{ width: "50rem" }}>
                <Card.Header>
                  <Card.Title as="h4">
                    {!isEdition ? (
                      <span>Créer votre article </span>
                    ) : (
                      <span>Modifier votre article </span>
                    )}
                  </Card.Title>
                </Card.Header>
                <Card.Body>
                  <Form
                    noValidate
                    validated={this.state.validated}
                    onSubmit={(e) => this.handleSubmit(e, article, isEdition)}
                  >
                    
                    <Row>
                      <Col className="px-1" sm="12">
                        <Form.Label
                          style={{
                            color: "black",
                            fontSize: "15",
                            fontWeight: "bold",
                          }}
                          htmlFor="composant"
                        >
                          Application <span style={{ color: 'red' }}>*</span>
                        </Form.Label>                    
                        <Select
                          required
                          data-trigger
                          name="choices-single-default"
                          id="application"
                          options={this.state.applications}
                          placeholder="Sélectionner l'application de l'article"
                          defaultValue={
                            isEdition
                              ? 
                              {
                               
                                  value: article.id_Cible,
                                  label: article.cible,
                                }
                              : null
                              
                          }
                          
                          
                          onChange={(option) => {
                           
                            const { applications } = this.state;
                          const selectedValue = option ? option.value : null;
                      
                          this.setState({
                            id_cible: selectedValue,
                            selectError: false,
                          });
                          }}
                        
                          styles={customStyles}
      
                        />
                      </Col>
                      <Col className="px-1" sm="12">
                        <Form.Group controlId="designation">
                          <Form.Label>Désignation <span style={{ color: 'red' }}>*</span></Form.Label>
                          <Form.Control
                            placeholder="Désignation"
                            type="text"
                            ref={(input) => (this.designation = input)}
                            defaultValue={isEdition ? article.libelle : null}
                            required
                          />
                        </Form.Group>
                      </Col>
                      <Col className="px-1" sm="12">
                        <Form.Group controlId="prix">
                          <Form.Label>Prix <span style={{ color: 'red' }}>*</span></Form.Label>
                          <Form.Control
                            placeholder="Prix"
                            type="number"
                            ref={(input) => (this.price = input)}
                            defaultValue={isEdition ? article.price : null}
                            required
                          ></Form.Control>
                        </Form.Group>
                      </Col>
                      <Col sm="12" className="px-1">
                        <Form.Group controlId="description">
                          <Form.Label>Description</Form.Label>
                          <Form.Control
                            as="textarea"
                            rows={3}
                            ref={(input) => (this.description = input)}
                            defaultValue={
                              isEdition ? article.description : null
                            }
                          
                          ></Form.Control>
                        </Form.Group>
                      </Col>
                      {!isEdition ||
                      (isEdition &&
                        article.thickness !== "" &&
                        article.surface === "") ? (
                        <Col className="px-1" sm="12">
                          <Form.Group controlId="epaisseur">
                            <Form.Label>Épaisseur</Form.Label>
                            <Form.Control
                              placeholder="Épaisseur"
                              type="number"
                              defaultValue={
                                isEdition ? article.thickness : null
                              }
                              ref={(input) => (this.thickness = input)}
                             
                            ></Form.Control>
                          </Form.Group>
                        </Col>
                      ) : null}

                      {!isEdition ||
                      (isEdition &&
                        article.surface !== "" &&
                        article.thickness === "") ? (
                        <Col className="px-1" sm="12">
                          <Form.Group controlId="surface">
                            <Form.Label>Surface</Form.Label>
                            <Form.Control
                              placeholder="Surface"
                              type="number"
                              defaultValue={isEdition ? article.surface : null}
                              ref={(input) => (this.surface = input)}
                           
                            ></Form.Control>
                          </Form.Group>
                        </Col>
                      ) : null}

                      <Col className="px-1" sm="12">
                        <Form.Group controlId="type">
                          <Form.Label>Type</Form.Label>
                          <Form.Control
                            placeholder="type de la mousse : ouverte / fermée"
                            type="text"
                            ref={(input) => (this.type = input)}
                            defaultValue={isEdition ? article.type : null}
                            
                          ></Form.Control>
                        </Form.Group>
                      </Col>

                      {!isEdition ||
                      ("libelle" in article &&
                        !article.libelle.includes("Forfait")) ? (
                        <>
                          <Col className="px-1" sm="12">
                            <Form.Group controlId="brand">
                              <Form.Label>Marque</Form.Label>
                              <Form.Control
                                placeholder="Marque"
                                type="text"
                                ref={(input) => (this.brand = input)}
                                defaultValue={isEdition ? article.brand : null}
                               
                              ></Form.Control>
                            </Form.Group>
                          </Col>

                          <Col className="px-1" sm="12">
                            <Form.Group controlId="Conductivity">
                              <Form.Label>Conductivité</Form.Label>
                              <Form.Control
                                placeholder="Conductivité du matériel"
                                type="number"
                                ref={(input) => (this.conductivity = input)}
                                defaultValue={
                                  isEdition ? article.conductivity : null
                                }
                                step="0.0001"
                               
                              ></Form.Control>
                            </Form.Group>
                          </Col>
                          <Col className="px-1" sm="12">
                            <Form.Group controlId="thermal_resistance">
                              <Form.Label>Resistance thermique</Form.Label>
                              <Form.Control
                                placeholder="Resistance thermique"
                                type="number"
                                ref={(input) =>
                                  (this.thermal_resistance = input)
                                }
                                defaultValue={
                                  isEdition ? article.thermal_resistance : null
                                }
                                step="0.0001"
                                
                              ></Form.Control>
                            </Form.Group>
                          </Col>
                          <Col className="px-1" sm="12">
                            <Form.Group controlId="Unité">
                              <Form.Label>Unité</Form.Label>
                              <Form.Control
                                placeholder="Unité"
                                type="text"
                                ref={(input) => (this.unit = input)}
                                defaultValue={isEdition ? article.unit : null}
                                
                              ></Form.Control>
                            </Form.Group>
                          </Col>
                        </>
                      ) : null}

                 
                    </Row>
                    <Row>
                      {!isEdition || ("libelle" in article && !article.libelle.includes("Forfait")) ? (
                        <Col sm="12" className="px-1">
                          <SingleFile
                            title={"Image de l'article"}
                            subTitle={null} // ou spécifiez un sous-titre si nécessaire
                            onFileSelect={this.handleFileSelect}
                          />
                        </Col>
                      ) : null}
                      {image && (
                        <Col sm="12" className="px-1 d-flex justify-content-center align-items-center">
                          <img src={`data:image/jpeg;base64, ${image}`} alt="Image de l'article" style={{ width: '25rem', height: '15rem' ,objectFit: 'contain'}} />
                        </Col>
                      )}
                    </Row>

                    <div className="d-flex align-items-center justify-content-center py-3 mt-5">
                      <Button
                        className="btn-fill pull-right"
                        type="submit"
                        variant="info"
                      >
                        {!isEdition ? (
                          <span>Créer </span>
                        ) : (
                          <span>Modifier </span>
                        )}
                      </Button>
                    </div>
                    <div className="clearfix"></div>
                  </Form>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col sm="12"></Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default ArticleCreate;
