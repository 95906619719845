import React from 'react';
import PaginationRB from 'react-bootstrap/Pagination';

class Pagination extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { currentPage, totalPages, onPageChange } = this.props;

    const renderPaginationItems = () => {
      const items = [];

      // Ajouter le bouton "Première page"
      items.push(
        <PaginationRB.First
          key="first"
          onClick={() => onPageChange(1)}
          disabled={currentPage === 1}
        />
      );

      // Ajouter le bouton "Précédent"
      items.push(
        <PaginationRB.Prev
          key="prev"
          onClick={() => onPageChange(currentPage - 1)}
          disabled={currentPage === 1}
        />
      );

      // Ajouter les numéros de page
      for (let page = 1; page <= totalPages; page++) {
        items.push(
          <PaginationRB.Item
            key={page}
            active={page === currentPage}
            onClick={() => onPageChange(page)}
          >
            {page}
          </PaginationRB.Item>
        );
      }

      // Ajouter le bouton "Suivant"
      items.push(
        <PaginationRB.Next
          key="next"
          onClick={() => onPageChange(currentPage + 1)}
          disabled={currentPage === totalPages}
        />
      );

      // Ajouter le bouton "Dernière page"
      items.push(
        <PaginationRB.Last
          key="last"
          onClick={() => onPageChange(totalPages)}
          disabled={currentPage === totalPages}
        />
      );

      return items;
    };

    return <PaginationRB>{renderPaginationItems()}</PaginationRB>;
  }
}

export default Pagination;
