/*!

=========================================================
* Light Bootstrap Dashboard React - v2.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/light-bootstrap-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { Component } from "react";
import { useLocation, NavLink, useHistory } from "react-router-dom";
import { Nav } from "react-bootstrap";
import { useAuth } from "AuthProvider";
import { useEffect } from "react";

function Sidebar({ color, image, routes }) {
  const auth = useAuth();
  const location = useLocation();
  const activeRoute = (routeName) => {
    return location.pathname.indexOf(routeName) > -1 ? "active" : "";
  };
  let filterRoutes = routes.filter(
    (route) =>
      (route.path === "/devis" && auth.isVisible("Lister les devis")) || // take it if "Lister les devis" visibility is true
      (route.path !== "/devis" && route.userType.includes(auth.user.type)) ||
      (route.path === "/find_devis" && auth.isVisible("Trouver un devis")) ||
      (route.path !== "/find_devis" &&
        route.userType.includes(auth.user.type)) ||
      (route.path === "/article" && auth.isVisible("Lister les articles")) || // take it if "Lister les devis" visibility is true
      (route.path !== "/article" && route.userType.includes(auth.user.type)) ||
      (route.path === "/user" && auth.isVisible("Lister les partenaires")) || // take it if "Lister les devis" visibility is true
      (route.path !== "/user" && route.userType.includes(auth.user.type)) ||
      (route.path === "/client" && auth.isVisible("Lister les clients")) || // take it if "Lister les devis" visibility is true
      (route.path !== "/client" && route.userType.includes(auth.user.type)) ||
      (route.path === "/emailmodel" && auth.isVisible("modéle email")) || // take it if "Lister les devis" visibility is true
      (route.path !== "/emailmodel" &&
        route.userType.includes(auth.user.type)) ||
      (route.path === "/exportdata" &&
        auth.isVisible("exporter les données")) || // take it if "Lister les devis" visibility is true
      (route.path !== "/exportdata" &&
        route.userType.includes(auth.user.type)) ||
      (route.path === "/dashboard" &&
        auth.isVisible("tableau des statistiques")) || // take it if "Lister les devis" visibility is true
      (route.path !== "/dashboard" && route.userType.includes(auth.user.type)),
  );

  const history = useHistory();
  const handleSubmit = (e) => {
    e.preventDefault(); // empecher le mécanisme normal de soumission du formulaire
    auth.logOut();
  };

  useEffect(() => {}, []);

  return (
    <div className="sidebar" data-image={image} data-color={color}>
      <div
        className="sidebar-background"
        style={{
          backgroundImage: "url(" + image + ")",
        }}
      />
      <div className="sidebar-wrapper">
        <div className="logo d-flex align-items-center justify-content-start">
          <a
            href="/home"
            className="simple-text logo-mini mx-1"
          >
            <div className="logo-img">
              <img src={require("assets/img/Logo_full.png")} alt="..." />
            </div>
          </a>
        </div>
        <div className="nav">
          {filterRoutes.map((prop, key) => {
            return (
              <li
                className={
                  prop.disconnect
                    ? "active active-pro"
                    : activeRoute(prop.layout + prop.path)
                }
                key={key}
              >
                <NavLink
                  onClick={prop.disconnect ? (e) => handleSubmit(e) : null}
                  to={prop.layout + prop.path}
                  className="nav-link"
                  activeClassName="active"
                >
                  <div className="d-flex align-items-center justify-content-start">
                    <prop.icon size={25} />
                    <p className="ms-3">{prop.name}</p>
                  </div>
                </NavLink>
              </li>
            );
            return null;
          })}
        </div>
      </div>
    </div>
  );
}

export default Sidebar;
