import React, { useEffect, useState, useRef } from "react";
import {
	Button,
	Container,
	Modal,
	Form,
	Row,
	Col,
	Card,
	Alert,
} from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { AiOutlineDownload } from "react-icons/ai";
import Table from "react-bootstrap/Table";
import JsPDF from "jspdf";
import { Dots } from "react-activity";
import "react-activity/dist/library.css";
import Error from "components/utils/Error";
import ApiConstants from "constant";
import axios from "axios";
import generatePDF from "documents";
import Loading from "components/utils/Loading";
import { remove_style_script_home } from "../utils";
import { masking_transition } from "../utils";

import { useLocation, useHistory } from "react-router-dom";
import { MdVerifiedUser } from "react-icons/md";


import moment from "moment";
import "moment/locale/fr";
import { useAuth } from "AuthProvider";
moment.locale("fr");

function AcceptDevisView(props) {
	const auth = useAuth();
	const location = useLocation();
	const history = useHistory();
	const [isLoading, setIsLoading] = useState(false);
	const [isFound, setIsFound] = useState(true);
	const [isError, setIsError] = useState(false);
	const [error, setError] = useState("");
	let marchand = location.pathname === "/home/accept/devis" ? JSON.parse(localStorage.getItem('marchand')) : auth.marchand;
	let childLinkForBoostrap = null;
	let childLinkForLightBoostrapReactv2_0_0 = null;
	let childLinkForStyleFreeDevis = null;
	let childScriptShow = null;

	const [invoice, setInvoice] = useState({
		"num_facturation": "",
		"surface": "0",
		"epaisseur": "0",
		"type_service": "",
		"date_echeance": "",

		"client": {
			"name": "",
		}, "ligne_articles": []
	});

	const [invoiceSummary, setInvoiceSummary] = useState({});

	const [showModal, setShowModal] = useState(false);
	const [showModalConfirmation, setShowModalConfirmation] = useState(false);
	const [dateErrorMessage, setDateErrorMessage] = useState("");
	const [date_realisation_debut, setDate_realisation_debut] = useState("");


	const handleChangeStateInvoice = (response) => {


		if (response.status === 200) {
			setIsLoading(false);

			if (location.pathname === "/home/accept/devis") {
				history.push({
					pathname: "/home",
				});
			} else if (location.pathname === "/welcome/accept/devis") {
				history.push({
					pathname: "/welcome/devis",
				});
			}
		} else if (response.status == 404) {
			setIsLoading(false);
			setIsFound(false);
		}
	};

	const handleConvertError = (error) => {
		setIsLoading(false);
		setIsError(true);
		setError(error);
		setIsFound(false);
	};

	function formatDate(dateString) {
		const date = new Date(dateString);
		const year = date.getFullYear().toString().slice(-2);
		const month = (date.getMonth() + 1).toString().padStart(2, "0");
		const day = date.getDate().toString().padStart(2, "0");
		return `${day}-${month}-${year}`;
	}

	const handleChangeStatusInvoiceClick = (e) => {
		e.preventDefault();
		setIsLoading(true);
		let url = ApiConstants.baseUrlRequest + ApiConstants.setStatusInvoice;

		axios
			.put(url, {
				username:
					location.pathname === "/home/accept/devis"
						? "melvain"
						: location.pathname === "/welcome/accept/devis"
							? auth.user.username
							: null,
				num_facturation: invoice.num_facturation,
			})
			.then(handleChangeStateInvoice)
			.catch(handleConvertError);
	};

	const handleCloseModal = () => {
		setDateErrorMessage("");
		setShowModal(false);
	};

	const handleDateChange = (e) => {
		setDate_realisation_debut(e.target.value);
		setDateErrorMessage("");
	};

	const getMinDate = () => {
		const today = new Date();
		today.setDate(today.getDate() + 10);
		const minDate = today.toISOString().split("T")[0];
		return minDate;
	};

	const handleSendEmail = (e) => {
		e.preventDefault();


		// Envoi de l'e-mail ici
		let url = ApiConstants.baseUrlRequest + ApiConstants.setPdfToSend;

		let pdf = generatePDF(
			invoice,
			"devis",
			marchand,
			"base64"
		);

		let pdfBase64 = pdf.split("base64,")[1];

		axios
			.post(url, {
				num_facturation: invoice.num_facturation,
				encodedFile: pdfBase64,
			})
			.then((response) => {
				// Gérer la réponse de l'envoi de l'e-mail ici

				// Vérifier la réponse de l'envoi de l'e-mail et effectuer les actions nécessaires
				if (response.status === 200) {

					if (location.pathname === "/home/accept/devis") {
						props.history.push({
							pathname: "/home",
						});
					} else if (location.pathname === "/welcome/accept/devis") {
						props.history.push({
							pathname: "/welcome/devis",
						});
					}
				} else if (response.data.status === 404) {
					setIsFound(false);
				}
			});
	};

	useEffect(() => {
		//REmove all home style s and script
		remove_style_script_home();


		// Adding bootstrap
		let linkForBoostrap = document.createElement("link");
		linkForBoostrap.id = "bootstrap_free_devis";
		linkForBoostrap.type = "text/css";
		linkForBoostrap.rel = "stylesheet";
		linkForBoostrap.href = "/css/bootstrap.min.css";
		childLinkForBoostrap = document.head.appendChild(linkForBoostrap);

		// Adding style for free devis
		let linkForStyleFreeDevis = document.createElement("link");
		linkForStyleFreeDevis.id = 'style_free_devis';
		linkForStyleFreeDevis.type = 'text/css';
		linkForStyleFreeDevis.rel = 'stylesheet';
		linkForStyleFreeDevis.href = '/css/style_free_devis.css'
		childLinkForStyleFreeDevis = document.head.appendChild(linkForStyleFreeDevis)

		// Adding light bootstrap
		let linkLightBoostrapReactv2_0_0 = document.createElement("link");
		linkLightBoostrapReactv2_0_0.type = "text/css";
		linkLightBoostrapReactv2_0_0.id = "style_lightBoostrapReactv2_0_0";
		linkLightBoostrapReactv2_0_0.rel = "stylesheet";
		linkLightBoostrapReactv2_0_0.href = "/css/light-bootstrap-dashboard-react.css";
		childLinkForLightBoostrapReactv2_0_0 = document.head.appendChild(linkLightBoostrapReactv2_0_0);

		//script for masking transition
		childScriptShow = masking_transition("show_accept_devis_home_page", "accept_devis_home_page", 'visible')


		let current_devis_created = localStorage.getItem("current_devis_created");
		let num_facturation = current_devis_created;


		if (num_facturation == null) {


		}

		let queryParams = {
			"num_facturation": num_facturation
		};



		let url = ApiConstants.baseUrlRequest + ApiConstants.getResumeInvoice;
		axios
			.get(url, { params: queryParams })
			.then(function (response) {
				if (response.status == 200) {
					setIsLoading(false);
					setIsFound(true);
					setInvoice(response.data.facturation);
					const ligne_articles = response.data.facturation.ligne_articles;
					let tempInvoiceSummary = {}
					ligne_articles.map(function (item) {

						if (tempInvoiceSummary[item.type_service] === undefined) {
							const test = item.article.type === "Option" || item.article.type === "matériau supplémentaire" || item.article.type === "service supplémentaire" || item.article.type === "forfait extérieur"
							tempInvoiceSummary[item.type_service] = {
								"surface": item.quantite,
								"epaisseur": item.thickness_service,
								"options": test ? [{
									"libelle": item.article.libelle
								}] : []
							}
						} else {
							if (item.article.type === "Option" || item.article.type === "matériau supplémentaire"
								|| item.article.type === "service supplémentaire" || item.article.type === "forfait extérieur") {
								tempInvoiceSummary[item.type_service]["options"].push({
									"libelle": item.article.libelle
								})
							}
						}
					});
					setInvoiceSummary(tempInvoiceSummary);

				} else if (response.status == 404) {
					setIsLoading(false);
					setIsFound(false);
				}
			})
			.catch(function (error) {
				setIsError(true);
				setError(error);
			});

		return () => {
			if (childLinkForBoostrap) {
				document.head.removeChild(childLinkForBoostrap);
			}
			if (childLinkForLightBoostrapReactv2_0_0) {
				document.head.removeChild(childLinkForLightBoostrapReactv2_0_0);
			}

			if (childLinkForStyleFreeDevis) {
				document.head.removeChild(childLinkForStyleFreeDevis);
			}

			if (childScriptShow) {
				document.body.removeChild(childScriptShow);
			}

		}
	}, []);

	if (isLoading === true) {
		return (
			<Container fluid>
				<div className="d-flex justify-content-center align-items-center">
					<Loading isLoading={isLoading} />
				</div>
			</Container>
		);
	}
	if (isLoading === false && isFound === false) {
		return <Error code={404} />;
	}

	if (isError === true) {
		if (error.message === "Network Error") {
			return <Error code={-1} />;
		}
		return <Error code={-2} />;
	}

	return (
		<div id="accept_devis_home_page" style={{ visibility: 'hidden' }}>
			<Container fluid className="p-5">
				<Row>
					<Col sm="6" className="d-flex justify-content-left align-items-left">
						<figure>
							<img
								style={{ height: '150px' }}
								src={require("assets/img/logo.png")}
								alt="..."
							/>
						</figure>
					</Col>

					<Col sm="6" className="d-flex justify-content-end align-items-center">
						{location.pathname === "/home/accept/devis" ? null : (
							<AiOutlineDownload size={40} />
						)}
					</Col>
				</Row>
				<Row>
					<Col
						md="12"
						className="d-flex justify-content-center align-items-center"
					>
						<div className="btn btn-fill btn-sm btn-success py-1 px-2 rounded">
							<h3 className="text-white m-0 p-0">Félicitations,vous venez de créer votre devis!</h3>
						</div>
					</Col>
				</Row>
				<Row className="d-flex align-items-center justify-content-between my-5">
					<Col
						md="12"
						className="d-flex justify-content-center align-items-center"
					>
						<Card>
							<Card.Header>
								<Card.Title as="h4" style={{ fontWeight: "bold" }}>
									Note importante
								</Card.Title>
							</Card.Header>
							<Card.Body>

								<p>
									Votre devis est prêt et nous sommes heureux de vous présenter votre offre. Dans 9 cas sur 10, votre devis est cohérent et conforme à vos besoins. Le programme sélectionne automatiquement la technique et le type d'isolation qui conviennent le mieux à votre chantier, qu'il s'agisse de pulvérisation de mousse, d'insufflation de ouate de cellulose, ou d'autres techniques. Cependant, un de nos techniciens passera systématiquement vérifier la faisabilité des travaux et, le cas échéant, il sera possible d'ajuster le type d'isolation. Nous proposons toutes les techniques d'isolation, y compris des options bio-sourcées et écologiques.
								</p>
								<p>
									Vous pouvez maintenant choisir de valider votre devis en planifiant une date d’intervention ou de l’enregistrer pour le finaliser plus tard.
								</p>
							</Card.Body>
						</Card>
					</Col>
				</Row>
				<Row className="d-flex align-items-center justify-content-between my-5">
					<Col md="12" className="">
						<Card>
							<Card.Header className="text-center">
								<Card.Title
									as="h4"
									style={{ fontWeight: "bold" }}
									className="my-4"
								>
									Aperçu du devis
								</Card.Title>
							</Card.Header>
							<Card.Body>

								{/* <Table
                  className="w-100 my-4"
                  style={{ borderCollapse: "collapse" }}
                  bordered hover
                >
                   <caption style={{textAlign: "center", captionSide : "top", fontWeight:"bold", color : "black"}}>{invoice.client.name}</caption>
                  <tbody>
                    {Object.keys(invoiceSummary).map(
                      (key) => (
                        <>
                          <tr>
                            <td colSpan={3} style={{textAlign:"center"}}>
                              <span style={{fontWeight:"bold", fontSize: "16px"}}>Application :</span> {key}
                            </td>
                          </tr>
                          <tr >
                            <td style={{textAlign : "center", fontWeight:"bold"}}>
                              Surface
                            </td>
                            <td style={{textAlign : "center", fontWeight:"bold"}}>
                              Epaisseur
                            </td>
                            <td style={{textAlign : "center", fontWeight:"bold"}}>
                              Option
                            </td>
                          </tr>

                          
                             
                            <tr >
                              <td style={{textAlign : "center"}}>
                                {invoiceSummary[key].surface}
                              </td>
                              <td style={{textAlign : "center"}}>
                                {invoiceSummary[key].epaisseur}
                              </td>
                              <td style={{display: "flex", justifyContent : "start"}}>
                                <ul >
                                {invoiceSummary[key].options.map((item) => (
                                
                                  <li className="form-label"> {item.libelle} </li>
                                
                                ))}
                                </ul>
                              </td>
                            </tr>

                          
                        </>
                      )
                    )}
                  </tbody>
                </Table> */}



								{/* {invoice.ligne_articles.map((item, index) => (
                <Row>
                  <Col md="6" className="mb-3">
                    <div class="row align-items-end custom-line">
                      <div class="col">Nom client: </div>
                      <div class="col" style={{ fontWeight: "bold" }}>
                        {invoice.client.name}
                      </div>
                    </div>
                  </Col>

                  <Col md="6" className="mb-3">
                    <div className="row align-items-end custom-line">
                      <div className="col">Surface </div>
                      <div className="col" style={{ fontWeight: "bold" }}>
                        {item.quantite}m²
                      </div>
                    </div>
                  </Col>
                  <Col md="6" className="mb-3">
                    <div className="row align-items-end custom-line">
                      <div className="col">Épaisseur </div>
                      <div className="col" style={{ fontWeight: "bold" }}>
                        {item.article.thickness}cm
                      </div>
                    </div>
                  </Col>
                  <Col md="6" className="mb-3">
                    <div className="row align-items-end custom-line">
                      <div className="col">Application </div>
                      <div className="col" style={{ fontWeight: "bold" }}>
                        {item.type_service}
                      </div>
                    </div>
                  </Col>
                </Row>
                  ))} */}

								<table
									className="w-100 my-4"
									style={{ borderCollapse: "collapse" }}
								>
									<tbody>
										<tr>
											<td
												style={{
													border: "1px solid black",
													textAlign: "center",
													fontWeight: "bold",
												}}
											>
												{invoice.num_facturation}
											</td>
											<td
												style={{
													border: "1px solid black",
													textAlign: "center",
													fontWeight: "bold",
												}}
											>
												N° TVA Client : {invoice.client.tva ? invoice.client.tva + "%" : "Aucun"}
											</td>
											<td
												style={{
													border: "1px solid black",
													textAlign: "center",
													fontWeight: "bold",
												}}
											>
												Noms du client : {invoice.client.name}
											</td>
											<td
												style={{
													border: "1px solid black",
													textAlign: "center",
													fontWeight: "bold",
												}}
											>
												{formatDate(invoice.date_echeance)}
											</td>
										</tr>
									</tbody>
								</table>
								<table
									className="w-100 my-4"
									style={{ borderCollapse: "collapse" }}
								>
									<thead>
										<tr>
											<th
												style={{
													border: "1px solid black",
													textAlign: "center",
													fontWeight: "bold",
												}}
											>
												Quantité
											</th>
											<th
												style={{
													border: "1px solid black",
													textAlign: "center",
													fontWeight: "bold",
												}}
											>
												Unité
											</th>
											<th
												style={{
													border: "1px solid black",
													textAlign: "center",
													fontWeight: "bold",
												}}
											>
												Description
											</th>
											<th
												style={{
													border: "1px solid black",
													textAlign: "center",
													fontWeight: "bold",
												}}
											>
												Prix unitaire
											</th>
											<th
												style={{
													border: "1px solid black",
													textAlign: "center",
													fontWeight: "bold",
												}}
											>
												Total
											</th>

										</tr>
									</thead>
									<tbody>
										{invoice.ligne_articles.map(
											(article, index) => (
												<tr key={index}>
													<td
														style={{
															border: "1px solid black",
															textAlign: "center",
														}}
													>
														{article.quantite}
													</td>
													<td
														style={{
															border: "1px solid black",
															textAlign: "center",
														}}
													>
														{article.article.unit}
													</td>
													<td style={{ border: "1px solid black" }}>
														<div>
															<strong>{article.article.libelle}</strong>
														</div>
														<div>{article.article.description}</div>
														<div>
															<em>
																Application:{" "}
																{article.type_service === 'sol_rez_de_chausse' ? 'sol rez-de-chaussée' : article.type_service}
															</em>
														</div>
														<div>
															<em>
																{article.article.conductivity
																	? 'Conductivité: ' + article.article.conductivity + " W/m∙K"
																	: ''}
															</em>
														</div>
														<div>
															<em>
																{["ouate de cellulose", "Polystyrène", "Ciment", "Mousse Polyuréthane"].includes(article.article.libelle) && article.thickness_service 
																	? 'Épaisseur: ' + article.thickness_service + " cm" 
																	: ''}
															</em>
														</div>
														<div>
															<em>
																{article.article.thermal_resistance
																	? 'Résistance thermique: ' + article.article.thermal_resistance + " m²∙K/W"
																	: ""}
															</em>
														</div>
													</td>
													<td
														style={{
															border: "1px solid black",
															textAlign: "center",
														}}
													>
														{(article.price / article.quantite).toFixed(2)} €
													</td>
													<td
														style={{
															border: "1px solid black",
															textAlign: "center",
														}}
													>
														{article.price.toFixed(2)} €
													</td>
													{/* <td
                            style={{
                              border: "1px solid black",
                              textAlign: "center",
                            }}
                          >
                            {}
                          </td> */}
												</tr>
											)
										)}
									</tbody>
								</table>
								<div style={{ display: "flex", justifyContent: "flex-end" }}>
									<table
										style={{
											borderCollapse: "collapse",
											textAlign: "left",
											width: "300px",
										}}
									>
										<tbody>
											<tr>
												<td
													style={{
														border: "1px solid black",
														fontWeight: "bold",
													}}
												>
													Total HTVA
												</td>
												<td style={{ border: "1px solid black" }}>
													{invoice.montant_ht} €
												</td>
											</tr>
											<tr>
												<td
													style={{
														border: "1px solid black",
														fontWeight: "bold",
													}}
												>
													TVA à {invoice.tva} %
												</td>
												<td style={{ border: "1px solid black" }}>
													{invoice.montant_tva} €
												</td>
											</tr>
											<tr>
												<td
													style={{
														border: "1px solid black",
														fontWeight: "bold",
													}}
												>
													Total TVAC
												</td>
												<td style={{ border: "1px solid black" }}>
													{invoice.montant_ttc} €
												</td>
											</tr>
										</tbody>
									</table>
								</div>
								<div style={{ textAlign: "center", marginBottom: "10px" }}>
									OFFRE VALABLE 15 JOURS
								</div>





							</Card.Body>
						</Card>
					</Col>
				</Row>
				<Row className="d-flex align-items-center justify-content-between my-5">
					<Col md="12" className="">
						<Card>
							<Card.Header>
								<Card.Title as="h4" style={{ fontWeight: "bold" }}>
									Cette offre vous intéresse ?
								</Card.Title>
							</Card.Header>
							<Card.Body>
								<p>
									Cliquez sur le bouton <strong>« je choisis la date d’intervention sur mon chantier »</strong> pour sélectionner une date qui vous convient et valider votre devis. Une fois la date choisie, votre devis sera validé et enregistré dans votre compte.
								</p>

								<p>
									Votre chantier n’est pas encore prêt et vous avez besoin de réflexion ? Pas de souci. Cliquez sur <strong>« j’enregistre mon devis et je déciderai plus tard »</strong>  pour sauvegarder votre offre et y revenir plus tard. Vous pourrez ainsi finaliser votre devis à tout moment depuis votre compte.
								</p>

							</Card.Body>
						</Card>
					</Col>
				</Row>
				<Row className="d-flex justify-content-evenly">
					<Col sm="12" md="6" className=" d-flex justify-content-center ">
						<Button
							className="btn-fill mb-3"
							variant="success"
							size="xl"
							onClick={(e) => {

								e.preventDefault();
								if (location.pathname === "/home/accept/devis") {
									setShowModal(true);

								} else if (location.pathname === "/welcome/accept/devis") {
									setShowModal(true);

								}
							}}
						>
							Je choisis la date d’intervention sur mon chantier
						</Button>
					</Col>

					<Col m sm="12" md="6" className=" d-flex justify-content-center">
						<Button
							className="btn-fill mb-3"
							variant="danger"
							size="xl"
							onClick={(e) => {
								e.preventDefault();
								setIsLoading(true)
								let url =
									ApiConstants.baseUrlRequest + ApiConstants.updateDevis;



								const data = {
									num_facturation: invoice.num_facturation,
									status: "refuse",
									date_realisation_debut: null,
								};


								axios
									.put(url, data)
									.then(function (response) {
										if (response.data.status == 200) {
											let emailUrl =
												ApiConstants.baseUrlRequest +
												ApiConstants.setPdfToSend;



											let pdf = generatePDF(
												invoice,
												"Devis",
												marchand,
												"base64"
											);


											let pdfBase64 = pdf.split("base64,")[1];



											const datamail = {
												num_facturation: invoice.num_facturation,
												encodedFile: pdfBase64,
											};

											axios
												.post(emailUrl, datamail)
												.then(function (response) {
													if (response.data.status == 200) {
														setIsLoading(false)
														setShowModalConfirmation(true)
													} else if (response.data.status == 404) {
														// setIsLoading(false);
														setIsFound(false);
													}
												});

										} else if (response.data.status == 404) {
											setIsFound(false);
										}
									})
									.catch(function (error) {
										setIsError(true);
										setError(error);
									});
							}}
						>
							J’enregistre mon devis et je déciderai plus tard
						</Button>
					</Col>
				</Row>
			</Container>


			<Modal show={showModal} onHide={handleCloseModal} id="accept_modal">
				<Modal.Header closeButton className="d-flex justify-content-center">
					<Modal.Title className="justify-content-center">
						Votre période de travail
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<p>A quelle date désirez-vous débuter vos travaux</p>
					<Form>
						<Row>
							<Col>
								<Form.Group
									as={Row}
									className="mb-3"
									controlId="date_realisation_debut"
								>
									<Form.Label column sm="2">
										date
									</Form.Label>
									<Col sm="10">
										<Form.Control
											type="date"
											value={date_realisation_debut}
											onChange={handleDateChange}
											min={getMinDate()}
										/>
									</Col>
								</Form.Group>
							</Col>
						</Row>

						<Row>
							<Col>
								<p className="text-danger mt-3">{dateErrorMessage}</p>
							</Col>
						</Row>
					</Form>
				</Modal.Body>
				<Modal.Footer className="d-flex justify-content-center">
					<Button
						variant="success"
						className="btn-fill"
						onClick={(e) => {
							e.preventDefault();
							if (date_realisation_debut == "" || date_realisation_debut == null) {
								setDateErrorMessage(
									"Veuillez renseigner la date de début."
								);
							} else {
								setIsLoading(true);

								let url =
									ApiConstants.baseUrlRequest + ApiConstants.updateDevis;



								const data = {
									num_facturation: invoice.num_facturation,
									status: "accepte",
									date_realisation_debut: date_realisation_debut,
								};


								axios
									.put(url, data)

									.then(function (response) {

										if (response.data.status == 200) {


											let emailUrl =
												ApiConstants.baseUrlRequest +
												ApiConstants.setPdfToSend;



											let pdf = generatePDF(
												invoice,
												"Devis",
												marchand,
												"base64"
											);


											let pdfBase64 = pdf.split("base64,")[1];



											const datamail = {
												num_facturation: invoice.num_facturation,
												encodedFile: pdfBase64,
											};



											axios
												.post(emailUrl, datamail)
												.then(function (response) {
													if (response.data.status == 200) {
														setIsLoading(false)
														setShowModal(false)
														setShowModalConfirmation(true)
													} else if (response.data.status == 404) {
														setIsLoading(false);
														setIsFound(false);
													}
												});
										} else if (response.data.status == 404) {
											setIsLoading(false);
											setIsFound(false);
										}
									});

							}
						}}
					>
						Confirmer
					</Button>
				</Modal.Footer>
			</Modal>


			<Modal show={showModalConfirmation} onHide={handleCloseModal} id="accept_modal">
				{/* <Modal.Header closeButton className="d-flex justify-content-center">
					<Modal.Title className="justify-content-center">
						Confirmation création et envoie de mail
					</Modal.Title>
				</Modal.Header> */}
				<Modal.Body>
					<center><MdVerifiedUser style={{ color: '#8ea841', fontSize: '100px', marginBottom: '20px' }} /><p>Votre devis a été créé et envoyé avec succès par mail.<br /> Au plaisir de vous revoir à bientôt.</p></center>
				</Modal.Body>
				<Modal.Footer className="d-flex justify-content-center">
					<Button
						variant="success"
						className="btn-fill"
						onClick={(e) => {
							e.preventDefault()
							if (location.pathname === "/home/accept/devis") {

								window.location.href = '/home'
							} else if (
								location.pathname === "/welcome/accept/devis"
							) {
								history.push({
									pathname: "/welcome/devis",
								});
							}

							localStorage.removeItem('current_devis_created')
							setShowModalConfirmation(false)
						}}
					>
						OK
					</Button>
				</Modal.Footer>
			</Modal>


		</div>
	);
}

export default AcceptDevisView;
