import CreateQuotationWithFlowchart from "views/create-quotation-with-flowchart";
import AccountCreate from "views/AccountCreate";
import AcceptDevisView from "views/AcceptDevisView";


const homeRoutes = [
  {
    path: "",
    component: CreateQuotationWithFlowchart,
    layout: "/home",
  },
  {
    path: "/create_account",
    component: AccountCreate,
    layout: "/home",
  },
  {
    path: "/accept/devis",
    component: AcceptDevisView,
    layout: "/home",
  },
  
];

export default homeRoutes;
