import React, { useEffect } from "react";
import {
  Container,
  Form,
  Row,
  Col,
  FloatingLabel,
  Button,
} from "react-bootstrap";
import { NavLink } from "react-router-dom";
import ApiConstants from "constant";
import axios from "axios";
import { Dots } from "react-activity";
import "react-activity/dist/library.css";
import Error from "components/utils/Error";
import Loading from "components/utils/Loading";
import { useAuth } from "AuthProvider";
import { useState, useRef } from "react";
import { remove_style_script_home } from "../utils";
import { masking_transition } from "../utils";
import { Spinner } from "react-activity";
import { IoIosWarning } from "react-icons/io";

const otp = (props) => {
  const auth = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [isFound, setIsFound] = useState(true);
  const [isError, setIsError] = useState(false);
  const [error, setError] = useState("");
  const [otpErrorMessage, setotpErrorMessage] = useState("");
  const [validated, setValidated] = useState(false);
  const connectionMethod = localStorage.getItem("connection_method");
  const usernameType = localStorage.getItem("username_type");
  const url = localStorage.getItem("url");

  const otp1 = useRef();
  const otp2 = useRef();
  const otp3 = useRef();
  const otp4 = useRef();
  const otp5 = useRef();
  const otp6 = useRef();

  let childScriptOtp = null;
  let childLinkForStyleForOtp = null;
  let childLinkForIconForOtp = null;
  let childScriptShow = null;
  useEffect(() => {
    //remove all home styles
    remove_style_script_home();

    // Adding style for otp
    let linkForStyleForOtp = document.createElement("link");
    linkForStyleForOtp.id = "style_for_connection";
    linkForStyleForOtp.type = "text/css";
    linkForStyleForOtp.rel = "stylesheet";
    linkForStyleForOtp.href = "/css/style_for_otp.css";
    childLinkForStyleForOtp = document.head.appendChild(linkForStyleForOtp);

    let linkForIconForOtp = document.createElement("link");
    linkForIconForOtp.id = "style_for_connection_icon";
    linkForIconForOtp.type = "text/css";
    linkForIconForOtp.rel = "stylesheet";
    linkForIconForOtp.href =
      "https://unpkg.com/boxicons@2.1.4/css/boxicons.min.css";
    childLinkForIconForOtp = document.head.appendChild(linkForIconForOtp);

    //Addind Script for otp
    let script_otp = document.createElement("script");
    script_otp.type = "text/javascript";
    script_otp.id = "otp-js";
    script_otp.src = "/js/script_otp.js";
    childScriptOtp = document.body.appendChild(script_otp);

    //script for masking transition
    childScriptShow = masking_transition(
      "show_otp_page",
      "otp_page",
      "visible",
    );

    // returned function will be called on component unmount
    return () => {
      if (childLinkForStyleForOtp) {
        document.head.removeChild(childLinkForStyleForOtp);
      }
      if (childLinkForIconForOtp) {
        document.head.removeChild(childLinkForIconForOtp);
      }

      if (childScriptOtp) {
        document.body.removeChild(childScriptOtp);
      }
      if (childScriptShow) {
        document.body.removeChild(childScriptShow);
      }
    };
  }, []);

  const handleIsLoading = (value) => {
    setIsLoading(value);
  };

  const handleValidated = (value) => {
    setValidated(value);
  };

  const handleIsFound = (value) => {
    setIsFound(value);
  };

  const handleIsError = (value) => {
    setIsError(value);
  };

  const handleError = (value) => {
    setError(value);
  };

  const handleOtpErrorMessage = (value) => {
    setotpErrorMessage(value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.stopPropagation();
    } else {
      // use loginAction of context
      auth.otpAction(
        {
          otp:
            otp1.current.value +
            otp2.current.value +
            otp3.current.value +
            otp4.current.value +
            otp5.current.value +
            otp6.current.value,
          username: localStorage.getItem("username"),
          connection_method: localStorage.getItem("connection_method"),
          username_type: localStorage.getItem("username_type"),
          uniqueid: localStorage.getItem("uniqueid"),
        },
        handleIsLoading,
        handleIsFound,
        handleIsError,
        handleError,
        handleOtpErrorMessage,
      );
    }
    setValidated(true);
  };

  if (isLoading === false && isFound === false) {
    return <Error code={404} />;
  }

  if (isError === true) {
    if (error.message === "Network Error") {
      return <Error code={-1} />;
    }
    return <Error code={-2} />;
  }

  return (
    <div className="container" id="otp_page" style={{ visibility: "hidden" }}>
      <header>
        <i className="bx bxs-check-shield" />
      </header>
      <p style={{ color: '#F9F9EA', textAlign:'center' }}>
            {
              connectionMethod ==='ga' ?  "Consulter le code OTP sur votre application GA." : (connectionMethod ==='otp' ? (usernameType === 'email' ? 'Le code OTP vous a été envoyé par mail.' : (usernameType === 'sms' ? 'Le code OTP vous a été envoyé par SMS.' : (usernameType === 'whatsApp'? 'Le code OTP vous a été envoyé par WhatsApp.': ''))):'' )}
          </p>
      <h4>Entrer le code OTP</h4>
      {url !== null && url.length !== 0 ? (
        <div className="">
          <figure style={{ textAlign: "center" }}>
            <img src={url} />
            <figcaption>
              <p>
                {" "}
                Scanner ce QR Code avec votre application Google Autheticator.
              </p>
              <p>
                {" "}
                Vérifier le code générer par votre application pour vous
                connecter.
              </p>
              <p
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {" "}
                <IoIosWarning size={30} style={{ color: "#f0ad4e" }} />{" "}
                <span>Ce QR Code apparaît une seule fois.</span>{" "}
              </p>
            </figcaption>
          </figure>
        </div>
      ) : null}
      <form noValidate validated={validated} onSubmit={(e) => handleSubmit(e)}>
        <div className="input-field">
          <input type="number" ref={otp1} />
          <input type="number" disabled ref={otp2} />
          <input type="number" disabled ref={otp3} />
          <input type="number" disabled ref={otp4} />
          <input type="number" disabled ref={otp5} />
          <input type="number" disabled ref={otp6} />
        </div>
        <p style={{ color: "red", textAlign: "center" }}>{auth.otpError}</p>

        <div>
                <div className="mb-3">
                  <a href="/home">
                    Retour à l'accueil
                  </a>
                </div>

                <div>
                  <a href="/home/connection">
                    Connectez-vous
                  </a>
                </div>
              </div>

        <button type="submit">
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <span style={{ marginRight: "1rem" }}>Vérifier OTP</span>
            <Spinner color="#8EA841" size={10} speed={1} animating={isLoading} />
          </div>
        </button>
        <p style={{ color: "red" }}>{otpErrorMessage}</p>
      </form>
    </div>
  );
};

export default otp;
