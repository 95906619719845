import React from "react";

// react-bootstrap components
import {
  Badge,
  Button,
  Card,
  Form,
  Navbar,
  Table,
  Nav,
  Container,
  Row,
  Col,
} from "react-bootstrap";
import { Label } from "reactstrap";
import Pagination from "components/utils/Pagination";
import { Link } from "react-router-dom";
import { NavLink } from "react-router-dom";
import { FaSearch } from "react-icons/fa";
import { BsCartCheckFill, BsFillTrashFill } from "react-icons/bs";
import { AiOutlineFileDone } from "react-icons/ai";
import DataTable from "react-data-table-component";
import axios from "axios";

import { useState, useEffect, useRef } from "react";

import { Dots } from "react-activity";
import "react-activity/dist/library.css";

import ApiConstants from "constant";

import Error from "components/utils/Error";

import { VscVmActive } from "react-icons/vsc";
import { useAuth } from "AuthProvider";
import { useHistory, useLocation } from "react-router-dom";

function Adresse() {
  const auth = useAuth();
  const history = useHistory();
  const location = useLocation();
  const [clients, setClients] = useState([]);
  const [adresses, setAdresses] = useState([]);
  const [filterclients, setFilterClients] = useState([]);
  const [applications, setApplications] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isFound, setIsFound] = useState(false);
  const [isError, setIsError] = useState(false);
  const [error, setError] = useState("");
  const code_client = useRef();
  const name = useRef();
  const country = useRef();
 
  const [responseTime, setResponseTime] = useState(0);

  const handleResponseFetchApplications = (response) => {
    if (response.data.status == 200) {
      let applications = [];
      response.data.Cibles.map((application) => {
        applications.push({
          value: application.id.toString(),
          label: application.libelle,
        });
      });
      setApplications(applications);
    }
  };

  const fetchApplications = () => {
    let url = ApiConstants.baseUrlRequest + ApiConstants.getApplications;

    axios.get(url).then(handleResponseFetchApplications).catch(handleError);
  };



  const handleActiveClient = (response) => {
 
    if (response.status === 200) {
      setIsLoading(false);
   
      history.push({
        pathname: location.pathname,
      });
    }
  };

  const handleError = (error) => {
    if (error.isAxiosError) {
      if ("response" in error) {
        if (error.response.status === 401) {
        
          setIsLoading(false);
        } else if (error.response.status === 404) {
      
          setIsLoading(false);
          setIsFound(false);
        } else {
         
          setIsLoading(false);
          setIsError(true);
          setError(error);
        
        }
      } else {
        
        setIsLoading(false);
        setIsError(true);
        setError(error);
      }
    } else {
    
    }

    console.log(error);
  };

  const buttonStyle = {
    width: '30px',
    height: '30px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  };

  const iconStyle = {
    fontSize: '16px',
  };

  const column = [

    {
      name: "Nom (s)",
      selector: (row) => row.client && row.client.last_name,
      sortable: true,
    },
    {
      name: "Prénom(s)",
      selector: (row) => row.client && row.client.first_name,
      sortable: true,
    },
    {
      name: "Pays",
      selector: (row) => row.country,
      sortable: true,
    },
    {
      name: "Ville",
      selector: (row) => row.town,
      sortable: true,
    },
    {
      name: "Région",
      selector: (row) => row.region,
      sortable: true,
    },
   
    {
      name: "Actions",
      cell: (row) => (
        <div className=" col-10 d-flex align-items-center justify-content-between">
          <Link
            className="btn btn-sm btn-fill btn-icon btn-info"
            style={buttonStyle}
            data-toggle="tooltip"
            data-placement="top"
            title="Détails"
            data-original-title="Détails"
            to={{ pathname: "/welcome/adresseavalider/infos", state: {adresse : row } }}
          >
            <span className="btn-inner">
              <svg
                width="18"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="https://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M15.1614 12.0531C15.1614 13.7991 13.7454 15.2141 11.9994 15.2141C10.2534 15.2141 8.83838 13.7991 8.83838 12.0531C8.83838 10.3061 10.2534 8.89111 11.9994 8.89111C13.7454 8.89111 15.1614 10.3061 15.1614 12.0531Z"
                  stroke="currentColor"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M11.998 19.355C15.806 19.355 19.289 16.617 21.25 12.053C19.289 7.48898 15.806 4.75098 11.998 4.75098H12.002C8.194 4.75098 4.711 7.48898 2.75 12.053C4.711 16.617 8.194 19.355 12.002 19.355H11.998Z"
                  stroke="currentColor"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
              </svg>
            </span>
          </Link>
        </div>
      ),
    },
  ];

  useEffect(() => {
    const fetData = async () => {
      axios.interceptors.request.use((x) => {
        
        x.meta = x.meta || {};
        x.meta.requestStartedAt = new Date().getTime();
        return x;
      });
      axios.interceptors.response.use((x) => {
        x.responseTime = new Date().getTime() - x.config.meta.requestStartedAt;
        return x;
      });

      let url = ApiConstants.baseUrlRequest + ApiConstants.getAddresstovalidate;
      
      const data = {
        username: auth.user.username,
      };
     
      axios
        .get(url, { params: data })
        .then(function (response) {
          if (response.status == 200) {
            setIsLoading(false);
            setIsFound(true);
            setAdresses(response.data.adresses);
            setResponseTime(response.responseTime / 1000);
          } else if (response.status == 404) {
            setIsLoading(false);
            setIsFound(false);
          }
        })
        .catch(function (error) {
          setIsError(true);
          setError(error);
        });
    };
    fetData();
    fetchApplications();
  }, [location]);

  const handleFilter = (event) => {
    const newData = filterclients.filter((row) =>
      row.client.name.toLowerCase().includes(event.target.value.toLowerCase())
    );
    setClients(newData);
  };

  if (isLoading === false && isFound === true) {
    return (
      <Container fluid>
        <Row>
          <div className="clearfix"></div>
        </Row>
        {}
        <br />
        <Row>
          <Col md="12">
         
            <DataTable
              columns={column}
              data={adresses}
              pagination
              selectableRows
              responsive
              dense
              subHeader // Ajouter la propriété subHeader pour afficher la barre de recherche
              subHeaderComponent={
                <Col xl="3" lg="3" md="3">
                  <input
                  type="text"
                  value={filterclients.clients} // Utilisez la variable searchTerm pour la valeur du champ
                  onChange={handleFilter} // Utilisez la fonction handleFilter pour l'événement onChange
                  placeholder="Rechercher..."
                  className="form-control" /> 
                </Col>// Ajouter un élément <input> pour le champ de recherche
              }/>
          </Col>
        </Row>
        {}
      </Container>
    );
  }


  return (
    <Container fluid>
      <div className="d-flex justify-content-center align-items-center">
        <Dots color="#727981" size={32} speed={1} animating={isLoading} />
      </div>
    </Container>
  );
}

export default Adresse;
