import React from "react";
import SingleFile from "components/Uploader/SingleFile";
import {
    Badge,
    Button,
    Card,
    Form,
    Navbar,
    Nav,
    Container,
    Row,
    Col
  } from "react-bootstrap";

  import axios from "axios";

class SearchArticle extends React.Component {

    constructor(props){
        super(props);

        this.handleSubmit = this.handleSubmit.bind(this)
        this.handleResponse = this.handleResponse.bind(this)
    }
    handleResponse(response){
      
    }
    handleSubmit(event){
      event.preventDefault();

    };

    render(){
        return (
            <>
            <Container fluid>
              <Row>
                <Col sm="12" className="d-flex justify-content-center">
                  <Card  style={{ width: '50rem' }}>
                    <Card.Body>
                      <Form onSubmit={this.handleSubmit}> 
                        <Row>
                          <Col className="px-1" sm="12">
                            <Form.Group controlId="designation">
                                <Form.Label>Désignation</Form.Label>
                                <Form.Control
                                    placeholder="Désignation"
                                    type="text"
                                    ref={input => this.designation = input}
                                />
                            </Form.Group>
                          </Col>
                          <Col className="px-1" sm="12">
                            <Form.Group controlId="prix">
                              <Form.Label>Prix</Form.Label>
                              <Form.Control
                                placeholder="Prix"
                                type="number"
                                ref={input => this.price = input}
                              ></Form.Control>
                            </Form.Group>
                          </Col>
                        </Row>
                       
                       
                      <div  className="d-flex align-items-center justify-content-center py-3">
                        <Button className="btn-fill pull-right"  type="submit" variant="info">
                            Rechercher
                        </Button>
                      </div>
                    <div className="clearfix"></div>
                  </Form>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
              <Row>
                <Col sm="12">

                </Col>
              </Row>
            </Container>
          </>
        )
    }
}

export default SearchArticle;