import React from "react";
import ChartistGraph from "react-chartist";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import ApiConstants from "constant";
import {
  Badge,
  Button,
  Card,
  Navbar,
  Nav,
  Table,
  Container,
  Row,
  Col,
  Form,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { Dots } from "react-activity";
import "react-activity/dist/library.css";
import Error from "components/utils/Error";
import { useAuth } from "AuthProvider";
import { PieChart } from "../views/Piechart";

function Dashboard() {
  const auth = useAuth();
  const [convertedData, setConvertedData] = useState({
    results: { encaisse: [], paye: [], autre: [] },
  });
  const location = useLocation;
  const [dataInvoice, setDataInvoice] = useState([]);
  const colors = [
    'rgb(255, 99, 132)',   // Couleur pour "brouillon"
    'rgb(54, 162, 235)',    // Couleur pour "accepté"
    'rgb(255, 205, 86)',    // Couleur pour "validé"
    'rgb(75, 192, 192)',    // Couleur pour "en attente"
    'rgb(153, 102, 255)'    // Couleur pour "refusé"
];
  const [isLoading, setIsLoading] = useState(true);
  const [year, setYear] = useState(new Date().getFullYear());
  const [isFound, setIsFound] = useState(false);
  const [isError, setIsError] = useState(false);
  const [error, setError] = useState("");

  const fetchData = async (type) => {
    try {
      const response = await axios.get(
        ApiConstants.baseUrlRequest +
          ApiConstants.facturationsByStatusType +
          "/" +
          auth.user.username +
          "/" +
          type
      );
      if (response.status === 200) {
        const { results } = response.data;
        const convertedResult = results.reduce((acc, item) => {
          const { status, count } = item;
          // Réglez les valeurs de chaque état ici
          switch (type) {
            case "devis":
              acc["brouillon"] = !acc["brouillon"] ? 0 : acc["brouillon"];
              acc["accepte"] = !acc["accepte"] ? 0 : acc["accepte"];
              acc["valide"] = !acc["valide"] ? 0 : acc["valide"];
              acc["en attente"] = !acc["en attente"] ? 0 : acc["en attente"];
              acc["refusé"] = !acc["refusé"] ? 0 : acc["refusé"];
              break;
            case "facture":
              acc["brouillon"] = !acc["brouillon"] ? 0 : acc["brouillon"];
              acc["encaisse"] = !acc["encaisse"] ? 0 : acc["encaisse"];
              acc["paye"] = !acc["paye"] ? 0 : acc["paye"];
              break;
            default:
              acc["termine"] = !acc["termine"] ? 0 : acc["termine"];
              acc["brouillon"] = !acc["brouillon"] ? 0 : acc["brouillon"];
              break;
          }

          var st = "";
          // Réglez le nom de l'état ici
          if (status == "à encaisser") st = "encaisse";
          else if (status == "payée") st = "paye";
          else if (status == "validé") st = "valide";
          else if (status == "terminé") st = "termine";
          else if (status == "accepté") st = "accepte";
          else if (status == "brouillon") st = "brouillon";
          else if (status == "en attente") st = "en attente";
          else if (status == "refusé") st = "refusé";

          acc[st] = count;
          return acc;
        }, {});
        
        let total = 0;
        const keys = Object.keys(convertedResult);
        for (let index = 0; index < keys.length; index++) {
          total += convertedResult[keys[index]];
        }
        let series = [];
        let labels = [];
        let legend = [];
        for (let index = 0; index < keys.length; index++) {
          //series.push(convertedResult[keys[index]] / total);
          series.push((convertedResult[keys[index]] / total)*100);
          let colorIndex = index;
          // Si le label est "en attente", assurez-vous qu'il utilise la couleur correspondante dans le graphique
          if (keys[index] === 'en attente') {
              colorIndex = colors.length - 1; // Utiliser la dernière couleur dans la liste
          }
          legend.push({ label: keys[index], color: colors[colorIndex] });
        }

        for (let index = 0; index < series.length; index++) {
          //labels.push((series[index] * 100).toFixed(2) + "%");
          labels.push(keys[index]);

        }

        setIsLoading(false);
        setIsFound(true);
        return { type: type, series: series, labels: labels, legend: legend };
      } else if (response.status === 401) {
        setIsLoading(false);
      } else if (response.status === 404) {
        setIsLoading(false);
        setIsFound(false);
      } else {
        setIsLoading(false);
        setIsError(true);
        setError(error);
      }
    } catch (error) {
      setIsLoading(false);
      setIsError(true);
      console.error("Error fetching data:", error);
    }
  };

  const fetchAllData = async () => {
    const devisData = await fetchData("devis");
    const factureData = await fetchData("facture");
    const bonCommamde = await fetchData("bon_commande");
    setDataInvoice([devisData, factureData, bonCommamde]);
  };

  const fetchDataFirstGraph = async(newYear) => {
    try {
      let parametre = {
        username : auth.user.username,
        annee: newYear
      }

      let url = ApiConstants.baseUrlRequest + ApiConstants.facturationsAmountByMonthAndStatus;
      const response = await axios.get(url,{
        params : parametre,
      }
      );

      if (response.status === 200) {

        const { results } = response.data;
        let convertedResult = null;
        let encaisse = [0,0,0,0,0,0,0,0,0,0,0,0];
        let paye = [0,0,0,0,0,0,0,0,0,0,0,0];

        if (results.length !=0){

            convertedResult = results.reduce((acc, item) => {
            const { status, montant_ttc, month } = item;
            const monthIndex = month - 1;
            var st = "";
            if (status == "à encaisser") st = "encaisse";
            else if (status == "payée") st = "paye";
            else st = "autre";
  
            if (!acc[st]) {
              acc[st] = Array(12).fill(0);
            }
            acc[st][monthIndex] = montant_ttc;
            return acc;
          }, {});
        }
        else {
          convertedResult = {encaisse,paye};
        }
        // else {
        //   const results = 
        //   const convertedResult = 
        // }

        
        setConvertedData({ results: convertedResult });
        setIsLoading(false);
        setIsFound(true);
      } else if (response.status === 401) {
        setIsLoading(false);
      } else if (response.status === 404) {
        setIsLoading(false);
        setIsFound(false);
      } else {
        setIsLoading(false);
        setIsError(true);
        setError(error);
      }
    } catch (error) {
      if (error.isAxiosError) {
        if (!("response" in error)) {
          setIsLoading(false);
          setIsError(true);
          setError(error);
        }
      } else {
        console.log("No Axios error");
      }

      console.error("Error fetching data:", error);
    }
  };

  
  const handleDecreaseYear = () => {
    setYear(year - 1);
    fetchDataFirstGraph(year-1)
  };

  const handleIncreaseYear = () => {
    setYear(year + 1);
    fetchDataFirstGraph(year+1)
  };

  useEffect(() => {
    fetchAllData();
    fetchDataFirstGraph(year);
  }, [location]);

  if (isLoading === false && isFound === true) {
    return (
      <Container fluid>
        <Row>
          <Col
            sm="12"
            className="d-flex justify-content-between align-items-center mb-5 rounded"
          >
            <div>
              <h1 className="h4 m-0 p-0">Facturation</h1>
            </div>

           
          </Col>
        </Row>
        <Row>
          <Col sm="12">
            <Card>
              <Card.Header>
              <div className="d-flex justify-content-between align-items-center">
                <div>
                  <Button variant="primary" onClick={handleDecreaseYear}>
                    &lt;
                  </Button>{' '}
                </div>
                <div>
                  <Card.Title as="h4">Factures {year}</Card.Title>
                  <p className="card-category">Toutes les factures, y compris les taxes</p>
                </div>
                <div>
                  <Button variant="primary" onClick={handleIncreaseYear}>
                    &gt;
                  </Button>
                </div>
              </div>
              </Card.Header>
              <Card.Body>
              {convertedData.length !== 0 ? (
                <div className="ct-chart" id="chartActivity">
                  
                  <ChartistGraph
                    data={{
                      labels: [
                        "Jan",
                        "Feb",
                        "Mar",
                        "Apr",
                        "Mai",
                        "Jun",
                        "Jul",
                        "Aug",
                        "Sep",
                        "Oct",
                        "Nov",
                        "Dec",
                      ],

                      series: [
                        (convertedData.results.encaisse) ? convertedData.results.encaisse : [],
                        (convertedData.results.paye) ? convertedData.results.paye : [],
                    ],
                      
                    }}
                    type="Bar"
                    options={{
                      seriesBarDistance: 10,
                      axisX: {
                        showGrid: false,
                      },
                      height: "245px",
                    }}
                    responsiveOptions={[
                      [
                        "screen and (max-width: 640px)",
                        {
                          seriesBarDistance: 5,
                          axisX: {
                            labelInterpolationFnc: function (value) {
                              return value[0];
                            },
                          },
                        },
                      ],
                    ]}
                  />
                </div>
                 ) : (
                  <div>Aucune donnée disponible pour afficher le graphique.</div>
                )}
              </Card.Body>
              <Card.Footer>
                <div className="legend mb-3">
                  <i className="fas fa-circle text-info"></i>
                  Factures payées <i className="fas fa-circle text-danger"></i>
                  Factures non encaissées
                </div>
                
              </Card.Footer>
            </Card>
          </Col>
        </Row>
        <Row>
          {dataInvoice.map((invoice, index) => {
            return (
              <Col xl="4" lg="6" md="6"sm="12" xs="12" key={index}>
                <Card>
              <Card.Header>
                <Card.Title as="h4">
                  {invoice.type != "bon_commande" ? invoice.type : "Bon de commande"} Statistiques
                </Card.Title>
                <p className="card-category">Last Campaign Performance</p>
              </Card.Header>
              <Card.Body className="d-flex justify-content-center">
                <div className="ct-chart ct-perfect-fourth" id="chartPreferences">
                  <PieChart data={{ labels: invoice.labels, series: invoice.series, colors: invoice.colors }} />
                </div>
              </Card.Body>
            </Card>
              </Col>
            );
          })}
        </Row>
      </Container>
    );
  }

  if (isLoading === false && isFound === false) {
    return <Error code={404} />;
  }

  if (isError === true) {
    if (error.message === "Network Error") {
      return <Error code={-1} />;
    }
    return <Error code={-2} />;
  }

  return (
    <Container fluid>
      <div className="d-flex justify-content-center align-items-center">
        <Dots color="#727981" size={32} speed={1} animating={isLoading} />
      </div>
    </Container>
  );
}

export default Dashboard;
