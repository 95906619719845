import React, { useState, useEffect, useRef } from "react";
import { AiOutlineDownload } from "react-icons/ai";
import {
  Button,
  Card,
  Nav,
  Container,
  Row,
  Col,
  Form,
  Table,
} from "react-bootstrap";
import { NavLink } from "react-router-dom";
import ApiConstants from "constant";
import DataTable from "react-data-table-component";
import axios from "axios";
import { useAuth } from "AuthProvider";

import { useLocation } from "react-router-dom";
import Loading from "components/utils/Loading";

function ListePermissions() {
  const auth = useAuth();
  const [show, setShow] = useState(false);
  const location = useLocation();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [isLoading, setIsLoading] = useState(true);
  const [isFound, setIsFound] = useState(false);
  const [isError, setIsError] = useState(false);
  const [error, setError] = useState("");
  const [permissions, setPermissions] = useState([]);
  const [profil_visibilities, setProfil_visibilities] = useState([]);
  const [filterpermissions, setFilterPermissions] = useState([]);
  const name = useRef();
  const code = useRef();
  const visibility = useRef();
  const valeur = useRef();
  const [responseTime, setResponseTime] = useState(0);
  const [applications, setApplications] = useState([]);

  const handleResponseFetchApplications = (response) => {
    if (response.data.status == 200) {
      let applications = [];
      response.data.Cibles.map((application) => {
        applications.push({
          value: application.id.toString(),
          label: application.libelle,
        });
      });
      setApplications(applications);
    }
  };

  const fetchApplications = () => {
    let url = ApiConstants.baseUrlRequest + ApiConstants.getApplications;

    axios.get(url).then(handleResponseFetchApplications).catch(handleError);
  };

  const handleCheckboxChange = (permissionName, visibility) => {
    const updatedPermissions = [...permissions];
    const profil_visibilities = [...permissions];
    const updatedPermission = updatedPermissions.find(
      (p) => p.name === permissionName,
    );

    if (updatedPermission) {
      const codeVisibility = profil_visibilities.code;

      updatedPermission.visibility = visibility === 1 ? 0 : 1;

      profil_visibilities.code = codeVisibility;

      setPermissions(updatedPermissions);
    }
  };

  const column = [
    {
      name: "Liste permissions",
      selector: (row) => row.name,
      sortable: true,
    },

    {
      name: "Valeur",
      cell: (row) => {
        return (
          <div className="">
            <input
              className="border border-danger"
              id={row.code}
              type="checkbox"
              defaultValue={row.visibility}
              name={row.code}
              defaultChecked={row.visibility === 1}
              onChange={() => handleCheckboxChange(row.name, row.visibility)}
            />
            <label
              className=""
              htmlFor={row.code}
              style={{
                width: "25px",
                height: "20px",
                marginBottom: 5,
                display: "block",
              }}
            ></label>
            <div className="clear" />
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    const fetData = async () => {
      let url =
        ApiConstants.baseUrlRequest + ApiConstants.getProfilVisibilityEndpoint;
      const data = {
        code_client: location.state.client.code_client,
      };

      axios
        .get(url, { params: data })
        //axios.get(url)
        .then(function (response) {
          if (response.status == 200) {
            setIsLoading(false);
            setIsFound(true);
            setPermissions(response.data.profil_visibilities);
            setResponseTime(response.responseTime / 1000);
          } else if (response.status == 404) {
            setIsLoading(false);
            setIsFound(false);
          }
        })
        .catch(function (error) {
          setIsError(true);
          setError(error);
        });
    };
    fetData();
  }, [location]);

  const handleFilter = (event) => {
    const newData = filterclients.filter((row) =>
      row.client.name.toLowerCase().includes(event.target.value.toLowerCase()),
    );
    setClients(newData);
  };

  const handleError = (error) => {
    if (error.isAxiosError) {
      if ("response" in error) {
        if (error.response.status === 401) {
          setIsLoading(false);
        } else if (error.response.status === 404) {
          setIsLoading(false);

          setIsFound(false);
        } else {
          setIsLoading(false);
          setIsError(true);
          setError(error);
        }
      } else {
        setIsLoading(false);
        setIsError(true);
        setError(error);
      }
    } else {
      console.log("No Axios error");
    }

    console.log(error);
  };

  if (isLoading === false && isFound === false) {
    return <Error code={404} />;
  }

  if (isError === true) {
    if (error.message === "Network Error") {
      return <Error code={-1} />;
    }
    return <Error code={-2} />;
  }

  return (
    <>
      <Container fluid>
        <Row>
          <Row>
            <Col
              sm="6"
              className="d-flex justify-content-left align-items-left"
            >
              <figure>
                <img
                  size={10}
                  style={{ height: "150px" }}
                  src={require("assets/img/logo.png")}
                  alt="..."
                />
              </figure>
            </Col>

            <Col
              sm="6"
              className="d-flex justify-content-end align-items-center"
            >
              <AiOutlineDownload size={40} />
            </Col>
          </Row>
          <Row>
            <Col
              md="12"
              className="d-flex justify-content-center align-items-center"
            >
              <div className="bg-success py-1 px-2 rounded">
                {location.pathname === "/welcome/user/permissions" && (
                  <h3 className="text-white m-0 p-0">
                    Informations du partenaire
                  </h3>
                )}
                {location.pathname === "/welcome/client/permissions" && (
                  <h3 className="text-white m-0 p-0">Informations du client</h3>
                )}
                {location.pathname === "/welcome/soustraitant/permissions" && (
                  <h3 className="text-white m-0 p-0">
                    Informations du sous-traitant
                  </h3>
                )}
              </div>
            </Col>
          </Row>
          <Row className="d-flex align-items-center justify-content-between my-5">
            <Card>
              <Card.Body>
                <Row>
                  <Col md="4">
                    <div class="row align-items-end custom-line">
                      <div class="col">Code client: </div>
                      <div class="col" style={{ fontWeight: "bold" }}>
                        {location.state.client.code_client}
                      </div>
                    </div>
                  </Col>
                  <Col md="4">
                    <div class="row align-items-end custom-line">
                      <div class="col">Nom de famille: </div>
                      <div class="col" style={{ fontWeight: "bold" }}>
                        {location.state.client.last_name}
                      </div>
                    </div>
                  </Col>
                  <Col md="4">
                    <div class="row align-items-end custom-line">
                      <div class="col">Rue,numero,boite : </div>
                      <div class="col" style={{ fontWeight: "bold" }}>
                        {location.state.client.adresse.street}{" "}
                        {location.state.client.adresse.number_street}{" "}
                        {location.state.client.adresse.box}
                      </div>
                    </div>
                  </Col>
                </Row>
                <br></br>
                <Row>
                  <Col md="4">
                    <div class="row align-items-end custom-line">
                      <div class="col">Email: </div>
                      <div class="col" style={{ fontWeight: "bold" }}>
                        {location.state.client.email}
                      </div>
                    </div>
                  </Col>
                  <Col md="4">
                    <div class="row align-items-end custom-line">
                      <div class="col">Prénom: </div>
                      <div class="col" style={{ fontWeight: "bold" }}>
                        {location.state.client.first_name}
                      </div>
                    </div>
                  </Col>
                  <Col md="4">
                    <div class="row align-items-end custom-line">
                      <div class="col">Code postal: </div>
                      <div class="col" style={{ fontWeight: "bold" }}>
                        {location.state.client.adresse.postal_code}
                      </div>
                    </div>
                  </Col>
                </Row>
                <br></br>
                <Row>
                  <Col md="4">
                    <div class="row align-items-end custom-line">
                      <div class="col">Numéro du mobile: </div>
                      <div class="col" style={{ fontWeight: "bold" }}>
                        {location.state.client.phone_number}
                      </div>
                    </div>
                  </Col>
                  <Col md="4">
                    <div class="row align-items-end custom-line">
                      <div class="col">Numero de TVA: </div>
                      <div class="col" style={{ fontWeight: "bold" }}>
                        {location.state.client.num_tva
                          ? "BE" + location.state.client.num_tva
                          : "Aucun"}
                      </div>
                    </div>
                  </Col>

                  <Col md="4">
                    <div class="row align-items-end custom-line">
                      <div class="col">Pays: </div>
                      <div class="col" style={{ fontWeight: "bold" }}>
                        {location.state.client.adresse.country}
                      </div>
                    </div>
                  </Col>
                </Row>
                <br></br>
                <Row></Row>
              </Card.Body>
            </Card>
          </Row>
          <Row>
            <Col
              md="12 mb-2"
              className="d-flex justify-content-center align-items-center"
            >
              <div className="bg-success py-1 px-2 rounded">
                <h3 className=" text-white m-0 p-0">
                  {"Listes des permissions"}
                </h3>
              </div>
            </Col>
          </Row>
          {}
          <br />
          <Row className="d-flex align-items-center justify-content-between my-5">
            <Card>
              <Card.Body>
                <Row>
                  <Col md="12">
                    {isLoading === true ? (
                      <Container fluid>
                        <div className="d-flex justify-content-center align-items-center">
                          <Loading isLoading={isLoading} />
                        </div>
                      </Container>
                    ) : (
                      <DataTable
                        columns={column}
                        data={permissions}
                        pagination
                        responsive
                        dense
                        subHeader
                      ></DataTable>
                    )}
                  </Col>
                </Row>
                <Row className="">
                  <Col className="d-flex justify-content-evenly align-items-center">
                    <NavLink
                      to={{
                        pathname: "",
                        state: { client: location.state.client },
                      }}
                    >
                      <Button
                        className="btn-fill"
                        variant="danger"
                        size="sm"
                        onClick={(e) => {
                          e.preventDefault();
                          setIsLoading(true);
                          let url =
                            ApiConstants.baseUrlRequest +
                            ApiConstants.getProfilVisibilityEndpoint;

                          const updateData = {
                            code_client: location.state.client.code_client,
                            username: auth.user.username,
                            data: permissions.map((permission) => ({
                              code_visibility: permission.code,
                              visibility: permission.visibility,
                            })),
                          };

                          axios
                            .put(url, updateData)
                            .then(function (response) {
                              if (response.status === 200) {
                                setIsLoading(false);
                                setIsFound(true);
                              } else if (response.status === 404) {
                                setIsLoading(false);
                                setIsFound(false);
                              }
                            })
                            .catch(handleError);
                        }}
                      >
                        Enregistrer
                      </Button>
                    </NavLink>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Row>

          {}

          <Row>
            <Col className="d-flex justify-content-evenly align-items-center mt-2">
              {location.pathname === "/welcome/user/permissions" ? (
                <NavLink
                  className="btn btn-fill btn-primary"
                  to={{ pathname: "/welcome/user" }}
                >
                  Retour
                </NavLink>
              ) : location.pathname === "/welcome/client/permissions" ? (
                <NavLink
                  className="btn btn-fill btn-primary"
                  to={{ pathname: "/welcome/client" }}
                >
                  Retour
                </NavLink>
              ) : location.pathname === "/welcome/soustraitant/permissions" ? (
                <NavLink
                  className="btn btn-fill btn-primary"
                  to={{ pathname: "/welcome/soustraitant" }}
                >
                  Retour
                </NavLink>
              ) : null}
            </Col>
          </Row>
        </Row>
      </Container>
    </>
  );
}

export default ListePermissions;
