import React from "react";
import {
	Container,
	Form,
	Row,
	Col,
	FloatingLabel,
	Button,
	Modal,
	ThemeProvider,
} from "react-bootstrap";

import axios from "axios";
import ApiConstants from "constant";
import { AuthContext } from "AuthProvider";
import CountryDropdown from "components/Marchand/CountryDropdown";
import InputNumber from "components/Marchand/InputNumber";
import Loading from "components/utils/Loading";
import Error from "components/utils/Error";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import {
	getCountryCallingCode,
	isPossiblePhoneNumber,
	parsePhoneNumber,
	formatPhoneNumberIntl,
} from "react-phone-number-input";
import Select from "react-select";
import { MdVerifiedUser } from "react-icons/md";
import { BiError } from "react-icons/bi";
import { Spinner } from "react-activity";
import { remove_style_script_home } from "../utils";
import { masking_transition } from "../utils";
import { customStyleForReactSelect } from "../utils";

class AccountCreate extends React.Component {
	static contextType = AuthContext;
	static childLinkForBoostrap = null;
	static childLinkForLightBoostrapReactv2_0_0 = null;
	static childLinkForStyleFreeDevis = null;
	static childScriptShow = null;
	constructor(props) {
		super(props);
		this.state = {
			showModal: false,
			showModalErrorConfirmation: false,
			otpVerificationTitle: "",
			otpEmailSpinnerIsLoading: false,
			otpPhoneSpinnerIsLoading: false,
			otpWhatsappSpinnerIsLoading: false,
			otpConfirmSpinnerIsLoading: false,
			otpEmailVerified: false,
			otpPhoneVerified: false,
			otpWhatsappVerified: false,
			otpErrorMessage: "",
			otpEmailErrorMessage: "",
			otpPhoneErrorMessage: "",
			otpWhatsappErrorMessage: "",
			otpToVerify: "",
			validated: false,
			country: "",
			phone_number: "",
			whatsapp_number: "",
			isPossibleNumber: true,
			isPossibleWhatsAppNumber: true,
			errorMessage: "",
			town: "",
			region: "",
			street: "",
			loading: false,
			accountErrorMessage: "",
			isLoading: false,
			isFound: true,
			isError: false,
			error: "",
			localCountry: { label: "", value: "" },
			selectedTypeCompte: null,
			selectedTypeSocialReason: null,
			isStreetFound: false,
			typeClient: this.props.location.pathname === "/welcome/createPartner" ? "partenaire" : this.props.location.pathname === "/welcome/customer" ? "particulier" : this.props.location.pathname === "/welcome/subcontractor" ? "professionnel" : '',
			showTVA: true,
			streets: [],
			towns: [],
			country_code: "",
			taux_commissionnement: " ",
			showPostalCodeError: false,
			showNoAddressFound: false,
			new_address: false,
			optionsTypeCompte: this.props.location.pathname === "/welcome/createPartner" ? [{ value: "partenaire", label: "Partenaire" }] : this.props.location.pathname === "/welcome/customer" ? [{ value: "particulier", label: "Particulier" }] : this.props.location.pathname === "/welcome/subcontractor" ? [{ value: "professionnel", label: "Sous-traitant" }] : [
				{ value: "partenaire", label: "Partenaire" },
				{ value: "particulier", label: "Particulier" },
				{ value: "professionnel", label: "Sous-traitant" },
			],
			addressList: [],
			uniqueId: "",
		};



		this.handleSubmit = this.handleSubmit.bind(this);
		this.handleResponse = this.handleResponse.bind(this);
		this.handleError = this.handleError.bind(this);
		this.handlePhoneNumberChange = this.handlePhoneNumberChange.bind(this);
		this.handleCountryChange = this.handleCountryChange.bind(this);
		this.handlePostalCodeChange = this.handlePostalCodeChange.bind(this);
		this.handleStreetChange = this.handleStreetChange.bind(this);
		this.handleTownChange = this.handleTownChange.bind(this);
		this.filterOptions = this.filterOptions.bind(this);
		this.handleTypeCompteChange = this.handleTypeCompteChange.bind(this);
		this.handleTypeSocialReasonChange = this.handleTypeSocialReasonChange.bind(this);
		this.handleCloseModal = this.handleCloseModal.bind(this);
		this.handleOtpChange = this.handleOtpChange.bind(this);

		this.handleEmailVerificationSubmit =
			this.handleEmailVerificationSubmit.bind(this);
		this.handlePhoneVerificationSubmit =
			this.handlePhoneVerificationSubmit.bind(this);
		this.handleWhatsappVerificationSubmit =
			this.handleWhatsappVerificationSubmit.bind(this);
		this.handleConfirmVerificationSubmit =
			this.handleConfirmVerificationSubmit.bind(this);
		this.sendotp = this.sendotp.bind(this);
		this.checkotp = this.checkotp.bind(this);


	}


	optionsTypeSocialReason = [
		{ value: "srl", label: "SRL" },
		{ value: "sa", label: "SA" },
		{ value: "sc", label: "SC" },
		{ value: "snc", label: "SNC" },
	];
	customStyleForReactSelect = customStyleForReactSelect;

	sendotp = async (type) => {
		let dataTosend = null;
		const uniqueid = this.context.generateUniqueId();
		const address =
			type === "email"
				? this.email.value
				: type === "sms"
					? this.state.phone_number.split(" ").join("").substring(1)
					: type === "whatsApp"
						? this.state.whatsapp_number.split(" ").join("").substring(1)
						: "";

		this.setState({ uniqueid: uniqueid });

		const dataotp = {

			userid: 1,
			token: process.env.REACT_APP_TOKEN,
			serviceid: 4,
			address: address,
			type: type,
			username: "admin",
			uniqueid: uniqueid,
		};
		const hash = this.context.generateHash(dataotp);

		const datawithhash = { ...dataotp };
		datawithhash.hash = hash;

		dataTosend = datawithhash;


		if (type === "whatsApp") {
			const dataotp2 = {
				username: "XXXX",
				password: "YYYY",
				number: this.state.whatsapp_number.split(" ").join("").substring(1),
				message: "Message to sent",
				type: "chat",
			}

			const hash2 = this.context.md5Hash(dataotp2);

			const datawithhash2 = { ...dataotp2 };
			datawithhash2.hash = hash2;
			dataTosend = datawithhash2
		}

		let response = await this.context.sendotp(dataTosend);

		if (response && response.status === "SUCCESS") {

			if (type === "email") {
				this.setState({ showModal: true });
				this.setState({
					otpVerificationTitle: "Vérification de votre adresse e-mail",
				});
				this.setState({ otpEmailSpinnerIsLoading: false });
			}
			if (type === "sms") {
				this.setState({ showModal: true });
				this.setState({
					otpVerificationTitle: "Vérification de votre numéro de téléphone",
				});
				this.setState({ otpPhoneSpinnerIsLoading: false });
			} else if (type === "whatsApp") {
				this.setState({ showModal: true });
				this.setState({
					otpVerificationTitle: "Vérification de votre numéro whatsapp",
				});
				this.setState({ otpWhatsappSpinnerIsLoading: false });
			}
		} else {
			if (type === "email") {
				this.setState({ otpEmailSpinnerIsLoading: false });
				this.setState({ otpEmailErrorMessage: "Échec d'envoie du code de vérification." })
			}
			if (type === "sms") {
				this.setState({ otpPhoneSpinnerIsLoading: false });
				this.setState({ otpPhoneErrorMessage: "Échec d'envoie du code de vérification." })
			} else if (type === "whatsApp") {
				this.setState({ otpWhatsappSpinnerIsLoading: false });
				this.setState({ otpWhatsappErrorMessage: "Échec d'envoie du code de vérification." })
			}

		}
	};

	checkotp = async () => {


		const dataotp = {

			userid: 1,
			token: process.env.REACT_APP_TOKEN,
			totp: parseInt(this.state.otpToVerify),
			uniqueid: this.state.uniqueid,
		};

		const hash = this.context.generateHash(dataotp);

		const datawithhash = { ...dataotp };
		datawithhash.hash = hash;

		let response = await this.context.checkotp(datawithhash);


		if (response && response.status === "SUCCESS") {
			this.handleCloseModal();
			if (
				this.state.otpVerificationTitle ===
				"Vérification de votre adresse e-mail"
			) {
				this.setState({ otpEmailVerified: true });
			} else if (
				this.state.otpVerificationTitle ===
				"Vérification de votre numéro de téléphone"
			) {
				this.setState({ otpPhoneVerified: true });
				this.setState({ whatsapp_number: this.state.phone_number });
			} else if (
				this.state.otpVerificationTitle ===
				"Vérification de votre numéro whatsapp"
			) {
				this.setState({ otpWhatsappVerified: true });
			}
			this.setState({ otpConfirmSpinnerIsLoading: false });
		} else {
			this.setState({ otpErrorMessage: 'Code incorrect.' })
			this.setState({ otpConfirmSpinnerIsLoading: false });
		}
	};

	handleEmailVerificationSubmit = () => {
		this.sendotp("email");
	};

	handlePhoneVerificationSubmit = () => {
		this.sendotp("sms");
	};

	handleWhatsappVerificationSubmit = () => {
		this.sendotp("whatsApp");
	};

	handleConfirmVerificationSubmit = () => {
		if (!this.state.otpToVerify) {
			this.setState({
				otpErrorMessage: "Veuillez renseigner le code que vous avez reçu.",
			});
			this.setState({ otpConfirmSpinnerIsLoading: false });
			return;
		}
		this.checkotp();
	};

	handleCloseModal = () => {
		this.setState({ otpErrorMessage: "" });
		this.setState({ showModal: false });
	};

	handleOtpChange = (e) => {
		this.setState({ otpToVerify: e.target.value });
	};

	handleTypeSocialReasonChange = (selectedOption) => {
		this.setState({
			selectedTypeSocialReason: selectedOption.value,
		});
	}

	handleTypeCompteChange = (selectedOption) => {
		let typeClientValue;
		let showTVA = true; // Afficher le champ par défaut

		switch (selectedOption.value) {
			case "partenaire":
				typeClientValue = "partenaire";

				break;
			case "particulier":

				typeClientValue = "particulier";
				showTVA = false; // Masquer le champ pour le type de compte "particulier
				break;
			case "professionnel":
				typeClientValue = "professionnel";
				break;
			default:
				typeClientValue = "";
				break;
		}

		this.setState({
			selectedTypeCompte: selectedOption,
			typeClient: typeClientValue,
			showTVA,
		});

	};

	handlePhoneNumberChange = (value, type) => {
		if (value && isPossiblePhoneNumber(value)) {
			if (type === "sms") {
				this.setState({ isPossibleNumber: true, phone_number: value });
			} else if (type === "whatsApp") {
				this.setState({
					isPossibleWhatsAppNumber: true,
					whatsapp_number: value,
				});
			}

			const phone = parsePhoneNumber(value);
			if (phone.country === "BE") {
				const phoneDecomposition = formatPhoneNumberIntl(value).split(" ");
				const countryIndicator = phoneDecomposition[0];
				let onlyPhone = phoneDecomposition
					.slice(-(phoneDecomposition.length - 1))
					.join("");
				if (onlyPhone.length == 10) {
					if (onlyPhone[0] === "0") {
						onlyPhone === onlyPhone.slice(1);
					}
				}
				const phoneResult = formatPhoneNumberIntl(
					countryIndicator + "" + onlyPhone,
				);

				if (type === "sms") {
					this.setState({ phone_number: phoneResult });
				} else if (type === "whatsApp") {
					this.setState({ whatsapp_number: phoneResult });
				}
			} else {
				const phoneResult = formatPhoneNumberIntl(value);

				if (type === "sms") {
					this.setState({ phone_number: phoneResult });
				} else if (type === "whatsApp") {
					this.setState({ whatsapp_number: phoneResult });
				}
			}
		} else {
			if (type === "sms") {
				this.setState({ isPossibleNumber: false });
			} else if (type === "whatsApp") {
				this.setState({ isPossibleWhatsAppNumber: false });
			}
		}
	};

	handleCountryChange = (event) => {
		const country_code = event.target.value;
		this.setState({ country_code });
	};

	handleResponse(response) {
		if (this.context.user !== null) {
			switch (this.state.typeClient) {
				case 'partenaire':
					this.props.history.push({
						pathname: "/welcome/user",
					});
					break;
				case 'professionnel':
					this.props.history.push({
						pathname: "/welcome/soustraitant",
					});
					break;
				case 'particulier':
					this.props.history.push({
						pathname: "/welcome/client",
					});
					break;
			}

		} else {
			this.props.history.push({
				pathname: "/home/connection",
			});
		}


	}

	handleError(error) {
		if (error.isAxiosError) {
			if ("response" in error) {
				if (error.response.status === 401) {

					this.setState({ isLoading: false });
				} else if (error.response.status === 404) {

					this.setState({ isLoading: false });
					this.setState({ isFound: false });
				} else if (error.response.status === 409) {

					console.log(error.response);

					if (error.response.data.message === "Duplicate entry: email already exists") {
						this.setState({ isLoading: false });
						this.setState({
							accountErrorMessage:
								"Cette adresse email (utilisateur) est déjà présente dans notre base de données",
						});
					}
					else if (error.response.data.message === "Duplicate entry: phone number already exists") {
						this.setState({ isLoading: false });
						this.setState({
							accountErrorMessage:
								"Ce numéro de téléphone est déjà présente dans notre base de données",
						});
					}

					else if (error.response.data.message === "Duplicate entry: whatsapp number already exists") {
						this.setState({ isLoading: false });
						this.setState({
							accountErrorMessage:
								"Ce numéro de téléphone whatsapp est déjà présente dans notre base de données",
						});
					}
				} else {

					this.setState({ isLoading: false });
					this.setState({ isError: true });
					this.setState({ error: error });

				}
			} else {

				this.setState({ isLoading: false });
				this.setState({ isError: true });
				this.setState({ error: error });
			}
		} else {

		}

		console.log(error);
	}

	componentWillUnmount() {
		if (this.childLinkForBoostrap) {
			document.head.removeChild(this.childLinkForBoostrap);
		}

		if (this.childLinkForLightBoostrapReactv2_0_0) {
			document.head.removeChild(this.childLinkForLightBoostrapReactv2_0_0);
		}

		if (this.childLinkForStyleFreeDevis) {
			document.head.removeChild(this.childLinkForStyleFreeDevis);
		}

		if (this.childScriptShow) {
			document.body.removeChild(this.childScriptShow);
		}
	}

	componentDidMount() {
		if (
			this.props.location.pathname === "/welcome/createPartner" ||
			this.props.location.pathname === "/welcome/customer" ||
			this.props.location.pathname === "/welcome/subcontractor"
		) {
			this.fetchTauxCommissionnement();
		}
		remove_style_script_home();
		// Adding bootstrap and light bootstrap style react for welcome page
		let linkForBoostrap = document.createElement("link");
		linkForBoostrap.id = "bootstrap_connection";
		linkForBoostrap.type = "text/css";
		linkForBoostrap.rel = "stylesheet";
		linkForBoostrap.href = "/css/bootstrap.min.css";
		this.childLinkForBoostrap = document.head.appendChild(linkForBoostrap);

		let linkLightBoostrapReactv2_0_0 = document.createElement("link");
		linkLightBoostrapReactv2_0_0.type = "text/css";
		linkLightBoostrapReactv2_0_0.id = "style_lightBoostrapReactv2_0_0";
		linkLightBoostrapReactv2_0_0.rel = "stylesheet";
		linkLightBoostrapReactv2_0_0.href =
			"/css/light-bootstrap-dashboard-react.css";
		this.childLinkForLightBoostrapReactv2_0_0 = document.head.appendChild(
			linkLightBoostrapReactv2_0_0,
		);

		// Adding style for free devis
		let linkForStyleFreeDevis = document.createElement("link");
		linkForStyleFreeDevis.id = "style_free_devis";
		linkForStyleFreeDevis.type = "text/css";
		linkForStyleFreeDevis.rel = "stylesheet";
		linkForStyleFreeDevis.href = "/css/style_free_devis.css";
		this.childLinkForStyleFreeDevis = document.head.appendChild(
			linkForStyleFreeDevis,
		);

		//script for masking transition
		this.childScriptShow = masking_transition(
			"show_account_create_page",
			"account_create_page",
			"visible",
		);
	}

	fetchTauxCommissionnement = async () => {
		try {
			const url = ApiConstants.baseUrlRequest + ApiConstants.getMarchand;

			const response = await axios.get(url, {
				params: {
					username: this.context.user.username,
				},
			});
			if (response.status === 200) {

				this.setState({
					taux_commissionnement: response.data.marchand.taux_commissionnement,
				});
			} else {
				console.error(
					"Erreur lors de la récupération du taux de commissionnement : Statut de réponse incorrect",
				);
			}
		} catch (error) {
			console.error(
				"Erreur lors de la récupération du taux de commissionnement :",
				error,
			);
		}
	};

	handleSubmit(event) {
		event.preventDefault();
		const form = event.currentTarget;

		if (form.checkValidity() === false) {
			this.setState({ selectedTypeSocialReason: this.state.selectedTypeSocialReason !== "" && this.state.selectedTypeSocialReason !== null ? this.state.selectedTypeSocialReason : ""})
			event.stopPropagation();
		} else {
			if (this.state.otpEmailVerified === false && this.state.otpPhoneVerified === false && this.state.otpWhatsappVerified === false && this.context.user === null){
				this.setState({ showModalErrorConfirmation: true })
				return;
			}

			this.setState({ isLoading: true });
			let client = null;
			if (
				this.props.location.pathname === "/welcome/createPartner" ||
				this.props.location.pathname === "/welcome/customer" ||
				this.props.location.pathname === "/welcome/subcontractor"
			) {
				client = this.context.user.username;
			}

			let url = ApiConstants.baseUrlRequest + ApiConstants.createClient;

			const postalCode = this.state.postal_code;
			const town = this.state.town.value;
			let street = this.state.street !== "Autres" ? this.state.street : "";

			if (this.state.new_address && this.street && this.street.value !== "") {
				street = this.street.value;
			}
			const country_code = this.state.country_code.value;

			let numberStreet = this.number_street.value;
			let box = this.box.value;
			const name = this.fName.value + " " + this.lName.value;
			const first_name = this.fName.value;
			const last_name = this.lName.value;
			const email = this.email.value;
			let tva = null;
			if (this.state.typeClient !== "particulier") {
				tva = this.tva.value;
			}
			const region = this.state.region.value;
			const country = this.state.country.value;
			const username = this.email.value;

			const dataToCreateAccount = {
				type_client: this.state.typeClient,
				enterprise_name: this.state.typeClient === 'partenaire' ? this.enterpriseName.value : null,
				social_reason: this.state.selectedTypeSocialReason,
				with_account: true,
				new_address: this.state.new_address,
				name: name,
				first_name: first_name,
				last_name: last_name,
				country: this.state.country,
				phone_number: this.state.phone_number.split(" ").join("").substring(1),
				whatsapp_phone_number: this.state.whatsapp_number.split(" ").join("").substring(1),
				email: email,
				num_tva: tva,
				postal_code: postalCode,
				town: this.state.town,
				country_code: this.state.country_code,
				street: street,
				number_street: numberStreet,
				box: box,
				region: this.state.region,
				username: username,
				client: client,
				taux_commissionnement: this.state.taux_commissionnement,
			};

			axios
				.post(url, dataToCreateAccount)
				.then(this.handleResponse)
				.catch(this.handleError);
		}
		this.setState({ validated: true });
	}

	handlePostalCodeChange = (event) => {
		this.setState({
			new_address: false,
		});
		const postalCode = event.target.value;
		const country_code = this.state.country_code;
		if (postalCode === "") {
			this.setState({
				postal_code: "",
				town: "",
				street: "",
				country: "",
				region: "",
				towns: [],
				streets: [],
				showPostalCodeError: false,
				showNoAddressFound: false,
				new_address: false,
			});
			return; // Sortez de la fonction pour éviter l'appel à l'API avec un code postal vide
		}
		if (postalCode.length < 4) {
			// Si le code postal a moins de 4 caractères, réinitialisez les valeurs correspondantes
			this.setState({
				postal_code: postalCode,
				town: "",
				street: "",
				country: "",
				region: "",
				towns: [],
				streets: [],
				showPostalCodeError: true,
				showNoAddressFound: false,
				new_address: false,
			});
			return;
		}
		// Supprimez les caractères supplémentaires du code postal
		const trimmedPostalCode = postalCode.slice(0, 4);
		// Mettez à jour l'état avec le code postal tronqué
		this.setState({
			postal_code: trimmedPostalCode,
			showPostalCodeError: false,
			showNoAddressFound: false,
			new_address: false,

		});
		let queryParams = {
			type: "create_account",
			country_code: country_code,
			keywords: encodeURIComponent(trimmedPostalCode),
		};
		let url = ApiConstants.baseUrlRequest + ApiConstants.getAdresse;
		axios
			.get(url, { params: queryParams })
			.then((response) => {
				if (response.status === 200) {
					const data = response.data;
					if (data && data.adresses && data.adresses.length > 0) {
						const addresses = data.adresses;
						const firstAddress = addresses[0];

						const towns = addresses.map((address) => address.town);
						const streets = addresses.map((address) => address.street);

						this.setState({
							postal_code: trimmedPostalCode,
							country_code: country_code,
							town: firstAddress.town,
							street: firstAddress.street,
							country: firstAddress.country,
							region: firstAddress.region,
							towns: towns,
							town: towns[0],
							streets: streets,
							addressList: data.adresses,
						});
					} else {

						this.setState((prevState) => ({
							postal_code: trimmedPostalCode,
							country_code: country_code,
							town: prevState.town,
							street: prevState.street,
							country: prevState.country,
							region: prevState.region,
							town: prevState.town,
							towns: prevState.towns,
							streets: prevState.streets,
							showNoAddressFound: true,
							addressList: [],
						}));
					}

				} else if (response.status === 404) {
					setIsLoading(false);
					setIsFound(false);
				}
			})
			.catch(function (error) {
				console.error("Erreur lors de la requête :", error);
			});
	};

	handleStreetChange(selectedOption, { action }) {
		if (action === "select-option") {
			const street = selectedOption.value;
			if (street === "Autre") {
				this.setState({
					new_address: true,
					street: "",

				});
			} else {
				this.setState({
					new_address: false,
					street: street,
				});
			}
		}


	}


	filterOptions(option, inputValue) {
		if (typeof inputValue !== "string") {
			return true;
		}

		const street = option.value.toLowerCase();
		const searchValue = inputValue.toLowerCase();

		if (street.includes(searchValue)) {

			return true;
		}

		// Check if there are no matching options and the current option is "Autres"
		if (option.value === "Autre") {

			return true;
		}

		return false;
	}

	handleTownChange = (event) => {
		// alert(event.target.value)
		const town = event.target.value;

		// alert(this.state.street)
		const temp = this.state.addressList.filter(
			(element) => element.town == town,
		);

		const tempStreet = temp.map((address) => address.street);

		this.setState({
			town: town,
			street: tempStreet[0],
			streets: tempStreet,
			region: temp.length > 0 ? temp[0].region : "",
		});


	};

	render() {
		const { isPossibleNumber } = this.state;

		const { street, streets } = this.state;
		let options = [];
		if (this.state.streets.length > 0) {
			options = this.state.streets.map((street) => ({
				value: street,
				label: street,
			}));
			options.unshift({ value: "Autre", label: "Autre" });
		}

		let townsOptions = [];
		if (this.state.towns.length > 0) {
			townsOptions = [...new Set(this.state.towns)].map((town) => ({
				value: town,
				label: town,
			}));
		}

		// Vérifiez si la rue saisie n'existe pas dans la liste des options filtrées
		const streetNotFound =
			street === "" ||
			!options.some(
				(option) => option.value.toLowerCase() === street.toLowerCase(),
			);



		if (this.state.isLoading === false && this.state.isFound === false) {
			return <Error code={404} />;
		}

		if (this.state.isError === true) {
			if (this.state.error.message === "Network Error") {
				return <Error code={-1} />;
			}
			return <Error code={-2} />;
		}

		return (
			<Container
				fluid
				className="my-5"
				id="account_create_page"
				style={{ visibility: "hidden" }}
			>
				<Row className="d-flex justify-content-center">
					<Col
						sm="12"
						lg="7"
						md="12"
						className="d-flex align-items-center justify-content-center my-5 p-3"
						style={{ backgroundColor: '#8EA841' }}
					>
						<Form
							noValidate
							validated={this.state.validated}
							onSubmit={this.handleSubmit}
						>
							<Row>
								<Col>
									{this.props.location.pathname === "/welcome/createPartner" ? (
										<h1 className="h1 mb-3 text-secondary fw-bold d-flex align-items-center justify-content-center">
											Créer un partenaire
										</h1>
									) : this.props.location.pathname ===
										"/home/create_account" ? (
										<h1 className="h1 mb-3 text-secondary fw-bold d-flex align-items-center justify-content-center">
											Créer votre compte
										</h1>
									) : this.props.location.pathname === "/welcome/customer" ? (
										<h1 className="h1 mb-3 text-secondary fw-bold d-flex align-items-center justify-content-center">
											Créer un client
										</h1>
									) : this.props.location.pathname ===
										"/welcome/subcontractor" ? (
										<h1 className="h1 mb-3 text-secondary fw-bold d-flex align-items-center justify-content-center">
											Créer un sous traitant
										</h1>
									) : null}
								</Col>
							</Row>
							<Row>
								<Col sm="12" lg="12">
									<Form.Group controlId="lName" className="mb-3">
										<Form.Label>Type de compte</Form.Label>
										{this.props.location.pathname ===
											"/welcome/createPartner" ||
											this.props.location.pathname === "/welcome/customer" ||
											this.props.location.pathname ===
											"/welcome/subcontractor" ? (
											<Select
												styles={this.customStyleForReactSelect}
												required
												name="typeCompte"
												options={

													this.state.optionsTypeCompte}
												defaultValue={this.state.optionsTypeCompte[0]}
												placeholder="Sélectionnez le type de compte"
												onChange={this.handleTypeCompteChange} // Utilisez la fonction de gestion de l'événement onChange
											/>
										) : (
											<Select
												styles={this.customStyleForReactSelect}
												required
												name="typeCompte"
												options={this.state.optionsTypeCompte}
												placeholder="Sélectionnez le type de compte"
												onChange={this.handleTypeCompteChange} // Utilisez la fonction de gestion de l'événement onChange
											/>
										)}
									</Form.Group>
								</Col>

								{this.state.showTVA &&
									this.props.location.pathname !== "/welcome/createPartner" &&
									this.props.location.pathname !== "/welcome/customer" &&
									this.props.location.pathname !== "/welcome/subcontractor" && (
										<Col sm="12" lg="12" className="mb-3">
											<Form.Group controlId="tva" className="mb-3">
												<Form.Label>Numéro de TVA</Form.Label>
												<Form.Control
													required
													ref={(input) => (this.tva = input)}
													placeholder="Numéro de TVA"
													type="number"
												/>
											</Form.Group>
										</Col>
									)}

								{(this.props.location.pathname === "/welcome/createPartner" ||
									this.props.location.pathname ===
									"/welcome/subcontractor") && (
										<React.Fragment>
											<Col sm="12" lg="6" className="mb-3">
												<Form.Group controlId="tva" className="mb-3">
													<Form.Label>Numéro de TVA</Form.Label>
													<Form.Control
														required
														ref={(input) => (this.tva = input)}
														placeholder="Numéro de TVA"
														type="number"
													/>
												</Form.Group>
											</Col>
											<Col sm="12" lg="6" className="mb-3">
												<Form.Group controlId="fName" className="mb-3">
													<Form.Label>Taux commissionnement</Form.Label>
													<Form.Control
														required
														value={this.state.taux_commissionnement}
														placeholder="Taux de commissionnement"
														type="text"
														onChange={(e) =>
															this.setState({
																taux_commissionnement: e.target.value,
															})
														}
													/>
												</Form.Group>
											</Col>
										</React.Fragment>
									)}

								<Col sm="12" lg="6" className="mb-3">
									<Form.Group controlId="lName" className="mb-3">
										<Form.Label>Nom(s)</Form.Label>
										<Form.Control
											required
											ref={(input) => (this.lName = input)}
											placeholder="Votre nom de famille"
											type="text"
										></Form.Control>
									</Form.Group>
								</Col>
								<Col sm="12" lg="6" className="mb-3">
									<Form.Group controlId="fName" className="mb-3">
										<Form.Label>Prénom(s)</Form.Label>
										<Form.Control
											required
											ref={(input) => (this.fName = input)}
											placeholder="Votre prénom"
											type="text"
										></Form.Control>
									</Form.Group>
								</Col>

								{
									this.props.location.pathname ===
										"/welcome/createPartner" || this.props.location.pathname ===
										"/welcome/subcontractor" || (this.state.selectedTypeCompte !== null && (this.state.selectedTypeCompte.value === 'partenaire' || this.state.selectedTypeCompte.value === 'professionnel')) ? (
										<>
											<Col sm="12" lg="6" className="mb-3">
												<Form.Group controlId="enterprise_name" className="mb-3">
													<Form.Label>Nom de la société</Form.Label>
													<Form.Control
														required
														ref={(input) => (this.enterpriseName = input)}
														placeholder="Le nom de votre société"
														type="text"
													></Form.Control>
												</Form.Group>
											</Col>
											<Col sm="12" lg="6">
												<Form.Group controlId="social_reason" className="mb-3">
													<Form.Label>Raison sociale de la société</Form.Label>
													<Select
														styles={this.customStyleForReactSelect}
														required
														name="social_reason"
														options={this.optionsTypeSocialReason}
														placeholder="Sélectionnez la raison social"
														onChange={this.handleTypeSocialReasonChange}
													/>
													{this.state.selectedTypeSocialReason === "" && (
														<p style={{ color: "red" }}>
															Choisiser une raison social
														</p>
													)}

												</Form.Group>
											</Col>
										</>
									)
										: null
								}

								<Col sm="12" lg="6" className="mb-3">
									<Form.Group controlId="email" className="mb-3 row">
										<Form.Label>Email</Form.Label>

										<Col sm="12" lg="8" className="mb-3">
											<Form.Control
												onChange={(e) => {
													this.setState({ otpEmailVerified: false });
												}}
												required
												ref={(input) => (this.email = input)}
												placeholder="Votre email"
												type="email"
											></Form.Control>
										</Col>
										<Col
											sm="12"
											lg="4"
											className="d-flex justify-content-end mb-3"
										>
											{!this.state.otpEmailVerified ? (
												<Button
													variant="primary"
													className="btn-fill btn-sm"
													onClick={(e) => {
														e.preventDefault();
														this.setState({ otpEmailErrorMessage: '' });
														this.setState({ otpEmailSpinnerIsLoading: true });
														this.handleEmailVerificationSubmit();

													}}
													disabled={(this.context.user !== null && this.context.user.type === 'marchand')}
												>
													<div className="d-flex justify-content-between align-items-center">
														<span className="me-2">Vérifier</span>
														<Spinner
															color="#87cb16"
															size={10}
															speed={1}
															animating={this.state.otpEmailSpinnerIsLoading}
														/>
													</div>
												</Button>
											) : (
												<Button className="btn btn-fill btn-sm btn-success">
													<MdVerifiedUser size={25} />
												</Button>
											)}
										</Col>
										{this.state.otpEmailErrorMessage.length !== 0 && (
											<p style={{ color: "red" }}>
												{this.state.otpEmailErrorMessage}
											</p>
										)}
									</Form.Group>
								</Col>
								<Col sm="12" lg="6" className="mb-3">
									<Form.Group controlId="pays" className="mb-3">
										<Form.Label>Pays</Form.Label>
										<select
											className="select_country"
											name="pays_tank_in_devis"
											id="pays_tank_in_devis"
											autoComplete="something-new"
											style={{
												marginBottom: 5,
											}}
											onChange={this.handleCountryChange} // Ajoutez cette ligne pour gérer le changement de pays
										>
											<option value="">Selectionnez un pays</option>
											<option value="BE">Belgique</option>
											<option value="LU">Luxembourg</option>
										</select>
									</Form.Group>
								</Col>
								<Col sm="12" lg="6" className="mb-3">
									<Form.Group controlId="code postal" className="mb-3">
										<Form.Label>Code Postal</Form.Label>
										<Form.Control
											required
											onChange={this.handlePostalCodeChange}
											placeholder="Votre code postal"
											type="text" // Modifier le type à "text"
											value={this.state.postal_code}
											name="postal_code"
										></Form.Control>
										{this.state.showPostalCodeError && (
											<p style={{ color: "red" }}>
												Le code postal doit contenir au moins 4 caractères.
											</p>
										)}
										{this.state.showNoAddressFound && (
											<p style={{ color: "red" }}>
												le code postale saisie n’existe pas,veuillez entrer un
												code postal valide.
											</p>
										)}
									</Form.Group>
								</Col>
								<Col sm="12" lg="6" className="mb-3">
									<Form.Group controlId="ville" className="mb-3">
										<Form.Label>Ville</Form.Label>
										<Form.Control
											required
											onChange={this.handleTownChange}
											placeholder="Votre ville"
											type="text"
											defaultValue={this.state.town}
											name="town"
											as="select"
										>
											{[...new Set(this.state.towns)].map((town) => (
												<option key={town} value={town}>
													{town}
												</option>
											))}
										</Form.Control>
									</Form.Group>
								</Col>

								<Col sm="12" lg="6" className="mb-3">
									<Form.Group controlId="rue" className="mb-3">
										<Form.Label>Rue</Form.Label>
										{this.state.new_address ? (
											<Form.Control
												required
												placeholder="Votre rue"
												type="text"
												value={street}
												onChange={(event) =>
													this.setState({ street: event.target.value })
												}
												name="street"
											/>
										) : (
											<div>

												<Select
													styles={this.customStyleForReactSelect}
													value={options.find(
														(option) => option.value === street,
													)}
													onChange={this.handleStreetChange}
													options={options}
													isSearchable
													filterOption={this.filterOptions}

												/>

											</div>
										)}
									</Form.Group>
								</Col>
								<Col sm="12" lg="3" className="mb-3">
									<Form.Group controlId="rue" className="mb-3">
										<Form.Label>Numéro</Form.Label>
										<Form.Control
											required
											ref={(input) => (this.number_street = input)}
											placeholder="Votre numéro"
											type="number"
										></Form.Control>
									</Form.Group>
								</Col>
								<Col sm="12" lg="3" className="mb-3">
									<Form.Group controlId="boite postale" className="mb-3">
										<Form.Label>Boite Postale</Form.Label>
										<Form.Control
											ref={(input) => (this.box = input)}
											placeholder="Votre boite postale"
											type="text"
										></Form.Control>
									</Form.Group>
								</Col>
								<Col sm="12" lg="6" className="mb-3">
									<Form.Group controlId="region" className="mb-3">
										<Form.Label>Région</Form.Label>
										<Form.Control
											required
											disabled
											placeholder="Votre région"
											type="text"
											value={this.state.region}
										></Form.Control>
									</Form.Group>
								</Col>
								<Col sm="12" lg="6" className="mb-3">
									<Form.Group controlId="phone" className="mb-3 row">
										<Form.Label>Numéro Téléphone</Form.Label>

										<Col sm="12" lg="8" className="mb-3">
											<PhoneInput

												international
												defaultCountry="BE"
												countryCallingCodeEditable={false}
												// value={phoneNumber}
												onChange={(value) => {
													this.setState({ otpPhoneVerified: false });
													this.handlePhoneNumberChange(value, "sms");
												}} // Use the bound method
											/>
										</Col>
										<Col
											sm="12"
											lg="4"
											className="d-flex justify-content-end mb-3"
										>
											{!this.state.otpPhoneVerified ? (
												<Button
													variant="primary"
													className="btn-fill btn-sm"
													onClick={(e) => {
														e.preventDefault();
														this.setState({ otpPhoneErrorMessage: '' });
														this.setState({ otpPhoneSpinnerIsLoading: true });
														this.handlePhoneVerificationSubmit();
													}}
													disabled={(this.context.user !== null && this.context.user.type === 'marchand')}
												>
													<div className="d-flex justify-content-between align-items-center">
														<span className="me-2">Vérifier</span>
														<Spinner
															color="#87cb16"
															size={10}
															speed={1}
															animating={this.state.otpPhoneSpinnerIsLoading}
														/>
													</div>
												</Button>
											) : (
												<Button className="btn btn-fill btn-sm btn-success">
													<MdVerifiedUser size={25} />
												</Button>
											)}
										</Col>

										{!isPossibleNumber && (
											<p style={{ color: "red" }}>
												Saisissez un numéro de téléphone valide
											</p>
										)}
										{this.state.otpPhoneErrorMessage.length !== 0 && (
											<p style={{ color: "red" }}>
												{this.state.otpPhoneErrorMessage}
											</p>
										)}

									</Form.Group>
								</Col>

								<Col sm="12" lg="6" className="mb-3 offset offset-lg-6">
									<Form.Group controlId="phone" className="mb-3 row">
										<Form.Label>Numéro WHATSAPP</Form.Label>

										<Col sm="12" lg="8" className="mb-3">
											<PhoneInput
												value={this.state.whatsapp_number}
												international
												defaultCountry="BE"
												countryCallingCodeEditable={false}
												// value={phoneNumber}
												onChange={(value) => {
													this.setState({ otpWhatsappVerified: false });
													this.handlePhoneNumberChange(value, "whatsApp");
												}} // Use the bound method
											/>
										</Col>
										<Col
											sm="12"
											lg="4"
											className="d-flex justify-content-end mb-3"
										>
											{!this.state.otpWhatsappVerified ? (
												<Button
													variant="primary"
													className="btn-fill btn-sm"
													onClick={(e) => {
														e.preventDefault();
														this.setState({ otpWhatsappErrorMessage: '' });
														this.setState({
															otpWhatsappSpinnerIsLoading: true,
														});
														this.handleWhatsappVerificationSubmit();
													}}
													// disabled={(this.context.user !== null && this.context.user.type === 'marchand')}
													disabled={true}
												>
													<div className="d-flex justify-content-between align-items-center">
														<span className="me-2">Vérifier</span>
														<Spinner
															color="#87cb16"
															size={10}
															speed={1}
															animating={this.state.otpWhatsappSpinnerIsLoading}
														/>
													</div>
												</Button>
											) : (
												<Button className="btn btn-fill btn-sm btn-success">
													<MdVerifiedUser size={25} />
												</Button>
											)}
										</Col>

										{!this.state.isPossibleWhatsAppNumber && (
											<p style={{ color: "red" }}>
												Saisissez un numéro de téléphone valide
											</p>
										)}
										{this.state.otpWhatsappErrorMessage.length !== 0 && (
											<p style={{ color: "red" }}>
												{this.state.otpWhatsappErrorMessage}
											</p>
										)}

									</Form.Group>
								</Col>

								<Col sm="12" lg="6"></Col>
								<Col sm="12" lg="6" className=""></Col>
							</Row>
							<Row>
								<Col>
									<p className="text-danger mt-3 text-center">
										{this.state.accountErrorMessage}
									</p>
								</Col>
							</Row>
							<div className="d-sm-flex mb-5 align-items-center">
								<div className=" mb-3 text-center">
									<a href="/home" className="forgot-pass text-secondary" style={{ color: '#' }}>
										Retour à l'accueil
									</a>
								</div>

								<div className="ms-auto text-center">
									<a href="/home/connection" className="forgot-pass text-secondary">
										Connectez-vous
									</a>
								</div>
							</div>
							<div className="d-grid gap-2 col-12 mx-auto">
								<div className="d-flex justify-content-center p-3">
									<Button
										variant="primary"
										type="submit"
										className="btn-fill btn-md mt-3 w-75"
									// disabled={
									//   !(
									//     this.state.otpEmailVerified ||
									//     this.state.otpPhoneVerified ||
									//     this.state.otpWhatsappVerified ||
									//     (this.context.user !== null && this.context.user.type === 'marchand')
									//   )
									// }
									>
										<div className="d-flex justify-content-center align-items-center">
											<span className="me-2">S'inscrire</span>
											<Spinner
												color="#8EA841"
												size={10}
												speed={1}
												animating={this.state.isLoading}
											/>
										</div>
									</Button>
								</div>
							</div>
						</Form>

					</Col>
				</Row>


				<Modal show={this.state.showModal} onHide={this.handleCloseModal}>
					<Modal.Header closeButton className="d-flex justify-content-center">
						<Modal.Title className="justify-content-center">
							{this.state.otpVerificationTitle}
						</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<p>Veuillez entrer le code que vous avez reçu.</p>
						<Form onSubmit={(e) => e.preventDefault()}>
							<Row>
								<Col>
									<Form.Group
										as={Row}
										className="mb-3"
										controlId="otp_to_verify"
									>
										<Form.Label column sm="2">
											Code
										</Form.Label>
										<Col sm="10">
											<Form.Control
												type="number"
												onChange={this.handleOtpChange}

											/>
										</Col>
									</Form.Group>
								</Col>
							</Row>

							<Row>
								<Col>
									<p className="text-danger mt-3 text-center">
										{this.state.otpErrorMessage}
									</p>
								</Col>
							</Row>
						</Form>
					</Modal.Body>
					<Modal.Footer className="d-flex justify-content-center">
						<Button
							variant="success"
							className="btn-fill bg-success"
							onClick={(e) => {
								e.preventDefault();
								this.setState({ otpConfirmSpinnerIsLoading: true });
								this.handleConfirmVerificationSubmit();
							}}
						>
							<div className="d-flex justify-content-between align-items-center">
								<span className="mr-3">Confirmer</span>
								<Spinner
									color="#fff"
									size={10}
									speed={1}
									animating={this.state.otpConfirmSpinnerIsLoading}
								/>
							</div>
						</Button>
					</Modal.Footer>
				</Modal>

				<Modal show={this.state.showModalErrorConfirmation}>
					{/* <Modal.Header closeButton className="d-flex justify-content-center">
					<Modal.Title className="justify-content-center">
						Confirmation création et envoie de mail
					</Modal.Title>
				</Modal.Header> */}
					<Modal.Body>
						<center><BiError style={{ color: '#ff2c2C', fontSize: '100px', marginBottom: '20px' }} /><p>Vous devez vérifier votre email, votre numéro de téléphone ou votre numéro de téléphone WhatsApp.</p></center>
					</Modal.Body>
					<Modal.Footer className="d-flex justify-content-center">
						<Button
							variant="success"
							className="btn-fill"
							onClick={(e) => {
								this.setState({ showModalErrorConfirmation: false })
							}}
						>
							OK
						</Button>
					</Modal.Footer>
				</Modal>
			</Container>
		);
	}
}

export default AccountCreate;
