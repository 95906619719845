import React from "react";
import {
  Badge,
  Button,
  Card,
  Form,
  Navbar,
  Table,
  Nav,
  Container,
  Row,
  Col,
  Modal,
} from "react-bootstrap";
import { FaSearch } from "react-icons/fa";
import moment from "moment";
import axios from "axios";
import { useState, useEffect, useRef } from "react";
import { Dots } from "react-activity";
import "react-activity/dist/library.css";
import ApiConstants from "constant";
import InvoiceLineTable from "components/Marchand/InvoiceLineTable";
import { useLocation } from "react-router-dom";
import routes from "router/welcome-routes.js";
import Error from "components/utils/Error";
import DataTable from "react-data-table-component";
import "moment/locale/fr";
moment.locale("fr");

import { useAuth } from "AuthProvider";

function InvoiceList(props) {
  const auth = useAuth();
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5;
  const location = useLocation();
  const [invoices, setInvoices] = useState([]);
  const [commission, setCommission] = useState([]);
  const [filterinvoices, setFilterInvoices] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isFound, setIsFound] = useState(false);
  const [isError, setIsError] = useState(false);
  const [error, setError] = useState("");
  const [responseTime, setResponseTime] = useState(0);
  const [numDevis, setNumDevis] = useState(10);
  const type = useRef();
  const numInvoice = useRef();
  const nomClient = useRef();
  const status = useRef();
  const echeanceDate = useRef();
  const createDate = useRef();

  const getInvoiceType = () => {
    for (let i = 0; i < routes.length; i++) {
      if (location.pathname.indexOf(routes[i].layout + routes[i].path) !== -1) {
        return routes[i].name;
      }
    }
    return "";
  };
  const invoiceType = getInvoiceType();

  const handleSubmit = (e) => {
    e.preventDefault();
    var type = null;
    if (invoiceType == "Bon de commande") {
      type = "bon_commande";
    } else if (invoiceType == "Devis non associés") {
      type = "devis";
    } else {
      type = invoiceType;
    }
    const searchCriteria = {
      name: nomClient.current.value !== "" ? nomClient.current.value : null,
      type: type !== "" ? type : null,
      num_facturation:
        numInvoice.current.value !== "" ? numInvoice.current.value : null,
      status: status.current.value !== "" ? status.current.value : null,
      date_debut:
        createDate.current.value !== "" ? createDate.current.value : null,
      date_echeance:
        echeanceDate.current.value !== "" ? echeanceDate.current.value : null,
    };

    axios
      .get(ApiConstants.baseUrlRequest + ApiConstants.searchinvoice, {
        params: searchCriteria,
      })
      .then(function (response) {
        if (response.status == 200) {
          setIsLoading(false);
          setIsFound(true);
          setCommission(response.data.total_commission);
          setInvoices(response.data.results);
          setResponseTime(response.responseTime / 1000);
        } else if (response.status == 404) {
          setIsLoading(true);
          setIsFound(false);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const column = [
    {
      name:
        invoiceType === "Facture"
          ? "N° Facture"
          : invoiceType === "Devis"
          ? "N° Devis"
          : invoiceType === "Bon de commande"
          ? "N° Bon de commande"
          : invoiceType === "Devis non associés"
          ? "N° Devis"
          : "",
      selector: (row) => row.num_facturation,
      sortable: true,
      width: "150px",
      style:
        invoices.length != 0
          ? invoices.length <= 1
            ? { marginBottom: "110px" }
            : invoices.length <= 2
            ? { marginBottom: "50px" }
            : invoices.length <= 3
            ? { marginBottom: "35px" }
            : invoices.length <= 4
            ? { marginBottom: "15px" }
            : null
          : null,
    },
    {
      name: "Nom client",
      selector: (row) => row.client.name,
      sortable: true,
      width: "150px",
      style:
        invoices.length != 0
          ? invoices.length <= 1
            ? { marginBottom: "110px" }
            : invoices.length <= 2
            ? { marginBottom: "50px" }
            : invoices.length <= 3
            ? { marginBottom: "35px" }
            : invoices.length <= 4
            ? { marginBottom: "15px" }
            : null
          : null,
    },

    {
      name: "Date création",
      selector: (row) =>
        moment(row.created_at).format("dddd Do MMMM YYYY à HH:mm:ss"),
      sortable: true,
      width: "200px",
      style:
        invoices.length != 0
          ? invoices.length <= 1
            ? { marginBottom: "110px" }
            : invoices.length <= 2
            ? { marginBottom: "50px" }
            : invoices.length <= 3
            ? { marginBottom: "35px" }
            : invoices.length <= 4
            ? { marginBottom: "15px" }
            : null
          : null,
    },

    {
      name: "Date échéance",
      selector: (row) =>
        moment(row.date_echeance).format("dddd Do MMMM YYYY à HH:mm:ss"),
      sortable: true,
      width: "150px",
      style:
        invoices.length != 0
          ? invoices.length <= 1
            ? { marginBottom: "110px" }
            : invoices.length <= 2
            ? { marginBottom: "50px" }
            : invoices.length <= 3
            ? { marginBottom: "35px" }
            : invoices.length <= 4
            ? { marginBottom: "15px" }
            : null
          : null,
    },
    {
      name: "Montant TTC",
      selector: (row) => row.montant_ttc,
      sortable: true,
      width: "100px",
      style:
        invoices.length != 0
          ? invoices.length <= 1
            ? { marginBottom: "110px" }
            : invoices.length <= 2
            ? { marginBottom: "50px" }
            : invoices.length <= 3
            ? { marginBottom: "35px" }
            : invoices.length <= 4
            ? { marginBottom: "15px" }
            : null
          : null,
    },
    {
      name: "Status",
      selector: (row) => row.status,
      sortable: true,
      width: "90px",
      style:
        invoices.length != 0
          ? invoices.length <= 1
            ? { marginBottom: "110px" }
            : invoices.length <= 2
            ? { marginBottom: "50px" }
            : invoices.length <= 3
            ? { marginBottom: "35px" }
            : invoices.length <= 4
            ? { marginBottom: "15px" }
            : null
          : null,
    },
    ...(invoiceType === "Devis" && auth.user.type !== "particulier"
      ? [
          {
            name: "Commission",
            selector: (row) => row.commissions + " €",
            sortable: true,
            width: "150px",
            style:
              invoices.length != 0
                ? invoices.length <= 1
                  ? { marginBottom: "110px" }
                  : invoices.length <= 2
                  ? { marginBottom: "50px" }
                  : invoices.length <= 3
                  ? { marginBottom: "35px" }
                  : invoices.length <= 4
                  ? { marginBottom: "15px" }
                  : null
                : null,
          },
        ]
      : []),
    {
      name: "Actions",
      cell: (row) => (
        <InvoiceLineTable
          key={row.id}
          invoice={row}
          setError={setError}
          setIsError={setIsError}
          setIsFound={setIsFound}
          setIsLoading={setIsLoading}
          {...props}
        />
      ),
      sortable: true,
      style:
        invoices.length != 0
          ? invoices.length <= 1
            ? { marginBottom: "110px" }
            : invoices.length <= 2
            ? { marginBottom: "50px" }
            : invoices.length <= 3
            ? { marginBottom: "35px" }
            : invoices.length <= 4
            ? { marginBottom: "15px" }
            : null
          : null,
    },
  ];

  useEffect(() => {
    const fetData = async () => {
      let url = ApiConstants.baseUrlRequest;
      axios.interceptors.request.use((x) => {
        // to avoid overwriting if another interceptor
        // already defined the same object (meta)
        x.meta = x.meta || {};
        x.meta.requestStartedAt = new Date().getTime();
        return x;
      });
      axios.interceptors.response.use((x) => {
        x.responseTime = new Date().getTime() - x.config.meta.requestStartedAt;
        return x;
      });
      switch (invoiceType) {
        case "Facture":
          url =
            url +
            ApiConstants.getInvoiceProfessionnel +
            "/facture/" +
            auth.user.username;
          break;
        case "Devis":
          url =
            url +
            ApiConstants.getInvoiceProfessionnel +
            "/devis/" +
            auth.user.username;
          break;
        case "Bon de commande":
          url =
            url +
            ApiConstants.getInvoiceProfessionnel +
            "/bon_commande/" +
            auth.user.username;
          break;
        case "Devis non associés":
          url = url + ApiConstants.getDevisParticulars;
          break;
      }

      if (numDevis > 0) {
        url = url + "?numDevis=" + numDevis;
      }

      axios
        .get(url)
        .then(function (response) {
          if (response.data.status == 200) {
            setIsLoading(false);
            setIsFound(true);
            setCommission(response.data.total_commission);
            setInvoices(response.data.facturations);
            setFilterInvoices(response.data.facturations);
            setResponseTime(response.responseTime / 1000);
          } else if (response.data.status == 404) {
            setIsLoading(false);
            setIsFound(false);
          }
        })
        .catch(function (error) {
          setIsError(true);
          setError(error);
        });
    };
    fetData();
  }, [location]);

  const handleFilter = (event) => {
    const newData = filterinvoices.filter((row) =>
      row.client.name.toLowerCase().includes(event.target.value.toLowerCase()),
    );
    setInvoices(newData);
  };

  if (isLoading === false && isFound === true) {
    return (
      <>
        <Container fluid>
          <Row>
            <Col>
              <Container fluid>
                <Row>
                  <Col>
                    <h1 className="h1 mt-0">
                      {invoiceType === "Facture"
                        ? "Rechercher une facture"
                        : invoiceType === "Devis" ||
                          invoiceType === "Devis non associés"
                        ? "Rechercher un devis"
                        : invoiceType === "Bon de commande"
                        ? "Rechercher un bon de comande"
                        : ""}
                    </h1>
                  </Col>
                  <Col>
                    <div className="d-flex align-items-center justify-content-end py-3">
                      <div
                        className="btn btn-fill btn-success text-dark m-4 px-3 py-2"
                        style={{
                          border: "2px solid #28a745",
                          backgroundColor: "#ffffff",
                        }}
                      >
                        Total des commissions : {commission} €
                      </div>
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col sm="12" className="d-flex justify-content-center">
                    <Card style={{ width: "100%" }}>
                      <Card.Title className="bg-secondary text-white fw-bold fs-5">
                        {" "}
                        <div className="m-3">
                          {" "}
                          <FaSearch className="me-3" /> Critères de recherche{" "}
                        </div>{" "}
                      </Card.Title>
                      <Card.Body className="p-5">
                        <Form onSubmit={handleSubmit}>
                          <Row>
                            <Col className="px-1" sm="6">
                              <Form.Group
                                controlId="numInvoice"
                                className="d-flex  align-items-center justify-content-between mb-3"
                              >
                                <Form.Label className="me-3 text-end searchlabel">
                                  {invoiceType === "Facture"
                                    ? "N° Facture"
                                    : invoiceType === "Devis" ||
                                      invoiceType === "Devis non associés"
                                    ? "N° Devis"
                                    : invoiceType === "Bon de commande"
                                    ? "N° Bon de commande"
                                    : ""}
                                </Form.Label>
                                <Form.Control
                                  placeholder={
                                    invoiceType === "Facture"
                                      ? "N° Facture"
                                      : invoiceType === "Devis" ||
                                        invoiceType === "Devis non associés"
                                      ? "N° Devis"
                                      : invoiceType === "Bon de commande"
                                      ? "N° Bon de commande"
                                      : ""
                                  }
                                  type="text"
                                  ref={numInvoice}
                                />
                              </Form.Group>
                            </Col>

                            <Col className="px-1" sm="6">
                              <Form.Group
                                controlId="nomClient"
                                className="d-flex  align-items-center justify-content-between mb-3"
                              >
                                <Form.Label className="me-3 text-end searchlabel">
                                  Nom client
                                </Form.Label>
                                <Form.Control
                                  placeholder="Nom client"
                                  type="text"
                                  ref={nomClient}
                                />
                              </Form.Group>
                            </Col>
                            <Col className="px-1" sm="6">
                              <Form.Group
                                controlId="status"
                                className="d-flex  align-items-center justify-content-between mb-3"
                              >
                                <Form.Label className="me-3 text-end searchlabel">
                                  Status
                                </Form.Label>
                                <Form.Control
                                  placeholder="Status"
                                  type="text"
                                  ref={status}
                                />
                              </Form.Group>
                            </Col>
                            <Col className="px-1" sm="6">
                              <Form.Group
                                controlId="createDate"
                                className="d-flex  align-items-center justify-content-between mb-3"
                              >
                                <Form.Label className="me-3 text-end searchlabel">
                                  Date de création
                                </Form.Label>
                                <Form.Control
                                  placeholder="Date de création"
                                  type="date"
                                  ref={createDate}
                                />
                              </Form.Group>
                            </Col>
                            <Col className="px-1" sm="6">
                              <Form.Group
                                controlId="echeanceDate"
                                className="d-flex  align-items-center justify-content-between mb-3"
                              >
                                <Form.Label className="me-3 text-end searchlabel">
                                  Date d'échéance
                                </Form.Label>
                                <Form.Control
                                  placeholder="Date d'échéance"
                                  type="date"
                                  ref={echeanceDate}
                                />
                              </Form.Group>
                            </Col>
                          </Row>

                          <div className="d-flex align-items-center justify-content-center py-3">
                            <Button
                              className="btn-fill pull-right"
                              type="submit"
                              variant="info"
                            >
                              Rechercher
                            </Button>
                          </div>
                          <div className="clearfix"></div>
                        </Form>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
                <Row>
                  <Col sm="12"></Col>
                </Row>
              </Container>
            </Col>
          </Row>

          <Row>
            <div className="d-flex align-items-center justify-content-end py-3">
              {invoiceType === "Devis" &&
              auth.isVisible("Créer un devis") === true ? (
                <a
                  href="/welcome/devis/create"
                  className="btn btn-fill btn-lg btn-success pull-right m-4"
                >
                  Nouveau devis
                </a>
              ) : null}
            </div>
            <div className="clearfix"></div>
          </Row>
          <Row>
            <Col sm="12" className="d-flex justify-content-between">
              <Col sm="6">
                <h1 className="h4 d-flex justify-content-start">
                  Résultats de recherche
                </h1>
              </Col>
              <Col sm="6">
                <h1 className="h4 d-flex justify-content-end">
                  {invoices.length +
                    " Résultats trouvé(s) en " +
                    responseTime.toString() +
                    " S"}{" "}
                </h1>
              </Col>
            </Col>
          </Row>
          <br />
          <br />
          <Row>
            <Col sm="12" md="12" lg="12" xl="12">
              <DataTable
                columns={column}
                data={invoices}
                //selectableRows
                responsive
                pagination
                dense
                //subHeader // Ajouter la propriété subHeader pour afficher la barre de recherche
                // subHeaderComponent={
                //   <Col xl="3" lg="3" md="3">
                //     <input
                //     type="text"
                //     value={filterinvoices.invoices} // Utilisez la variable searchTerm pour la valeur du champ
                //     onChange={handleFilter} // Utilisez la fonction handleFilter pour l'événement onChange
                //     placeholder="Rechercher..."
                //     className="form-control"
                //     />
                //   </Col>// Ajouter un élément <input> pour le champ de recherche
                // }
              />
            </Col>
          </Row>
        </Container>
      </>
    );
  }

  return (
    <Container fluid>
      <div className="d-flex justify-content-center align-items-center">
        <Dots color="#727981" size={32} speed={1} animating={isLoading} />
      </div>
    </Container>
  );
}

export default InvoiceList;
