import React from "react";
import {
  Container,
  Form,
  Row,
  Col,
  FloatingLabel,
  Button,
} from "react-bootstrap";
import { NavLink, useHistory } from "react-router-dom";

import ApiConstants from "constant";
import axios from "axios";

import { Dots } from "react-activity";
import "react-activity/dist/library.css";

import Error from "components/utils/Error";
import Loading from "components/utils/Loading";
import { error } from "jquery";
import { useAuth } from "AuthProvider";
import { useState, useRef, useEffect } from "react";
import { style_connection } from "constant";
import { remove_style_script_home } from "../utils";
import { masking_transition } from "../utils";
import { Spinner } from "react-activity";
import Select from "react-select";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import {
  getCountryCallingCode,
  isPossiblePhoneNumber,
  parsePhoneNumber,
  formatPhoneNumberIntl,
} from "react-phone-number-input";


const connection = (props) => {
  const auth = useAuth();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [isFound, setIsFound] = useState(true);
  const [isError, setIsError] = useState(false);
  const [error, setError] = useState("");
  const [validated, setValidated] = useState(false);
  const [isPossibleNumber, setIsPossibleNumber] = useState(true);
  const [isPossibleWhatsAppNumber, setIsPossibleWhatsAppNumber] =
    useState(true);
  const [typeconnexion, setTypeconnexion] = useState("otp");
  const [selectedOption, setSelectedOption] = useState("");
  const [gaUrl, setGaUrl] = useState("");
  let childStyle = null;
  const username = useRef(null);
  const [userName, setUsername] = useState("");

  const phonewhatsApp = useRef(null);
  const phonenumber = useRef(null);
  let childLinkForBoostrap = null;
  let childLinkForOwlCarousel = null;
  let childLinkForStyleForConnection = null;
  let childScriptShow = null;

  useEffect(() => {
    if(localStorage.getItem("site")){
      window.location.href="/welcome/dashboard"
    }
    //remove all home styles
    remove_style_script_home();

    // Adding owl carousel
    let linkForOwlCarousel = document.createElement("link");
    linkForOwlCarousel.id = "owl_carousel";
    linkForOwlCarousel.type = "text/css";
    linkForOwlCarousel.rel = "stylesheet";
    linkForOwlCarousel.href = "/css/owl.carousel.min.css";
    childLinkForOwlCarousel = document.head.appendChild(linkForOwlCarousel);

    // Adding bootstrap
    let linkForBoostrap = document.createElement("link");
    linkForBoostrap.id = "bootstrap_connection";
    linkForBoostrap.type = "text/css";
    linkForBoostrap.rel = "stylesheet";
    linkForBoostrap.href = "/css/bootstrap4.min.css";
    childLinkForBoostrap = document.head.appendChild(linkForBoostrap);

    // Adding style for connection
    let linkForStyleForConnection = document.createElement("link");
    linkForStyleForConnection.id = "style_for_connection";
    linkForStyleForConnection.type = "text/css";
    linkForStyleForConnection.rel = "stylesheet";
    linkForStyleForConnection.href = "/css/style_for_connection.css";
    childLinkForStyleForConnection = document.head.appendChild(
      linkForStyleForConnection,
    );

    //script for masking transition
    childScriptShow = masking_transition(
      "show_connection_page",
      "connection_page",
      "visible",
    );

    // returned function will be called on component unmount
    return () => {
      if (childLinkForOwlCarousel) {
        document.head.removeChild(childLinkForOwlCarousel);
      }

      if (childLinkForBoostrap) {
        document.head.removeChild(childLinkForBoostrap);
      }

      if (childLinkForStyleForConnection) {
        document.head.removeChild(childLinkForStyleForConnection);
      }
      if (childScriptShow) {
        document.body.removeChild(childScriptShow);
      }
    };
  }, []);
  const handleIsLoading = (value) => {
    setIsLoading(value);
  };

  const handleValidated = (value) => {
    setValidated(value);
  };

  const handleIsFound = (value) => {
    setIsFound(value);
  };

  const handleIsError = (value) => {
    setIsError(value);
  };

  const handleError = (value) => {
    setError(value);
  };

  const handleRadioChange = (value) => {
    setTypeconnexion(value);
    // Masquer/afficher les éléments HTML en fonction de la sélection
    document.getElementById("username").style.display = "block";
    document.getElementById("phonenumber").style.display = "block";
    document.getElementById("phonewhatsApp").style.display = "none";
    document.getElementById("usernameotp").style.display =
      value === "otp" ? "block" : "none";
    document.getElementById("usernameGA").style.display =
      value === "ga" ? "block" : "none";
  };

  const handleRadioChangeselect = (option) => {
    setSelectedOption(option);

    // Masquer/afficher les éléments HTML en fonction de la sélection
    document.getElementById("username").style.display =
      option === "email" ? "block" : "none";
    document.getElementById("phonenumber").style.display =
      option === "sms" ? "block" : "none";
    document.getElementById("phonewhatsApp").style.display =
      option === "whatsApp" ? "block" : "none";
    document.getElementById("usernameotp").style.display = "none";
    document.getElementById("usernameGA").style.display = "none";
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.stopPropagation();
    } else {
      // if(selectedOption === 'email'){
      //   username : username.current.value,
      // }
      // use loginAction of context
      //phone_number.split(" ").join("").substring(1)
      const data = {
        username:
          selectedOption !== "email"
            ? userName.split(" ").join("").slice(1)
            : username.current.value,
        connection_method: typeconnexion,
        username_type: selectedOption,
      };
      auth.loginAction(
        data,
        handleIsLoading,
        handleIsFound,
        handleIsError,
        handleError,
      );
    }
    setValidated(true);
  };

  const handlePhoneNumberChange = (value) => {
    if (value && isPossiblePhoneNumber(value)) {
      setIsPossibleNumber(true);
      username.current = value;
      phonenumber.current = value;
      const phone = parsePhoneNumber(value);
      if (phone.country === "BE") {
        const phoneDecomposition = formatPhoneNumberIntl(value).split(" ");
        const countryIndicator = phoneDecomposition[0];
        let onlyPhone = phoneDecomposition
          .slice(-(phoneDecomposition.length - 1))
          .join("");
        if (onlyPhone.length == 10) {
          if (onlyPhone[0] === "0") {
            onlyPhone === onlyPhone.slice(1);
          }
        }
        const phoneResult = formatPhoneNumberIntl(
          countryIndicator + "" + onlyPhone,
        );

        username.current = phoneResult;
        phonenumber.current = phoneResult;
        setUsername(value);
      } else {
        const phoneResult = formatPhoneNumberIntl(value);

        username.current = phoneResult;
        phonenumber.current = phoneResult;
        setUsername(value);
      }
    } else {
      setIsPossibleNumber(false);
    }
  };

  const handlePhoneNumberChange1 = (value) => {
    if (value && isPossiblePhoneNumber(value)) {
      setIsPossibleWhatsAppNumber(true);
      username.current = value;
      phonenumber.current = value;
      setUsername(value);
    } else {
      setIsPossibleWhatsAppNumber(false);
    }
  };

  if (isLoading === false && isFound === false) {
    return <Error code={404} />;
  }

  if (isError === true) {
    if (error.message === "Network Error") {
      return <Error code={-1} />;
    }
    return <Error code={-2} />;
  }

  return (
    <div id="connection_page" className="half" style={{ visibility: "hidden" }}>
      <div
        id="connection_bg"
        className="bg order-1 order-md-2"
        style={{ backgroundImage: 'url("/img/bg_1.jpg")' }}
      />
      <div className="contents order-2 order-md-1">
        <div className="container">
          <div className="row align-items-center justify-content-center">
            <div className="col-md-6">
              <div className="form-block">
                <div className="text-center mb-5">
                  <h3>
                    Connexion à <strong>Isocentre</strong>
                  </h3>
                </div>
                <form
                  noValidate
                  validated={validated}
                  onSubmit={(e) => handleSubmit(e)}
                >
                  <div className="form-group first">
                    <label
                      htmlFor="username"
                      className="d-flex justify-content-center"
                    >
                      Methode de connexion
                    </label>
                    <div className="form-group first"></div>
                    <div className="form-group first d-flex justify-content-center">
                      <Form.Check
                        inline
                        type="radio"
                        label="Otp"
                        name="inlineRadioOptions1"
                        id="inlineRadio1"
                        checked={typeconnexion === "otp"}
                        onChange={() => handleRadioChange("otp")}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="GA"
                        name="inlineRadioOptions2"
                        id="inlineRadio2"
                        checked={typeconnexion === "ga"}
                        onChange={() => handleRadioChange("ga")}
                      />
                    </div>
                  </div>
                  <div
                    className="form-group first"
                    id="usernameotp"
                    style={{ display: "block" }}
                  >
                    <p>
                      Avec la connexion par OTP, vous recevrez un code
                      temporaire par email ou SMS. Saisissez ce code pour vous
                      authentifier et accéder à votre compte de manière
                      sécurisée.
                    </p>
                    <p>Connectez vous par:</p>
                  </div>
                  <div
                    className="form-group first"
                    id="usernameGA"
                    style={{ display: "none" }}
                  >
                    <p>
                      Avec la méthode de connexion par Google Authenticator(GA),
                      lors de la 1ere connexion, vous devez scanner un code QR
                      pour enregistrer ce compte dans l'application sur votre
                      smartphone. À chaque connexion, saisissez le code à 6
                      chiffres généré par l'application GA pour vous
                      authentifier.
                    </p>
                    <p>Connectez vous en utilisant:</p>
                  </div>
                  <div className="form-group first">
                    <Form.Check
                      inline
                      type="radio"
                      label="Votre email"
                      name="inlineRadioOptions3"
                      id="inlineRadio3"
                      checked={selectedOption === "email"}
                      onChange={() => handleRadioChangeselect("email")}
                    />
                    <br />
                    <Form.Check
                      inline
                      type="radio"
                      label="Votre numéro de télephone"
                      name="inlineRadioOptions4"
                      id="inlineRadio4"
                      checked={selectedOption === "sms"}
                      onChange={() => handleRadioChangeselect("sms")}
                    />
                    <br />
                    <Form.Check
                      disabled
                      inline
                      type="radio"
                      label="Votre numéro whatsapp"
                      name="inlineRadioOptions5"
                      id="inlineRadio5"
                      checked={selectedOption === "whatsApp"}
                      onChange={() => handleRadioChangeselect("whatsApp")}
                    />
                  </div>
                  <div
                    className="form-group first"
                    id="username"
                    style={{ display: "none" }}
                  >
                    <label htmlFor="username">Email</label>
                    {selectedOption === "email" && (
                      <input
                        type="text"
                        className="form-control"
                        placeholder="votre-email@gmail.com"
                        id="username"
                        ref={username}
                        onChange={(value) => {
                          //this.setState({ otpPhoneVerified: false });
                          setUsername(value);
                        }}
                        style={{
                          display:
                            selectedOption === "email" ? "block" : "none",
                        }}
                        required
                      />
                    )}
                  </div>
                  <div
                    className="form-group first"
                    id="phonenumber"
                    style={{ display: "none" }}
                  >
                    <label htmlFor="phonenumber">Numéro de téléphone</label>
                    {selectedOption === "sms" && (
                      <PhoneInput
                        international
                        defaultCountry="BE"
                        countryCallingCodeEditable={false}
                        //value={phonenumber}
                        onChange={(value) => {
                          //this.setState({ otpPhoneVerified: false });
                          handlePhoneNumberChange(value);
                        }} // Use the bound method
                        style={{
                          display: selectedOption === "sms" ? "block" : "none",
                        }}
                      />
                    )}
                    {!isPossibleNumber && (
                      <p style={{ color: "red" }}>
                        Saisissez un numéro de téléphone valide
                      </p>
                    )}
                  </div>
                  <div
                    className="form-group first"
                    id="phonewhatsApp"
                    style={{ display: "none" }}
                  >
                    <label htmlFor="phonewhatsApp">Numéro whatsApp</label>
                    {selectedOption === "whatsApp" && (
                      <PhoneInput
                        //value={phonewhatsapp}
                        international
                        defaultCountry="BE"
                        countryCallingCodeEditable={false}
                        // value={phoneNumber}
                        onChange={(value) => {
                          //this.setState({ otpWhatsappVerified: false });
                          handlePhoneNumberChange1(value);
                        }} // Use the bound method
                        style={{
                          display:
                            selectedOption === "whatsApp" ? "block" : "none",
                        }}
                      />
                    )}
                    {!isPossibleWhatsAppNumber && (
                      <p style={{ color: "red" }}>
                        Saisissez un numéro de téléphone valide
                      </p>
                    )}
                  </div>
                  <div className="d-sm-flex mb-5 align-items-center">
                    <div className=" mb-3 mb-sm-0 text-center">
                      <a href="/home" className="forgot-pass ">
                        Retour à l'accueil
                      </a>
                    </div>

                    <div className="ml-auto text-center">
                      <a href="/home/create_account" className="forgot-pass">
                        Créer un compte ?
                      </a>
                    </div>
                  </div>
                  <p className="mb-4 text-center" style={{ color: "red" }}>
                    {auth.otpError}
                  </p>
                  <Button type="submit" className="btn btn-block btn-success">
                    <div className="d-flex justify-content-center align-items-center">
                      <span className="mr-3">Se connecter</span>
                      <Spinner
                        color="#fff"
                        size={10}
                        speed={1}
                        animating={isLoading}
                      />
                    </div>
                  </Button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default connection;
